PmService.$inject = ['$http'];

function PmService($http) {
	this.check = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/a/checkpm', $.param(data)).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}
}

module.exports = PmService;