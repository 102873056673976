RankController.$inject = ["$scope", "$rootScope", "$stateParams", "RankService", "Util", "$state", "CookieFactory", 'gettextCatalog'];

function RankController ($scope, $rootScope, $stateParams, RankService, Util, $state, CookieFactory, gettextCatalog) {
    //이전페이지 이동
    $scope.back = function () {
        if (afreeca.link.isWebview()) {
            afreeca.link.close();
        } else {
            $state.go('myinfo');
        }
    }
}
module.exports = RankController;
