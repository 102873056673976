ItemService.$inject = ['$http', '$sce'];

function ItemService($http, $sce) {
	this.iteminfo = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(ITEM_M_AFREECATV + '/api/a/balance', $.param({
				type: 'all'
			})).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.quickview = async (data) => {
		const url = POINT_AFREECATV + '/mobile/mobileMyItemJSON.asp?' + $.param(data || '');

		const response = await $http.get(url);
		if (parseInt(response.data.STATUS) !== 1) {
			throw response.data;
		}

		return response.data;
	}

	this.quickviewSort = function (data) {
		return new Promise(function (resolve, reject) {
			var url = POINT_AFREECATV + '/mobile/mobileMyItemJSON.asp?' + $.param(data || '');
			$http.get(url).then(function (response) {
				if (response.data.STATUS == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}
	this.quickviewCount = function () {
		return new Promise(function (resolve, reject) {
			$http.post(ITEM_M_AFREECATV + '/api/quickview/a/count').then(function (response) {
				resolve(response.data)
			}).catch(function (error) {
				reject(error);
			})
		});
	}
	this.subscriptionCount = function () {
		return new Promise(function (resolve, reject) {
			$http.post(ITEM_M_AFREECATV + '/api/subscription/a/count').then(function (response) {
				resolve(response.data)
			}).catch(function (error) {
				reject(error);
			})
		});
	}
	this.syncCheckQuickview = function (data) {
		return new Promise(function (resolve, reject) {
			var url = POINT_AFREECATV + '/mobile/mobileMyItemJSON.asp?' + $.param(data || '');
			$.ajax({
				type: 'POST',
				url: url,
				dataType: 'json',
				async: false,
				xhrFields: {
					withCredentials: true
				},
				success: function (response) {
					if (response.data.STATUS == 1) {
						resolve(response.data)
					} else {
						reject(response.data);
					};
				},
				error: function (data, status, err) {
					reject(data);
				},
			});
		});
	}
	
	this.cnlquickview = function () {
		return new Promise(function (resolve, reject) {
			$http.post(ST_AFREECATV + '/api/item/quickview.php', $.param({
				szWork: 'cnlItem',	
				szItemType: 'autoPayment'
			})).then(function (response) {
				resolve(response.data);
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.use = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(POINT_AFREECATV + '/mobile/mobilesvccouponins.asp', $.param(data || '')).then(function (response) {
				resolve(response.data)
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	/**
	 * 아이템 리스트
	 */
	this.itemImage = {};
	this.itemImages = function () {
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: RES_AFREECATV + '/new_player/items/downloadinfo.js',
				type: 'GET',
				dataType: 'jsonp',
				jsonp: 'callback',
				jsonpCallback: 'ItemListCallback',
				success: function (response) {
					if (response.result == 1) {
						this.itemImage = response.data;
						resolve(response.data)
					} else {
						reject(response.data);
					};
				},
				error: function (xhr, status, error) {
					reject(error);
				}
			});
		});

	}

	this.stickerItemConfirmAddr = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/confirmaddr', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.stickerGetBalance = function () {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/balance').then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.fanLoveItem = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(ST_AFREECATV + '/app/fanlove_item.cgi', $.param(data || '')).then(function (response) {
				resolve(response.data)
			}).catch(function (error) {
				reject(error);
			})
		});
	}


	this.stickerTicketExchangeView = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/exchangeview', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.stickerGiftTicketView = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/giftticket', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}


	this.stickerGiftTicketEndView = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/exchangeview', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}



	this.stickerItemInsertAddr = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/insertaddr', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}


	this.nickname = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/a/nickname', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}



	this.stickerTicketList = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/item/sticker/a/list', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.quickviewUpgradeInfo = async (data) => {
		const response = await $http.post(ITEM_M_AFREECATV + '/api/quickview/a/upgradeInfo', $.param(data || ''));
		return response.data;
	}

	this.quickviewSetAutoTrial = function () {
		return new Promise(function (resolve, reject) {
			$http.post(ITEM_M_AFREECATV + '/api/quickview/a/setAutoTiral').then(function (response) {
				resolve(response.data)
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.itemInfoApi = async (data) => {
		const response = await $http.get(
			`${LIVE_AFREECATV}/api/item_info/index.php?${$.param(data || '')}`
		);
		return response.data;
	};
}


module.exports = ItemService;