


gtext.$inject = ['gettextCatalog'];
function gtext(gettextCatalog) {
        return function (str) {
                var args = Array.prototype.slice.call(arguments, 1);
                var i = 0;
                str = gettextCatalog.getString(str);
                return str.replace(/%s/g, function () {
                        return args[i++];
                });
        }; 
}
module.exports = gtext