IteminfoExchangeTicketController.$inject = ['$scope', '$state', '$stateParams', '$window', '$http', 'ItemService', 'gtext'];

function IteminfoExchangeTicketController($scope, $state, $stateParams, $window, $http, ItemService, gtext) {

    $scope.isDisabled = false;

    /**
     * 뒤로가기 버튼
     */
    $scope.backHandler = function () {
        $window.history.back();
    }

    /**
     * 스티커로 티켓 교환
     */
    $scope.exchangeTicket = function () {
        // 티켓 매진
        if ($scope.ticket.stock_cnt <= 0) {
            alert(gtext('죄송합니다. 티켓이 모두 매진되어 교환할 수 없습니다.'));
            return;
        }

        // 1인 최대 교환개수 초과
        if ($scope.ticket.buy_cnt >= $scope.ticket.buy_limit_cnt) {
            alert(gtext('1인 최대 ' + $scope.ticket.buy_limit_cnt + '장을 모두 교환하여 더 이상 교환할 수 없습니다.'));
            return;
        }

        $scope.isDisabled = true;
        ItemService.stickerGetBalance().then(function (response) {
            // 현재 보유 스티커 개수 체크

            if (response.balance.length <= 0) {
                alert('스티커가 부족하여 티켓으로 교환할 수 없습니다.');
                return false;
            }

            if (parseInt(response.balance.replace(',', '')) < $scope.ticket.sticker_cnt) {
                $scope.isDisabled = false;
                alert(gtext('스티커가 부족하여 티켓으로 교환할 수 없습니다.'));
                return;
            } else {
                // 스티커로 티켓 교환
                ItemService.fanLoveItem({
                    szWork: 'EXCHANGE',
                    szType: 'json',
                    szItemType: $scope.ticket.ticket_type,
                    nPeriod: 1,
                    nItemNo: $scope.ticket.idx
                }).then(function (response) {
                    eval(response);

                    var result = oExcResult.CHANNEL.RESULT;

                    if (result == "-100") {
                        alert("스티커가 부족하여 티켓으로 교환할 수 없습니다.");
                        return;
                    } else if (result == "-200") {
                        alert("티켓 교환에 실패하였습니다.\n다시 시도해 주세요.");
                        return;
                    } else if (result == "-400") {
                        alert("해당 기능은 현재 점검을 진행중입니다.");
                        return;
                    } else if (result == "-800") {
                        alert("1인 최대 " + oExcResult.CHANNEL.BUY_LIMIT_CNT + "장을 모두 교환하여 더 이상 교환할 수 없습니다.");
                        return;
                    } else if (result == "-900") {
                        alert("죄송합니다. 티켓이 모두 매진되어 교환할 수 없습니다.");
                        return;
                    }

                    // 교환 완료 페이지
                    $state.go("iteminfo.ticketend", {
                        ticket_type: $scope.ticket.ticket_type
                    });
                })
            }
        });
    }

    /**
     * 교환할 티켓 정보 불러오기 (티켓 교환하기 페이지)
     */
    ItemService.stickerTicketExchangeView({
        ticket_type: $stateParams.ticket_type
    }).then(function (response) {
        $scope.total = 1;
        $scope.ticket = response.data;
        $scope.$digest();
    }).catch(function (error) {
        $scope.total = 0;
        $scope.$digest();
    });
}
module.exports = IteminfoExchangeTicketController;