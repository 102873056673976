

CategoryService.$inject = ['$http'];
function CategoryService($http) {
  this.list = function () {
    return new Promise(function (resolve, reject) {
      $http.get(API_M_AFREECATV + '/application/a/category').then(function (response) {
        if (response.data.result == 1) {
          resolve(response.data)
        } else {
          reject(response.data);
        };
      }).catch(function (error) {
        reject(error);
      })
    });
  }
}


module.exports = CategoryService;