MyTokenExchangeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState']

function MyTokenExchangeController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState) {

    $scope.validity = 12;
    $scope.myPoint = 0;
    $scope.availablePoint = 0;
    
    // 사용가능 포인트 조회
    $scope.amount = function () {
        MypointService.amount(LoginManager.getUserId())
        .then(function (response) {
            $scope.myPoint = response.DATA.AVAILABLE_POINT;
            $scope.availablePoint = response.DATA.AVAILABLE_POINT;
            $scope.remainPoint = $scope.availablePoint;
            $scope.earnToken = 0;
            $scope.usePoint = 0;
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 이전 페이지로 이동
    $scope.back = function () {
        history.back();
    }

    // 크레딧전환 수치 계산
    $scope.pressTokenVal = function (value) {
        if (typeof value == 'undefined' || value == null) {
            value = 0;
        }

        $scope.earnToken = value;
        $scope.usePoint = value > 0 ? value * 10 : 0;
        $scope.remainPoint = $scope.myPoint-$scope.usePoint;
    }
    
    // 전환가능 크레딧 최대치
    $scope.maxTokenVal = function () {
        $scope.earnToken = parseInt($scope.myPoint / 10);
        $('#oExchangeVal').val($scope.earnToken);
        $scope.pressTokenVal($scope.earnToken);
    }

    // 크레딧전환 진행
    $scope.exchangeToken = function () {
        if ($scope.myPoint <= 0 || $scope.myPoint < $scope.usePoint) {
            alert('보유한 포인트가 부족합니다.');
            return false;
        }
        if ($scope.earnToken <= 0) {
            alert('획득 크레딧은 1이상 입력 바랍니다.');
            return false;
        }

        MypointService.exchange(LoginManager.getUserId(),'point',{
            token: $scope.earnToken,
            point: $scope.usePoint
        }).then(function (response) {
            if (response.RESULT == 1) {
                alert('포인트가 크레딧으로 전환되었습니다.');
                history.back();
            } else {
                alert('잠시 후 다시 시도해주세요.');
            }
            $scope.$digest();
        }).catch(function (response) {
            if (response.data.RESULT == -1) {
                alert(response.DETAIL.message);
            }
            $scope.$digest();
        });
    }

   if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $scope.amount();
            } else {
                if (afreeca.link.isWebview()) {
                    location.href = SERVICE_APP_SCHEME + "login/requireLogin";
                } else {
                    toastr.error("로그인이 되어 있지 않습니다.");
                    setTimeout(function () {
                        $state.go("home");
                    }, 500);
                }
            }
        });
    } else {
        $scope.amount();
    }
}

module.exports = MyTokenExchangeController;
