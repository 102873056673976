

AlarmService.$inject = ['$http'];
function AlarmService($http) {
  this.status = function () {
    return new Promise(function (resolve, reject) {
      $http.get(API_M_AFREECATV + '/push/alarm/a/status').then(function (response) {
        if (response.data.result == 1) {
          resolve(response.data)
        } else {
          reject(response.data);
        };
      }).catch(function (error) {
        reject(error);
      })
    });
  }


  this.register = function () {
    return new Promise(function (resolve, reject) {
      $http.get(API_M_AFREECATV + '/push/alarm/a/register').then(function (response) {
        if (response.data.result == 1) {
          resolve(response.data)
        } else {
          reject(response.data);
        };
      }).catch(function (error) {
        reject(error);
      })
    });
  }



  this.unregister = function () {
    return new Promise(function (resolve, reject) {
      $http.get(API_M_AFREECATV + '/push/alarm/a/unregister').then(function (response) {
        if (response.data.result == 1) {
          resolve(response.data)
        } else {
          reject(response.data);
        };
      }).catch(function (error) {
        reject(error);
      })
    });
  }
}


module.exports = AlarmService;