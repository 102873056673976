function favVideo() {
    return {
        restrict: "E",
        scope: {
            item : "=",
            group : "=",
        },
        template : require('../templates/favorite.template.video.html'),
        link : function(scope, event, attrs){
        },
        controller : ["$scope", "$rootScope", "Util", "CookieFactory",  function($scope, $rootScope, Util, CookieFactory){

                            
            // 플레이어 가기
            $scope.goPlayer = function(item,group){
                var oVideo = item;
                if (oVideo.type == 'LIVE') {
                    oVideo.scheme = SERVICE_APP_SCHEME + 'player/live?broad_no='+oVideo.broad_no+'&user_id='+oVideo.user_id+'&channel=LIVE';
                    Util.action(oVideo.scheme);
                }
                else {
                    $rootScope.action(oVideo.scheme);
                    $scope.InflowLogEvent(oVideo,group);
                }
            }
             // 현재기준 몇일전 인지 값을 구한다.
                $scope.regDateConvert = function (reg_date) {
                    var nTimeStamp = null;
                    if(typeof reg_date == "string"){
                        nTimeStamp = new Date(reg_date.replaceAll("-","/")).getTime()/1000;
                    }
                    else{
                        nTimeStamp = reg_date
                    }
                    return Util.regDateConvert(nTimeStamp);
                };

            // 유입경로 처리
            $scope.InflowLogEvent = function(oVideo,group){
                // VOD유입경로 설정
                var arrInflowPath = [];
                var nTitleNo = oVideo.title_no;
                var szBjId = '';
                // 스트리머 ID가 있을 경우에는 대입해줌
                if(oVideo.user_id){
                    szBjId = oVideo.user_id;
                }

                arrInflowPath = [];
                
                arrInflowPath['path1'] = 'my';

                switch(group){
                    case 'favorite_vod': // 핫이슈
                        arrInflowPath['path2']="normal";
                        arrInflowPath['path3']="fav_vod";
                        break;
                    case 'favorite_vod_clip': // 기록
                        arrInflowPath['path2']="live_vod";
                        arrInflowPath['path3']="fav_vod";
                        break;
                    case 'favorite_vod_catch': // 카테고리
                        arrInflowPath['path2']="catch";
                        arrInflowPath['path3']="fav_vod";
                        if( CookieFactory.get('listPreviousUrl') !="") CookieFactory.set('listPreviousUrl', DOMAIN, 1);
                        break;
                }
                    
                // VOD 유입경로 전송
                Util.directVodInflowPath(szBjId, nTitleNo, arrInflowPath);
                    
            }

        }]
    }
}

module.exports = favVideo;