PmController.$inject = ['$scope', '$state', '$stateParams', '$sce'];
function PmController($scope, $state, $stateParams, $sce) {
	if ($stateParams.pm) {
		var msg = $stateParams.pm.pm_message.replace(/(\n|\r\n)/g, '<br>');//줄바꿈
		$scope.msg = $sce.trustAsHtml(msg);
		$scope.time = $stateParams.pm.pm_time;
	} else {
		$state.go('home');
	}

}
module.exports = PmController; 