module.exports = function CommonFooter() {
    return {
            restrict: 'A',
            scope: {
                    ngModel: '='
            },
            link: function ($scope, $element, $attrs) {
                const soopNavigation = window.SOOP_UI.SoopNavigation(null, null, $element[0], { 'shouldHideDarkModeSelector': true, 'isSimplifiedFooter': true });
            }
    }
}