NotificationListController.$inject = ['$scope', '$rootScope', '$timeout', 'Util', 'NotificationService']

function NotificationListController($scope, $rootScope, $timeout, Util, NotificationService) {

    if (!$rootScope.member) {
        $rootScope.login("notification");
    }

	$scope.size = 20; // data limit
    $scope.notiContent = []; // list data
    $scope.bPageLoading = true;
    $scope.loofStartCnt = 0;
    $scope.loofEndCnt = 20;
	$scope.pageNo = 1;
	$scope.notiType = ['F01', 'F02', 'F12', 'B02'];

    $scope.contents = function (nSeqNo) {
    	$scope.bPageLoading = true;
        NotificationService.contents({
            from: nSeqNo,
            size: $scope.size,
            Previous: $rootScope.fromBadge
        }).then(function (response) {
            if (response.data.length > 0) {
				$.merge($scope.notiContent, response.data);
            	setContentType(nSeqNo,$scope.pageNo);
            }
            $scope.has_more = response.has_more;
            $scope.last_seq = response.last_seq;
            $scope.bPageLoading = false;
            $scope.$digest();
        }).catch(function (response) {
        	$scope.bPageLoading = false;
			$scope.$digest();
        });
    }

	// 더보기
	Util.lastItem(function () {
		if ($scope.bPageLoading == false && $scope.has_more) {
			$scope.pageNo = $scope.pageNo+1;
			$scope.contents($scope.last_seq);
		}
	});

	// 채널 이동
    $scope.goStation = function (szUserId,aLogInfo) {
    	if (szUserId == "afsysop"){
    		return false;
    	}
        NotificationService.updateNoti({
            actionType : 'station',
            logInfo : aLogInfo
        }).then(function (response) {
        	// nothing..
        }).catch(function (response) {
	        if (response.data.code == -1000) {
	            $rootScope.login("notification");
	            return;
	        } else {
	            //alert(response.data.message);
	        }
        });

        Util.goStation(szUserId);
    }

    // 알림설정 페이지이동
    $scope.setting = function () {
        location.replace(M_AFREECATV + "/#/notification/notisetting");
    }
    
    // 랜딩페이지 이동
    $scope.goNotiPage = function(szUrl,nSeqNo,aLogInfo) {
    	// 유입경로 전송
    	setInflowLog(aLogInfo);
    	// 알림항목 읽음처리
        NotificationService.updateNoti({
            actionType : 'read',
            sequence : nSeqNo,
            logInfo : aLogInfo
        }).then(function (response) {
			$('.feed_list>li').click(function(){
			    $(this).removeClass("unlead");
			});
        	if (szUrl !== ""){
				if(aLogInfo.action_type ==="F01"){
					location.href = szUrl;
				}else{
					const a = document.createElement('a')
		      		a.setAttribute('href', szUrl)
		      		a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true}))
				}
        	}           	
        }).catch(function (response) {
	        if (response.data.code == -1000) {
	            $rootScope.login("notification");
	            return;
	        } else {
	            alert(response.data.message);
	        }
        }); 
    }

    // 알림 읽음/삭제 처리
    $rootScope.updateNoti = function(szAction) {
        let updateSeqNo = $scope.first_seq;
		if (typeof(updateSeqNo) == "undefined" || updateSeqNo == "") {
			return false;
		}
        NotificationService.updateNoti({
            actionType : szAction,
            sequence : updateSeqNo,
        }).then(function (response) {
        	//읽은알림 삭제
        	if (szAction == "delete_read"){
				$(".feed_list>li").not(".unlead").remove();
				$(".info_layer").toggle();
				//읽은 알림이 전부일 경우, 새로고침
				if ($(".feed_list>li").length <= 0){
					setTimeout(function () { // 1초 뒤에 페이지 리로드 진행
						location.reload();
					}, 1000);
				}
			//모두읽기
        	} else if (szAction == "read_all"){
        		$(".feed_list>li").removeClass("unlead");
        	} else if (szAction == "delete_all"){
				setTimeout(function () { // 1초 뒤에 페이지 리로드 진행
					location.reload();
				}, 1000);
        	}
        }).catch(function (response) {
	        if (response.data.code == -1000) {
	            $rootScope.login("notification");
	            return;
	        } else {
	            alert(response.data.message);
	        }
        });
    }

	//게시물 세부설정
    function setContentType(nSeqNo,nPageNo) {
        if (nPageNo > 1) {
			$scope.loofStartCnt = $scope.loofEndCnt;
        }
		$scope.loofEndCnt = $scope.notiContent.length;
		let nStartCnt = parseInt($scope.loofStartCnt);
        let nEndCnt = parseInt($scope.loofEndCnt);

        $timeout(function () {
            for (let i = nStartCnt; i < nEndCnt; i++) {
            	let aListData = $scope.notiContent[i];
            	if (aListData !== null || typeof aListData !== 'undefined') {
	            	if (nSeqNo !=="" && i === 0) {
	            		$scope.first_seq = aListData.seq;
	            	}

	            	$szNickName = aListData.from_nickname || "";
	            	$szMessage = aListData.noti_message || "";
	            	$szHeadText = aListData.head_text || "";
					$szMessage = $szNickName === null ? $szMessage.replace("<>","") : $szMessage;
					$bTypeCheck = $scope.notiType.includes(aListData.noti_type);
	            	$scope.notiContent[i].noti_message = setConvertText($szNickName,$szMessage,$szHeadText, $bTypeCheck);

	            	if (aListData.thumbnail !== "") {
	            		$scope.notiContent[i].photo_flag = true;
	            		if (aListData.read_flag == 0) {
							$scope.notiContent[i].contentType = "photo unlead";
	            		} else {
	            			$scope.notiContent[i].contentType = "photo";
	            		}
	            	} else {
	            		$scope.notiContent[i].photo_flag = false;
	            		if (aListData.read_flag == 0){
							$scope.notiContent[i].contentType = "unlead";
	            		} else {
	            			$scope.notiContent[i].contentType = "";
	            		}
	            	}

					$scope.notiContent[i].iconType = setNotiType(aListData.noti_type);
				}
            }
        }, 50);
    }

    // 알림메세지 설정
    function setConvertText(szNickName,szMessage,$szHeadText, $bTypeCheck) {

    	if (szNickName !== null || szNickName !== '') {
    		if ($szHeadText === null || $szHeadText === '') {
    			$szHeadText = '';
    		} else {
    			$szHeadText = ":&nbsp;"+ ($bTypeCheck ? Util.getHtmlEntity($szHeadText) : $szHeadText);
			}

			szTargetText = '<'+szNickName+'>';
			szTargetTexts = escapeRegExp(szTargetText);
			let szChangeText = '<span class="nickname">'+szNickName+'</span>';
			let szConvertedText = szMessage.replace(new RegExp(szTargetTexts,'gi'),szChangeText);
			
			return szConvertedText+$szHeadText;
    	} else {
    		return szMessage+$szHeadText;
    	}
    } 

    // 알림타입 구분
    function setNotiType(szCode) {
    	var szType = '';
		switch (szCode) {
			case 'F01': 
			case 'F21':
				szType = "live";
				break;
			case 'F03':
			case 'F04':
			case 'F05':
				szType = "vod";
				break;
			case 'F02':
			case 'F12':
				szType = "bj_post";
				break;
			case 'F06':
				szType = "bj_text";
				break;
			case 'F11':
				szType = "bj_msg";
				break;
			case 'A01':
			case 'A02':
			case 'A03':
			case 'A04':
			case 'A05':
			case 'A14':
			case 'A15':
			case 'A16':
			case 'A17':
			case 'C11':
			case 'C21':
			case 'C31':
			case 'C14':
			case 'C24':
			case 'C34':
				szType = "text";
				break;
			case 'A07':
			case 'A08':
			case 'A09':
			case 'A10':
			case 'A12':
			case 'A13':
			case 'C17':
			case 'C27':
			case 'C37':
			case 'C19':
			case 'C29':
			case 'C39':
				szType = "up";
				break;
			case 'A11':
				szType = "message";
				break;
			case 'B02':
				szType = "mypost";
				break;
			case 'B06':
				szType = "mytext";
				break;
			case 'B03':
			case 'B04':
			case 'B07':
				szType = "myvod";
				break;
			case 'S01':
			case 'S08':
			case 'S02':
			case 'S03':
			case 'S04':
				szType = "balloon";
				break;
			case 'S05':
			case 'S06':
				szType = "sticker";
				break;
			case 'S07':
				szType = "choco";
				break;
			case 'S09':
			case 'S10':
			case 'S16':
				szType = "ad";
				break;
			case 'S11':
			case 'S12':
			case 'S13':
			case 'S14':
				szType = "subscribe";
				break;
			case 'S15':
				szType = "quickview";
				break;
			default :
				break;
		}
		return szType;
    }

    // 유입경로 호출 (LIVE,VOD)
    function setInflowLog(aLogInfo) {
	    const checkLiveCode = ['F01', 'F21'];
	    const checkVodCode = ['F03', 'A08', 'B03', 'B04', 'B07', 'A02', 'A05', 'A10', 'A13'];
	    if (aLogInfo === '' && typeof(aLogInfo) !== "object") {
	        return;
	    }

	    let isLive = checkLiveCode.indexOf(aLogInfo.action_type) !== -1;
	    let isVod = checkVodCode.indexOf(aLogInfo.action_type) !== -1;
	    if (!(isLive || isVod)) {
	      return;
	    }

	    let bjId = aLogInfo.from_id || "";
	    let commonNo = aLogInfo.common_no || 0;
	    let pathData = ["separate", "noti", aLogInfo.noti_code];

	    let logType;
	    if (isLive) {
	    	logType = 'LIVE'
	    } else if (isVod) {
	    	logType = 'VOD'
	    }

	    try {
			$rootScope.sendInflowLog(logType, bjId, commonNo, pathData);
	    } catch (error) {}
    }

	function escapeRegExp(str) {
	    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
	}

	// top button controll
	var topBtn = $('.btn_top');
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
	topBtn.click(function () {
		$('body,html').animate({
		  scrollTop: 0
		}, 100);
		return false;
	});

	$scope.contents();

}
module.exports = NotificationListController;