

MainService.$inject = ['$http', 'Util'];
function MainService($http, Util) {
        this.menu = function () {
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/application/a/menu', $.param({
                                platform: 'web'
                        })).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }


        this.rejectRecommend = function (user_id, bj_id, type) {
                // 호출된 Page  (home : prb, favorite : frb)
                var ut = (type == 'home') ? 'prb' : 'frb';
                // platform (ios : ios1 , android : adr1, etc : etc)
                var w = '';
                switch (Util.getOS()) {
                        case 'ios':
                                w = 'ios1';
                                break;
                        case 'android':
                                w = 'adr1';
                                break;
                        default:
                                w = 'etc';
                                break;
                }

                var szUrl = SUGGEST_AFREECATV + '/api_ufb.php?' + $.param({
                        m: 'rfb',
                        v: '1.0',
                        ut: ut,
                        w: w,
                        score: 0,
                        logic: 'oco',
                        uid: user_id,
                        bj: bj_id,
                });

                return new Promise(function (resolve, reject) {


                        $.ajax({
                                url: szUrl,
                                type: 'GET',
                                dataType: 'jsonp',
                                jsonp: 'callback',
                                jsonpCallback: 'JSON_CALLBACK',
                                success: function (response) {
                                        if (response.result == 1) {
                                                resolve(response)
                                        } else {
                                                reject(response);
                                        };
                                },
                                error: function (xhr, status, error) {
                                        reject(error);
                                }
                        });
                });
        }
}


module.exports = MainService;