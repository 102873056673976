module.exports = {
	'홈': 'หน้าหลัก',
	'핫이슈': 'ฮิต',
	'나중에보기': 'รับชมภายหลัง',
	'전체': 'ทั้งหมด',
	'로그인': 'ล็อคอิน',
	'최신순': 'ล่าสุด',
	'참여인원순': 'ผู้ชมสูงสุด',
	'즐겨찾기 추가': 'เพิ่มในรายการโปรด',
	'방송국 보러가기': 'ไปยังบล็อก',
	'나중에보기 등록': 'เพิ่มในรายการรับชมภายหลัง',
	'목록편집': 'แก้ไขรายการ',
	'전체삭제': 'ลบทั้งหมด',
	'재생한 VOD 삭제': 'ลบวิดีโอที่ดูล่าสุด',
	'시청한 VOD': 'ดูล่าสุด',
	'다시보기': 'เล่นซ้ำ',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': 'ท่านสามารถดูวิดีโอในรายการรับชมย้อนหลัง<br>ได้หลังจากทำการล็อกอิน,',
	'나중에 보기에 등록한 VOD가 없습니다.': 'ท่านยังไม่ได้ทำการเพิ่มวิดีโอเข้ามายังรายการรับชมภายหลัง,',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': 'หากไม่ต้องการจะพลาดวิดีโอ <br>เพิ่มเข้าไปยังรายการชมย้อนหลัง <br>รับชมได้เมื่อต้องการ,',
	'나중에보기 삭제': 'ลบรายการดูทีหลัง,',
	'나중에 보기 서비스란?': 'อะไรคือ "ดูภายหลัง",',
	'나중에 보기에 등록되었습니다.': 'เพิ่มไปยังรายการรับชมภายหลัง',
	'나중에 보기가 삭제되었습니다.': 'ลบออกจากรายการรับชมภายหลัง',
	'라이브 방송을 시청할 수 없는 상황일 때': 'หากไม่สะดวกที่จะรับชมถ่ายทอดสด',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': 'คุณสามารถเพิ่มวิดีโอนี้ในรายการรับชมภายหลัง เพื่อรับชมในเวลาอื่นได้ตามสะดวก',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': '<span class="blue">คุณสามารถรับชมวิดีโอต่างๆ</span>ย้อนหลังได้<br> ในรายการรับชมภายหลัง,',
	'보고 싶은 VOD나, 아직 보지 않은': 'เก็บรายการถ่ายทอดสดที่เราพลาดรับชมไปดูย้อนหลังได้ง่ายๆ,',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': 'เพียงแค่เพิ่มในรายการรับชมย้อนหลัง,',
	'다시 보지 않기': 'ไม่ต้องแสดงอีกครั้ง',
	'닫기': 'ปิด',
	'나중에 보기를 지원하지 않는 영상입니다.': 'ไม่สามารถใช้การรับชมภายหลังกับวิดีโอนี้ได้',
	'쪽지함': 'ส่งข้อความ',
	'새 쪽지 <span>%s</span>개': '<span>%s</span> ข้อความใหม่',
	'새 쪽지가 없습니다': ' ไม่มีข้อความใหม่',
	'쪽지 보내기': 'ส่่งข้อความ ',
	'쪽지설정': 'ตั้งค่าข้อความ',
	'받은 쪽지함': 'กล่องข้อความ',
	'보낸 쪽지함': 'กล่องข้อความส่ง',
	'쪽지 보관함': 'ข้อความถูกบันทึกแล้ว',
	'전체 쪽지': 'ข้อความทั้งหมด',
	'안읽은 쪽지': 'ยังไม่ได้อ่าน',
	'보관한 쪽지':'ข้อความที่ี่บันทึก',
	'보관한 쪽지가 없습니다.': 'ไม่พบข้อความที่บันทึก',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': 'ข้อความที่ถูกบันทึกจะถูกเก็บไว้ถาวร (สูงสุด 1,000 ข้อความ)',
	'이미지 첨부는 3개까지 가능합니다.': 'ท่านสามารถอัพโหลดได้มากที่สุด 3 รูป',
	'이미지 첨부는 8MB까지 가능합니다.': 'รูปที่อัพโหลดสามารถมีขนาดได้สูงสุด 8 MB',
	'이미지가 저장되었습니다.': 'บันทึกรูปแล้ว',
	'확인': 'ยืนยัน',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(จะบันทึกไว้เป็นเวลา <em>90</em> วันนับจากวันที่ได้รับ)',
	'이미지 첨부': 'อัพโหลดรูป',
	'첨부 이미지': 'รูปที่อัพโหลด',
	'이미지 저장 기간': 'ช่วงเวลาที่เก็บรูป',
	'이미지 저장 기간 만료': 'ช่วงเวลาที่เก็บรูปหมดลงแล้ว',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(จะเก็บรูปไว้เป็นเวลา <em>90</em> วันหลังจากวันที่อัพโหลด)',
	'이미지 파일은 1개씩 첨부 가능합니다.': 'แนบไฟล์ภาพได้เพียงครั้งละ 1 ไฟล์เท่านั้น',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': 'เฉพาะไฟล์ภาพนามสกุล JPG, JPEG, GIF, PNG, BMP เท่านั้น',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': 'ข้อความที่ถูกบันทึกไว้จะอยู่แบบถาวร',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)':'ข้อความที่ถูกบันทึกไว้จะอยู่แบบถาวร (สูงสุด 1,000 ข้อความ)',
	'이미지 첨부 중입니다.': 'กำลังอัพโหลดรูป.',
	'해당 쪽지가 삭제되었습니다.' : 'ท่านได้ทำการลบข้อความแล้ว',
	'해당 쪽지가 신고되었습니다.' : 'ท่านได้ทำการรายงานข้อความแล้ว',
	'수신거부가 완료되었습니다.' : 'ท่านได้ทำการบล็อคข้อความแล้ว',
	'해당 쪽지가 발송 취소되었습니다.' : 'ท่านมีข้อความที่ยังไม่ได้ส่ง',
	'이미 신고된 쪽지입니다.':'ท่านได้้รายงานข้อความนี้ไปแล้ว',

	'보관': 'บันทึก',
	'신고': 'รายงาน',
	'삭제': 'ลบ',
	'받은 쪽지가 없습니다.': 'ท่านยังไม่่ได้รับข้อความ',
	'읽지않음': 'ยังไม่อ่าน',
	'읽음': 'อ่านแล้ว',
	'더 보기': 'ดูเพิ่มเติม',
	'받은 쪽지': 'ได้รับ',
	'보낸 사람': 'ผู้ส่ง',
	'받은 시각': 'เวลาได้รับ',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': ' ข้อความจะถูกลบหลังผ่านไป 90 วัน',
	'답장': 'ตอบ',
	'수신거부': 'บล็อค',
	'보낸 쪽지가 없습니다.': 'ท่านยังไม่ได้ส่งข้อความ',
	'보냄': 'ข้อความที่ส่ง',
	'보낸 쪽지': 'ส่ง',
	'받는 사람': 'ผู้รับ',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': 'ข้อความที่ส่งไป จะถูกลบภายใน 90 วัน',
	'쪽지 수신 설정': 'ตั้งค่าข้อความ',
	'전체 수신거부': 'บล็อคข้อความทั้งหมด',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': 'ไม่รับข้อความจากผู้ใช้งานทุกท่าน<br>(ยกเว้นทีมงานอาฟรีคาทีวี)',
	'수신거부 ID 목록': 'รายชื่อบล็อค',
	'총 %s명': 'ทั้งหมด %s',
	'아이디': 'ไอดี',
	'추가 날짜': 'วันที่เพิ่ม',
	'수신거부 목록이 없습니다.': ' ไม่สามารถส่งข้อความหาตัวเองได้',
	'수신거부 해제': 'ปลดบล็อค',
	'추가': 'เพิ่ม',
	'수신거부 추가': 'เพิ่มเข้ารายชื่อบล็อค',
	'쪽지 보내기': 'ส่งข้อความ',
	'보내기': 'ส่งข้อความ',
	'받는 아이디': 'ไอดีผู้รับ',
	'다중 전송은 엔터키로 구분해 주세요.': 'กรุณาแบ่งรายการแต่ละรายการออกจากกันด้วยปุ่ม Enter',
	'최대 5,000자까지 작성 가능합니다.': 'พิมพ์ได้สูงสุด 5,000 ตัวอักษร',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': 'ไม่สามารถใช้ระบบนี้ได้เนื่องจากท่านใช้คำต้องห้ามมากเกินไป กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อสอบถามเพิ่มเติม',
	'신고가 접수되었습니다.': 'ท่านได้ทำการรายงานแล้ว',
	'선택한 쪽지를\n신고하시겠습니까?': 'ท่านต้องการจะรายงานข้อความนี้หรือไม่',
	'쪽지를 선택해 주세요.': 'กรุณาเลือกข้อความ',
	'선택한 쪽지를\n삭제하시겠습니까?': 'ท่านต้องการจะลบข้อความนี้หรือไม่',
	'쪽지를 선택해 주세요.': 'กรุณาเลือกข้อความ',
	'삭제하시겠습니까?': 'ท่านต้องการจะลบหรือไม่',
	'신고하시겠습니까?': 'ท่านต้องการจะรายงานหรือไม่',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': 'ทำการบล็อคเรียบร้อย ท่านสามารถปลดบล็อคได้ผ่านทางการตั้งค่า',
	'아이디를 입력해 주세요.': 'ใส่ชื่อผู้ใช้',
	'아이디는 영문,숫자만 입력 가능합니다.': 'กรุณาใช้เฉพาะอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
	'아이디를 선택해 주세요': 'กรุณาเลือกไอดีผู้ใช้',
	'해제되었습니다.': 'ยกเลิก',
	'한 번에 최대 30명까지만 전송 가능합니다.': 'ท่านสามารถส่งข้อความถึงผู้รับสูงสุด 30 คนพร้อมกัน',
	'본인에게는 쪽지를 보낼 수 없습니다.': 'ท่านไม่สามารถส่งข้อความหาตัวเองได้',
	'이미 받는사람 목록에 포함 되어 있습니다.': ' มีอยู่ในรายชื่อผู้รับแล้ว',
	'%s은(는) 존재하지 않는 닉네임/아이디입니다.': '%sชื่อหรือไอดีผิดพลาดหรือไม่มีในระบบ\n',
	'받는 아이디/닉네임을 입력해 주세요.': 'กรุณาระบุไอดี/ชื่อที่จะทำการส่ง',
	'쪽지 내용을 입력해 주세요.': 'กรุณากรอกรายละเอียดของท่าน',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': 'ท่านสามารถส่งข้อความถึงผู้รับสูงสุด 30 คนพร้อมกัน',
	'전송이 완료되었습니다.': 'ส่งเรียบร้อย',
	'로그인이 되어 있지 않습니다.': 'ท่านไม่ได้ทำการล็อกอินอยู่',
	'아이디는 12자 이내로 입력해 주세요.': 'ชื่อไอดีสูงสุดไม่เกิน 12 ตัวอักษร',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': 'ทำการรายงานแล้ว (ยกเว้นชื่อผู้ใช้ของแอดมิน)\nท่านต้องการจะลบข้อความที่ท่านรายงานไปหรือไม่',
	'잠시 후 이용해 주세요.': 'กรุณาลองใหม่ในภายหลัง',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': 'ทำการบล็อคแล้ว (ยกเว้นชื่อผู้ใช้ของแอดมิน)\nท่านต้องการจะตรวจสอบการบล็อคนี้ที่การตั้งค่าบล็อคของท่านหรือไม่?',
	'수신거부 ID 추가' : 'เพิ่มไอดีลงในรายการบล็อค',

	'즐겨찾기': 'รายการโปรด',
	'즐찾': 'รายการโปรด',
	'방송중': 'กำลังถ่ายทอดสด',
	'방송 시작 전': 'ออฟไลน์',
	'BJ 랭킹순': 'อันดับ BJ',
	'방송 중인 즐겨찾기 BJ가 없습니다.': 'ไม่มี BJ ในรายการโปรดถ่ายทอดสดอยู่',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': 'ไม่มี BJ ในรายการโปรดที่ออฟไลน์อยู่',
	'즐겨찾기한 BJ가 없거나': 'ไม่มี BJ ในรายการโปรด',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': 'ไม่มี BJ ในรายการโปรดถ่ายทอดสดอยู่',
	'상세정보': 'รายละเอียด',
	'추천 받지 않기': 'ไม่ต้องแนะนำเพิ่ม',
	'즐겨찾기 삭제': 'ลบรายการโปรด',
	'명 시청중': 'คน รับชมอยู่',


	'BJ랭킹순': 'อันดับ BJ',
	'회원가입': 'ลงทะเบียน',
	'고객센터': 'ศูนย์ช่วยเหลือ',
	'최근 본 방송': 'ประวัติการรับชม',
	'지상파/케이블': 'เคเบิลทีวี',
	'보이는라디오': 'กระจายเสียง',
	'스포츠': 'กีฬา',
	'게임': 'เกม',
	'애니': 'อนิเมชั่น',
	'콘텐츠발전소': 'มุมไอเดีย',
	'별풍선': 'Star Balloons',
	'장애/버그신고': 'แจ้งปัญหาการใช้งาน',
	'공지사항': 'ประกาศ',
	'게임센터': 'มุุมเกม',
	'더보기': 'เพิ่มเติม',
	'신인': 'หน้าใหม่',
	'시청자순': 'ผู้ชมสูงสุด',
	'참여자순': 'ผู้ชมสูงสุด',
	'1일': '1 วัน',
	'1주': '1 สัปดาห์',
	'1개월': '1 เดือน',
	'즐겨찾기할만한방송': 'เพิ่ิ่มไปยังรายการโปรด',
	'아프리카TV앱': 'AfreecaTV App',
	'바로가기': 'ดาวน์โหลด',
	'정보': 'ข้อมูล',
	'댓글': 'ความคิดเห็น',
	'승인': 'ยินยอม',
	'인기방송': 'รายการสดยอดนิยม',
	'내가 보유한 별풍선': 'Star Balloons ที่มีอยู่',
	'확인': 'ยิืนยัน',
	'취소': 'ยกเลิก',
	'스티커': 'Sticker',
	'구매하기': 'ซื้อ',
	'직접입력': 'อื่นๆ',
	'초콜릿': 'Chocolate',
	'로그인상태유지': 'จดจำไว้',
	'개': 'ชิ้น',
	'비밀번호': 'รหัสผ่าน',
	'최근 본 방송 내역 전체 삭제하기': 'ลบประวัติการรับชมทั้งหมด',
	'전체방송': 'รายการสดทั้งหมด',
	'모바일게임': 'เกมมือถือ',
	'애니메이션': 'อนิเมชั่น',
	'먹방/쿡방': 'อาหารการกิน',
	'음악': 'ดนตรี',
	'시사/현장': 'กิจกรรม',
	'주식/금융': 'การเงิน',
	'금융': 'การเงิน',
	'생활/정보': 'ชีวิตประจำวัน',
	'공부방': 'ทักษะพิเศษ',
	'보이는쇼핑': 'ช้อปปิ้งออนไลน์',
	'케이블': 'เคเบิลทีวี',
	'1:1문의': 'สอบถาม 1:1',
	'엔터테인먼트': 'บันเทิง',
	'창업방': 'มุม Start-up',
	'VOD': 'วิดีโอ',
	'개인방송국': 'บล็อก',
	'핫이슈동영상': 'วิิดีโอยอดนิยม',
	'로그아웃': 'ออกจากระบบ',
	'방송시작전': 'ออฟไลน์',
	'아이디찾기': 'ค้นหาไอดี',
	'방송국보러가기': 'ไปยังบล็อก',
	'연령제한': 'จำกัดอายุ',
	'청소년유해신고': 'แจ้งคอนเทนต์ไม่เหมาะสม',
	'국내야구': 'เบสบอลเกาหลี',
	'일본야구': 'เบสบอลญี่ปุ่น',
	'프로농구': 'บาสเก็ตบอล',
	'프로배구': 'วอลเลย์บอล',
	'UFC/일반': 'UFC / ทั่วไป',
	'프로골프': 'กอล์ฟ',
	'특집VOD': 'วิดีีโออืื่นๆ',
	'스포츠특집': 'กีฬาอื่นๆ',
	'게임특집': 'เกมอื่นๆ',
	'명시청중': 'คน รับชมอยู่',
	'내가 보유한 골드': 'Gold ที่มีอยู่',
	'내가 보유한 초콜릿': 'Chocolate ที่มีอยู่',
	'아프리카TV': 'AfreecaTV',
	'아프리카TV,': 'AfreecaTV',
	'랭킹순': 'อันดับรายการสด',
	'님이': '-',
	'비밀번호찾기': 'ลืมรหัสผ่าน',
	'최근본방송 삭제': 'ลบ',
	'최근본방송추가': 'เพิ่มรายการที่รับชมล่าสุด',
	'시청한 방송이 없습니다': 'ไม่พบรายการที่รับชมไป',
	'지금 바로 아프리카TV의 재미있고': 'มาพบกับคอนเทนต์สนุกๆ ที่หลากหลาย',
	'다양한 방송을 만나보세요!': 'บน AfreecaTV กันเถอะ!',
	'[생]': '[Live]',
	'[재]': '[Replay]',
	'[중계방]': '[Relay]',
	'아이디비번찾기': 'ลืมไอดี / รหัสผ่าน',
	'명시청': 'ผู้ชม',
	'레이디스배틀': 'Ladies Battle',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': 'ไอดี / รหัสผ่านไม่ถูกต้อง',
	'아이디/비밀번호찾기': 'ลืมไอดีี / รหัสผ่าน',
	'님이빠져들만한방송': 'รายกาารสดแนะนำ',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': 'อยู่ระหว่างการพัฒนา สามารถใช้งานส่วนนี้ได้เร็วๆ นี้',
	'구매': 'ซื้อ',
	'즐겨찾기추가': 'เพิ่มเข้ารายการโปรด',
	'베스트BJ방송': 'รายการสดของ Best BJ',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': 'ต้องการลบประวัติการรับชมทั้งหมดหรือไม่? หมายเหตุ : สามารถเลือกที่จะลบทีละรายการได้ ดูเพิ่มเติม',
	'자신에게는선물하실수없습니다.': 'ไม่สามารถส่งไอเท็มให้ตัวเองได้',
	'현재시청자수': 'ผู้ชม',
	'모바일': 'Mobile',
	'누적 시청자 수': 'ดูทั้งหมด',
	'유머/패러디': 'บันเทิิง',
	'베스트BJ': 'Best BJ',
	'신인BJ': 'ฺBJ หน้าใหม่',
	'파트너BJ': 'Partner BJ',
	'뷰티/패션': 'แฟชั่น และความงาม',
	'샵프리카': 'ร้านค้า Afreeca',
	'MY쇼핑': 'ช้อปปิ้ง',
	'마인크래프트': 'Minecraft',
	'히어로즈': 'Heroes',
	'메이플스토리2': 'Maple Story 2',
	'피파온라인3': 'FIFA ONLINE 3',
	'검은사막': 'Black Desert',
	'블랙스쿼드': 'Black Squad',
	'하스스톤': 'Hearthstone',
	'문명': 'Civilization',
	'버블파이터': 'Bubble Fighter',
	'마비노기': 'Mabinogi',
	'사이퍼즈': 'Cyphers',
	'메이플스토리': 'Maple Story',
	'테일즈런너': 'Tales Runner',
	'서든어택': 'Sudden Attack',
	'마영전': 'Vindictus',
	'리니지': 'Lineage',
	'스타': 'StarCraft',
	'스타2': 'StarCraft II',
	'아키에이지': 'ArcheAge',
	'카트라이더': 'Kart Rider',
	'모두의마블': 'Everybodys Marble',
	'디아블로3': 'Diablo III',
	'월드오브탱크': 'World of Tanks',
	'워크래프트': 'Warcraft',
	'아이온': 'Aion',
	'던파': 'Dungeon&Fighter',
	'스페셜포스': 'Special Force',
	'고전게임': 'เกมเก่า',
	'비디오게임': 'วิดีโอเกม',
	'기타': 'อื่นๆ',
	'LOL': 'League of Legends',
	'BladeNSoul': 'Blade & Soul',
	'WOW': 'World of Warcraft',
	'종교': 'ศาสนา',
	'요리/건강': 'อาหาร และสุขภาพ',
	'자습': 'ทักษะพิเศษ',
	'강좌': 'ติวสอบ',
	'멘토': 'ที่ปรึกษา',
	'프로야구': 'การแข่งขันเบสบอล',
	'농구': 'บาสเก็ตบอล',
	'골프': 'กอล์ฟ',
	'국내축구': 'ฟุตบอลเกาหลี',
	'해외축구': 'ฟุุตบอลต่างชาติ',
	'스타리그': 'StarLeague',
	'어학': 'เรียนภาษา',
	'지식': 'เรีียนรู้ทักษะพิเศษ',
	'취미': 'งานอดิเรก',
	'토크/캠방송': 'พูดคุย',
	'나루토': 'Naruto',
	'명탐정코난': 'Detective Conan',
	'교육': 'การศึึกษา',
	'토크/캠방': 'พูดคุุย',
	'별풍선 &#183; 아이템': 'ไอเท็ม Star Balloons',
	'별풍선ㆍ아이템': 'ไอเท็ม Star Balloons',
	'보.라': 'วิทยุ',
	'엔터': 'บันเทิง',
	'파트너BJ방송': 'รายการสดของ Partner BJ',
	'게임전체': 'เกมทั้งหมด',
	'스포츠전체': 'กีฬาทั้งหมด',
	'보이는라디오전체': 'กระจายเสียงทั้งหมด',
	'엔터전체': 'บันเทิิงทั้งหมด',
	'농구/NBA': 'บาสเก็็ตบอล / NBA',
	'시청자가밀어주는방송': 'รายการยอดนิยมจากผู้ชม',
	'클랜이밀어주는방송': 'รายการยอดนิิยมจากสังกัด',
	'PC게임': 'เกม PC',
	'멸망전': 'รายการแข่งขันพิเศษ',
	'라이프': 'ชีวิตประจำวัน',
	'라이프전체': 'ชีีวิตประจำวันทั้งหมด',
	'더빙/리뷰': 'รายการพากย์ และรีวิว',
	'격투기/일반': 'ศิลปะป้องกันตัว / ทั่วไป',
	'샵프리카Beta': 'ร้านค้า Afreeca Beta',
	'라이브': 'รายการสด',
	'배구': 'วอลเลย์บอล',
	'농구/배구': 'บาสเก็ตบอล / วอลเลย์บอล',
	'별풍선·아이템': 'Star Balloons / ไอเท็ม',
	'실시간핫이슈': 'เทรนด์ยอดนิยม',
	'오버워치': 'Overwatch',
	'리니지LFC': 'Lineage LFC',
	'테라': 'Tera',
	'철권': 'Tekken',
	'스파5': 'Street Fighter V',
	'스팀게임': 'Steam Games',
	'뽐': 'FFOM',
	'가우스X아프리카TV': 'Gaus X AfreecaTV',
	'유로2016': 'Euro 2016',
	'LoL공식리그': 'LoL Leagues',
	'e스포츠': 'ESports',
	'세계정복2': 'World Conquest II',
	'클래시로얄': 'Clash Royale',
	'세븐나이츠': 'Seven Nights',
	'레이븐': 'Raven',
	'쿠키런': 'Cookie Run',
	'기타게임': 'อื่่นๆ',
	'두산': 'Doosan',
	'한화': 'Hanwha',
	'롯데': 'Lotte',
	'넥센': 'Nexon',
	'삼성': 'Samsung',
	'중립': 'Baseball',
	'채널A': 'Channel A',
	'TV조선': 'TV Chosun',
	'연합뉴스': 'Yonhap News',
	'몬길': 'Monster Tamer',
	'서든어택2': 'Sudden Attack II',
	'BJ리그': 'BJ League',
	'포켓몬GO': 'Pokemon Go',
	'2016BJ대상': '2016 BJ Award',
	'학습': 'ทักษะพิิเศษ',
	'애니메이션관': 'อนืเมชั่น',
	'콘텐츠제작지원센터': 'ศูนย์ช่วยเหลือด้านคอนเทนต์',
	'알려드립니다': 'ประกาศ',
	'개인정보처리방침': 'นโยบายส่วนบุคคล',
	'개인정보 수정': 'เปลี่ยนแปลงข้อมูลไอดี',
	'내 정보': 'ข้อมูลของฉัน',
	'로그인 기기관리': 'จัดการล็อกอินผ่านอุปกรณ์ต่างๆ',
	'내 방송국': 'ช่องของฉัน',
	'본방': 'รายการสด',
	'MY뽐': 'My FFOM',
	'2차비밀번호입력': 'กรุณากรอกรหัสผ่านสำรอง',
	'2차비밀번호': 'รหัสผ่านสำรอง',
	'입력내용삭제': 'ลบข้อมูลทั้งหมด',
	'2차비밀번호찾기': 'ลืมรหััสผ่านสำรอง',
	'MOBILE': 'Mobile',
	'나중에설정': 'จัดการภายหลัง',
	'닉네임': 'ชื่อเล่น',
	'고화질로시청': 'ดูแบบคุณภาพสูง HD',
	'멀티방송': 'แยกหน้าต่าง',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'KakaoTalk',
	'참여중인 중계방': 'อยู่ในห้องสำรองของรายการสดนี้',
	'멀티방송화면리스트': 'รายการถ่ายทอดสดที่แยกหน้าต่าง',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': 'สามารถซื้อไอเท็มต่างๆ ได้โดยการคลิกดูรายละเอียดเพิ่มเติมได้ที่คำสั่งไอเท็ม',
	'앱에서시청하기': 'ดูผ่านแอพลิเคชั่น',
	'상품보기': 'ไอเท็ม',
	'선물하기': 'ส่งของขวัญ',
	'전송': 'ส่ง',
	'비밀번호 변경': 'เปลี่ยนรหัสผ่าน',
	'2차 비밀번호 설정': 'ตั้้งค่ารหัสผ่านสำรอง',
	'건강': 'สุขภาพ',
	'이슈방송스케줄': 'กำหนดการถ่ายทอดสดที่น่าสนใจ',
	'PC버전으로보기': 'ไปยัง Desktop Mode',
	'LoL챌린저스': 'LoL Challengers',
	'오버워치BJ리그': 'Overwatch League',
	'격투게임': 'เกมต่อสู้',
	'별풍선/아이템 정보': 'ข้อมูลไอเท็ม',

	'VOD가 없습니다.': 'ไม่พบวิดีโอ',
	'UP순': 'UP สูงสุด',
	'댓글순': 'ความคิดเห็นสูงสุด',
	'조회순': 'ผู้ชมสูงสุด',
	'1주일': '1 สัปดาห์',
	'방송국': 'บล็อกของฉัน',
	'인기순' : 'ความนิยม',

	'사용 중인 퀵뷰가 없습니다.': 'ไม่มีการใช้งาน QuickView ',
	'새로고침': 'รีเฟรช',
	'사용중': 'ใช้งานอยู่',
	'무료사용중': 'อยู่ในช่วงทดลองใช้งานฟรี',
	'자동결제': 'ชำระเงินอัตโนมัติ',
	'사용기간이': 'ระยะเวลาการใช้งาน',
	'일': 'วัน',
	'시간': 'ชั่วโมง',
	'남았습니다.': '',
	'다음 결제일': 'วันชำระเงินรอบถัดไป',
	'보유 중인 퀵뷰': 'QuickView ของฉัน',
	'보유중인 퀵뷰가 없습니다.': 'ไม่พบ QuickView ที่ใช้งานได้',
	'보유 중인 퀵뷰 선물권': 'QuickView Giftของฉัน',
	'전체 방송': 'รายการสดทั้งหมด',
	'아이템효과 닫기': 'ปิดการแสดงผลไอเท็ม',
	'채팅토글': 'แชท',
	'앱에서 참여하기': 'ดูผ่านแอพลิเคชั่น',
	'아프리카TV 앱에서 참여하기': 'รับชมผ่านแอพลิเคชั่น AfreecaTV',
	'이모티콘': 'อิโมติคอน',

	'광고 Skip, 본방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': 'ข้ามโฆษณา เข้าสู่ห้องแชทหลัก และใช้งานฟังก์ชั่นข้ามเวลาได้<br>เพียงใช้งาน<strong>ไอเทม QuickView</strong>และสิทธิพิเศษอื่นๆ อีกมากมาย!',
	'아프리카TV 상품권 등록 하세요!': 'ลงทะเบียนบัตรกำนัลของ AfreecaTV',

	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': 'ท่านต้องเข้าสู่ระบบก่อนจึงจะสามารถเพิ่มรายการโปรดได้ ต้องการเข้าสู่ระบบหรือไม่?',
	'즐겨찾기에 추가됐습니다.': 'เพิ่มเข้ารายการโปรดแล้ว',
	'바람의나라': 'World of Wind',
	'닉네임 변경': 'เปลี่ยนชื่อเล่น',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': 'ชื่อเล่นต้องเป็นภาษาอังกฤษความยาวไม่เกิน 12 ตัวอักษร',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': 'สามารถเปลี่ยนชื่อเล่นได้อีกครั้งหลังจากผ่านไป 24 ชั่วโมง',
	'닉네임 적용하기': 'เปลี่ยนชื่อเล่น',
	'중복확인': 'ตรวจสอบชื่อซ้ำ',
	'내정보': 'ข้อมูลของฉัน',
	'퀵뷰 정보': 'ข้อมูล QuickView',
	'아이템': 'ไอเท็ม',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': 'ต้องการลบออกจาก\nรายการโปรดหรือไม่',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': 'ต้องการลบออกจากรายการโปรดหรือไม่',

	'사용하기': 'ใช้งาน',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': 'เมื่อมีการใช้งานฟังก์ชั่น Blind คุณจะไม่สามารถรับชมหรือแชทได้ ไม่สามารถเข้ามาเพื่อรับชมใหม่ได้อีกโดยจะมีการแสดงผลว่าโดนเตะออกจากห้องแชทโดยอัตโนมัติ',
	'고객센터 문의하기': 'ติดต่อศูนย์ช่วยเหลือ',
	'비밀번호 설정 방송': 'รายการสดนี้ตั้งรหัสผ่านไว้',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'รายการสดนี้มีการตั้งรหัสผ่านไว้<br> กรุณารับชมผ่านแอพลิเคชั่น',
	'연령제한 방송': 'รายการจำกัดอายุ',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'รายการสดนี้สำหรับผู้ชมที่บรรลุนิติภาวะแล้ว<br> กรุณารับชมผ่านแอพลิเคชั่น',
	'연령제한 / 비밀번호 설정 방송': 'จำกัดอายุ / ติดรหัสผ่าน',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'รายการสดนี้สำหรับผู้ชมที่บรรลุนิติภาวะแล้ว <br>กรุณารับชมผ่านแอพลิเคชั่น',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': 'ไม่ได้ถ่ายทอดสดอยู่ <br>ดูเพิ่มเติมได้บนแอพลิเคชั่น AfreecaTV',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 시청이 가능합니다.': 'ไม่ได้ถ่ายทอดสดอยู่ <br>ดูเพิ่มเติมได้บนแอพลิเคชั่น AfreecaTV',
	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': 'BJ ได้หยุดรายการสดไว้ชั่วคราว',
	'다시 시작될 때까지 잠시만 기다려주세요.': 'กรุณารอสักครู่',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': 'หากมีข้อสงสัย หรือต้องการสอบถามเพิ่มเติมเกี่ยวกับการระงับการใช้งานกรุณาติดต่อศูนย์ช่วยเหลือ',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 있습니다.': 'เมื่อตรวจสอบจำนวน และระยะเวลาของ QuickView แล้ว สามารถเลือกคำสั่ง [ใช้งาน] เพื่อเริ่มการใช้งานไอเท็ม ได้ทันที',
	'즐겨찾기가 삭제되었습니다.': 'ลบออกจากรายการโปรดแล้ว',
	'알람': 'แจ้งเตือน',
	'유료': 'มีค่าใช้จ่าย',
	'알람 닫기': 'ปิดแจ้งเตือน',
	'알림 예약': 'ตั้งค่าแจ้งเตือน',
	'알림 취소': 'ยกเลิกการแจ้งเตือน',
	'현재 진행 중인 방송이 없습니다.': 'ไม่มีรายการสดในขณะนี้',
	'곧,  BJ들의 재미있고 다양한 방송이': 'BJ กำลังจะเริ่มต้น',
	'시작됩니다.': 'ถ่ายทอดสดแล้ว',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>ไม่มีรายการสดในขณะนี้</strong><br>BJ กำลังจะเริ่มต้น<br>ถ่ายทอดสดแล้ว',
	'방송이 시작되면': 'รับแจ้งเตือน',
	'알려 드릴까요?': 'เมื่อมีการเริ่มถ่ายทอดสดหรือไม่?',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>ตั้งค่าแจ้งเตือน<br>เรียบร้อยแล้ว',

	'운영자 강제퇴장 안내': 'การระงับการใช้งานโดยผู้ดูแล',
	'강제퇴장': 'ระงับการใช้งาน',
	'회차': 'ครั้งที่',
	'(채팅금지 24시간)': '(ไม่สามารถแชทได้ 24 ชั่วโมง)',
	'강제퇴장 및 3일 이용정지': 'ระงับการใช้งาน 3 วัน',
	'강제퇴장 및 7일 이용정지': 'ระงับการใช้งาน 7 วัน',
	'강제퇴장 되어 방송을 보실 수 없습니다.': 'ไม่สามารถรับชมได้เนื่องจากถูกระงับการใช้งาน',
	'앱을 설치하시면 구매가 가능합니다.': 'ต้องติดตั้งแอพลิเคชั่นก่อนทำการซื้อไอเท็ม',
	'[안내]': '[ประกาศ]',
	'방송에 입장하였습니다.': 'เข้าสู่การรับชมรายการสด',
	'채널 퇴장하셨습니다.': 'ท่านได้ออกจากห้องแชทแล้ว',
	'로그아웃 하시겠습니까?': 'ต้องการออกจากระบบใช่หรือไม่?',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'กรุณาเข้าสู่ระบบก่อนตั้งค่าแจ้งเตือน\nต้องการเข้าสู่ระบบหรือไม่?',
	'알림 예약이 완료되었습니다.': 'ตั้งค่าแจ้งเตือนเรียบร้อยแล้ว',
	'알림 예약이 취소되었습니다.': 'ยกเลิกการแจ้งเตือนเรียบร้อยแล้ว',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': 'รายการสดนี้รับชมได้เฉพาะผู้ชมที่มีอายุ 19 ปีขึ้นไป และสามารถรับชมได้ผ่านแอพลิเคชั่น AfreecaTV เท่านั้น \n\nต้องการรับชมผ่านแอพลิเคชั่นหรือไม่?',
	'유효기간': 'ระยะเวลาในการใช้งาน',
	'등록되었습니다.': 'ลงทะเบียนเรียบร้อยแล้ว',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': 'ไม่สามารถลงทะเบียนได้เนื่องจากมีการใช้งาน QuickView อยู่แล้ว',
	'오류가 발생하여 이용하실수 없습니다.': 'พบข้อผิดพลาดบางประการทำให้ไม่สามารถใช้งานได้',

	'검색결과': 'ผลการค้นหา',
	'검색결과가 없습니다.': 'ไม่พบสิ่งที่ค้นหา',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': 'เปลี่ยนชื่อเล่นเรียบร้อยแล้ว สามารถเปลี่ยนชื่อเล่นได้เพียงวันละครั้งเท่านั้น',
	'사용할 수 없는 닉네임입니다.': 'ไม่สามารถใช้งานชื่อเล่นนี้ได้',
	'닉네임 중복 확인을 해주세요.': 'กรุณาตรวจสอบชื่อเล่นซ้ำก่อน',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': 'เบราเซอร์ดังกล่าวไม่รองรับการแชทนี้ กรุณาใช้งานแชทผ่านแอพลิเคชั่น AfreecaTV',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'รายการสดนี้มีการตั้งรหัสผ่านไว้ กรุณารับชมผ่านแอพลิเคชั่น',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'รายการสดนี้มีการตั้งรหัสผ่านไว้ กรุณารับชมผ่านแอพลิเคชั่น',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': 'รายการสดดังกล่าวไม่สามารถรับชมได้ในต่างประเทศเนื่องด้วยข้อบังคับทางด้านลิขสิทธิ์',
	'강제퇴장 되어 방송을 볼 수 없습니다.': 'คุณถูกเตะออกจากรายการสดนี้',
	'로그인 되었습니다.': 'เข้าสู่ระบบเรียบร้อยแล้ว',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': 'การเชื่อมต่อไม่เสถียร ต้องการลองใหม่อีกครั้งหรือไม่?',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': 'เครือข่ายไม่เสถียร ทำให้ไม่สามารถเรียกข้อมูลทั้งหมดมาได้\n\nลองใหม่อีกครั้งหรือไม่?',
	'채팅 메세지를 입력하세요.': 'พิมพ์ข้อความที่นี่',
	'1개 이상 선물이 가능합니다.': 'ไม่มี Star Balloons',
	'보유하신 별풍선이 부족합니다.': 'ไม่มี Star Balloons',
	'보유하신 골드가 부족합니다.': 'ไม่มี Sticker',
	'보유하신 초콜릿이 부족합니다.': 'ไม่มี Chocolate',
	'선물하기 서버 문제로 이용하실 수 없습니다.': 'ไม่สามารถใช้งานหน้าไอเท็มได้ชั่วคราวเนื่องจากพบปัญหาบางประการบนเซิร์ฟเวอร์',
	'로그인 하셔야 선물 하실 수 있습니다.': 'กรุณาเข้าสู่ระบบก่อนส่งไอเท็ม',

	'즐겨찾기 바로가기': 'ไปยังรายการโปรด',
	'멀티방송 화면리스트': 'รายการหน้าต่างถ่ายทอดสดย่อย',
	'현재 유저수': 'ผู้ชมปัจจุบัน',
	'중계방': 'ห้องสำรอง',
	'PC버전으로 보기': 'ดูบน PC',
	'앱에서 보기': 'ดูบน App',
	'명': ' คน',

	'본방입장': 'เข้าชมรายการสด',
	'방송보기': 'รับชม',
	'잠시 쉬어갑니다.': 'ปิดปรับปรุงระบบ',
	'점검시간': 'ระยะเวลา',
	'점검영향': 'รายละเอียด',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': 'ขออภัยในความไม่สะดวก',
	'이용에 불편을 드려 죄송합니다.': 'ขณะนี้AfreecaTV กำลังอยู่ในช่วงปิดปรับปรุงระบบเพื่อ<br>เพิ่มประสิทธิภาพในการใช้งาน',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': 'ระบบจะกลับมาใช้งานได้ตามเวลาที่ระบุ',
	'채팅안보기 해제': 'ยกเลิกการปิดแชท',
	'채팅안보기': 'ปิดแชท',

	'즐겨찾기에 추가됐습니다.': 'เพิ่มเข้ารายการโปรดแล้ว',
	'[아프리카TV 안내]': '[ประกาศจาก AfreecaTV]',
	'운영자 안내': 'ประกาศจากผู้ดูแล',
	'운영자': 'ผู้ดูแล',
	'BJ': 'BJ',
	'매니저': 'ผู้จัดการ',
	'클린아티': 'Clean Arti',
	'%s에 의해 강제퇴장 되었습니다.': 'ถูกแบนออกจากแชท เนื่องจาก%s',
	'%s 님이 채팅금지 %s회가 되었습니다.': '%sได้รับการเตือน#$s',
	'로그인 서버오류가 발생하였습니다.': 'พบข้อผิดพลาดบนเซิร์ฟเวอร์ระหว่างการเข้าสู่ระบบ',
	'지원하지 않는 OS버전입니다.': 'ไม่รองรับระบบปฏิบัติการดังกล่าว',
	'아프리카TV 앱으로 시청해주세요.': 'กรุณารับชมผ่านแอพลิเคชั่น AfreecaTV',
	'버퍼링 중': 'กำลัังโหลด...',
	'애청자': 'ผู้ชม',
	'팬클럽': 'แฟนคลับ',
	'통합검색': 'ค้นหาแบบรวม',
	'생방송': 'รายการสด',
	'정확도순': 'ที่เกี่ยวข้อง',
	'방송시간순': 'ตามเวลาถ่ายทอดสด',
	'누적참여자순': 'ยอดคนดูรวม',
	'최신방송순': 'ถ่ายทอดสดล่าสุด',
	'적용 되었습니다.': 'ใช้งานแล้ว',
	'채팅창을 얼렸습니다.': 'แช่แข็งแชทนี้แล้ว',
	'채팅 참여 등급이 변경되었습니다.': 'เปลี่ยนระดัับผู้ใช้งานที่เข้าแชทแล้ว',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': ' BJ และผู้จัดการเท่านั้นที่แชทได้',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': ' BJ แฟนคลับ และผู้จัดการเท่านั้นที่แชทได้',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': ' BJ ผู้จัดการ และผู้สนับสนุนเท่านั้นที่แชทได้',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': ' BJ ผู้จัดการ แฟนคลับ และผู้สนับสนุนเท่านั้นที่แชทได้',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': 'ยกเลิิกการแช่แข็งแชท สามารถเข้าร่วมแชทได้ตามปกติ',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "ท่านไม่สามารถใช้ \'%s\' ได้ เนื่องจากเป็นคำต้องห้าม",
	'검색시스템에 문제가 발생했습니다.': 'พบข้อผิิดพลาดในระบบการค้นหา',
	'검색어를 입력하세요.': 'กรุณาระบุคำที่ต้องการค้นหา',
	'|중계방송|': '|ห้องสำรอง|',

	'BJ가 방송을 종료하였습니다.': 'BJ จบการถ่ายทอดสดลงแล้ว',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": "ไม่ต้องการ UP รายการสดของ \'%s\' นี้อีกใช่หรือไม่?",
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': 'หากต้องการเข้าสู่ห้องหลัก ต้องเปิดใช้งานไอเท็ม QuickView ก่อน ต้องการไปยังหน้าซื้อไอเท็ม QuickView หรือไม่?',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': 'กรณีที่ BJ ถ่ายทอดสดผ่านแอพลิเคชั่นด้วย Mobile Live Cam แล้วตั้งพักหน้าจอไว้ชั่วคราว อาจจะมีการแสดงผลหน้าจอที่ชะงักหรือเป็นจอดำขึ้นมา เมื่อมีการยกเลิกการพักหน้าจอ รายการสดจะดำเนินต่อเอง',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'สามารถทำการ UP ผ่านทางแอพลิเคชั่น AfreecaTV ได้ \n\nต้องการรับชมผ่านแอพลิเคชั่น AfreecaTV หรือไม่?',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'สามารถรับชมแบบคุณภาพสูงผ่านทางแอพลิเคชั่น AfreecaTV เท่านั้น\n\nต้องการรับชมผ่านแอพลิเคชั่น AfreecaTV หรือไม่?',
	' 이상~': 'ขึ้นไป~',

	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%s ถูกห้ามแชทเนื่องจาก%s ไม่สามารถแชทได้เป็นเวลา 30 วินาที',
	'%s님이 %s회가 되었습니다.': '%s ถูกห้ามแชทเป็นครั้งที่%s เนื่องจาก%s',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%s ถูกห้ามแชทเนื่องจาก%s ไม่สามารถแชทได้เป็นเวลา 60 วินาที',
	'%s 님이 채팅 금지 횟수 초과로 블라인드 되었습니다. 2분 동안 채팅과 방송화면을 볼 수 없습니다.': '%s ถูกห้ามแชทเกินกำหนด เนื่องจาก จึงถูก Blind เป็นเวลา 2 นาที',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%s ถูกห้ามแชทเกินกำหนด เนื่องจาก%s จึงถูก Blind เป็นเวลา 2 นาที เมื่อผู้ใช้งานถูก Blind จะไม่สามารถดูแชท และไม่สามารถเข้ามารับชมได้เนื่องจากจะถูกเตะออกจากแชทโดยอัตโนมัติ',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제 퇴장되었습니다.': '%s(%s) พยายามละเมิด Blind จึงถูกแบนออกจากแชท',
	'%s(%s)님이 강제퇴장 되었습니다.': '%s(%s) ถูกแบนออกจากแชทแล้ว',
	'%s(%s)님의 강제퇴장이 취소되었습니다..': 'ยกเลิกการแบน %s(%s) ออกจากแชทแล้ว',
    '%s님이 별풍선 %s개를 선물했습니다!': '%s ได้ส่ง Star Balloon จำนวน %s เป็นของขวัญ',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': '%s ได้ส่ง Star Balloon จำนวน %s เป็นของขวัญในช่องสำรอง',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': '%s ได้ส่ง Star Balloon จำนวน %s เป็นของขวัญในวิดีโอย้อนหลัง',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%s ได้ส่ง %s Video Balloon เป็นของขวัญ',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': '%s ได้ส่ง %s Video Balloon เป็นของขวัญในช่องสำรอง',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%s เป็นแฟนคลับลำดับที่%s แล้ว',
	'%s님이 스티커 %s개를 선물했습니다!': '%s ส่งของขวัญเป็น Sticker %s อัน!',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%s เป็นผู้สนับสนุนลำดับที่%s แล้ว',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': '%s ในห้องสำรอง ส่งของขวัญเป็น Sticker %s อัน!',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%s ส่งของขวัญเป็น Chocolate %s ชิ้น!',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': '%s ในห้องสำรอง ส่งของขวัญเป็น Chocolate %s ชิ้น!',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s ส่งของขวัญเป็น QuickView จำนวน %sวัน ให้แก่%s',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': 'ไม่สามารถรับชมผ่าน Mobile Web ได้\nกรุณารับชมผ่าน PC หรือแอพลิเคชั่นบน IOS หริือ AOS',

	'운영자에 의해 강제퇴장 되었습니다.': 'ผู้ดูแลจึงทำการแบนไปเป็นที่เรียบร้อย',
	'운영자에 의해<br ng-show="newLine">': 'ผู้ดูแลได้ทำการ ',
	'되었습니다': ' ',

	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                                                                                        \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                                                                                        \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': 'รายการสดนี้อาจจะเป็นอันตรายต่อเยาวชนตามกฏหมายปกป้องการใช้งานเครือข่ายสารสนเทศ และกฏหมายปกป้องเยาวชน \n\
                                                                                                                                                                                                                        \n\ระดับของรายการสดถูกเปลี่ยนแปลงทำให้ผู้มีอายุต่ำกว่า 19 ปีไม่สามารถเข้าชมได้ \n\
                                                                                                                                                                                                                        \n\หากท่านต้องการจะรับชม กรุณาทำการยืนยันตัวตนผ่านทางแอป',
	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': 'กำลังรับชม %s บน AfreecaTV ต้องการรับชมด้วยกันหรือไม่?',
	'채팅입력이 지원되지 않는 브라우져입니다.': 'เบราเซอร์ดังกล่าวไม่รองรับการแชทนี้',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>ลงทะเบียน<strong>บัตรกำนัล</strong>ของ AfreecaTV</span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': 'ไม่รองรับระบบปฏิบัติการดังกล่าว<br><span>กรุณาเข้ารับชม</span>ผ่านทางแอพลิเคชั่น AfreecaTV',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': 'เปิดแชท<br>ของ %s แล้ว',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': 'ปิดแชท<br>ของ %s แล้ว',

	'랭킹 홈': 'หน้าหลัก Ranking',
	'최고 유저 평균': 'ผู้ชมสูงสุดเฉลี่ย',
	'애청자 증가수': 'ยอดผู้ชมที่เพิ่มขึ้น',
	'UP 수': 'จำนวน UP',
	'펫방': 'สัตว์เลี้ยง',
	'더빙/라디오': 'รายการพากย์ / วิทยุ',
	'VOD BJ': 'วิดีโอโดย BJ',
	'VOD FAN': 'วิดีโอโดย FAN',
	'누적 애청자수': 'ยอดผูู้้ชมทั้งหมด',
	'누적 팬클럽수': 'ยอดแฟนคลับทั้งหมด',
	'랭킹 소통방 바로가기': 'ไปยังบอร์ดสนทนาของหน้า Ranking',
	'BJ랭킹 산정 방식 안내': 'เกณฑ์การคัดเลือก Ranking ของ BJ',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': 'ในส่วนของการจัดอันดับ BJ นั้นจะพิจารณาจากยอดการถ่ายทอดสด หรือผู้ชมตลอดสัปดาห์ที่ผ่านมา',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '(มีการจัดอันดับแยกในส่วนของ BJ ที่มีผู้ชมสูงสุด)',
	'VOD BJ 랭킹 산정 방식 안내': 'เกณฑ์การคัดเลือก Ranking วิดีโอโดย BJ',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': 'Ranking ของวิดีโอโดย BJ คือลำดับวิดีโอถ่ายทอดสดของ BJ ที่จะอัพโหลดอัตโนมัติไว้บนหน้าบล็อกช่องของ BJ เอง',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': 'ยอดผู้เข้าชมวิดีโอรวมในสัปดาห์ที่ผ่านมา',
	'VOD FAN 랭킹 산정 방식 안내': 'เกณฑ์การคัดเลือก Ranking วิดีโอโดย FAN',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': 'Ranking ของวิดีโอโดย BJ คือลำดับวิดีโอที่จัดทำโดยแฟนคลับ BJ ซึ่งเป็นวิดีโอที่ได้รับการตัดต่อแล้วนำไปอัพโหลดใหม่ทั้งหมด',

	'변경': 'เปลี่ยน',
	'아프리카TV 앱 실행하기': 'เปิดแอป Afreeca TV',
	'최고 유저': 'ผู้ชมสูงสุด',
	'BJ랭킹': 'อันดับ BJ',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': 'หากท่านทำการติดตั้งแอป<span> AfreecaTV</span><br> ท่านจะสามารถ<span>รับชมรายการได้ในความละเอียดสูงสุด</span>และใช้งานช่องสนทนาได้',
	'고화질로 시청하기': 'รับชมแบบ HD',




	'년 전': 'ปีที่แล้ว',
	'개월 전': 'เดือนที่แล้ว',
	'주 전': 'สัปดาห์ที่แล้ว',
	'일 전': 'วันที่ผ่านมา',
	'시간 전': 'ชั่วโมงที่แล้ว',
	'분 전': 'นาทีที่แล้ว',
	'초 전': 'วินาทีที่แล้ว',

	'위': 'Ranking',
	'보낸 시각': 'เวลาส่ง',
	'공유하기': 'แชร์',
	'다음 VOD': '',
	'검색': 'ค้นหา',
	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '<strong>AfreecaTV App</strong><span>ดาวน์โหลด</span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'ท่านต้องล็อกอินจึงจะใช้ระบบชมภายหลังได้ \nล็อกอินหรือไม่',
	'누적 애청자 수': '',
	'누적 팬클럽 수': '',
	'BJ 랭킹': '',
	'즐겨찾기 전체': 'เพิ่มเข้ารายการโปรดทั้งหมด',
	'최신등록순': 'เรียงตามล่าสุด',
	'참여인원순': 'เรียงตามยอดผู้ชม',
	'닉네임순': 'เรียงตามชื่อเล่น',
	'아이디순': 'เรียงตามไอดี',
	'최신방송순': 'เรียงตามวันที่',
	'LIVE 시청': 'รับชม Live',
	'목록 상단 고정 완료': 'ปักหมุดไว้ที่รายการแล้ว',
	'라이브&amp;VOD': 'Live &amp; VOD',
	'방송국 개설일': 'วันที่สมัคร',
	'최근방송': 'ถ่ายทอดสดล่าสุด',
	'LIVE 시청하기': 'รับชม Live',
	'방송국가기': 'ไปยังบล็อก',
	'목록 상단 고정': 'ปักหมุดรายการโปรด',
	'즐겨찾기한 BJ가 없습니다.': 'ไม่มี BJ ในรายการโปรดของท่าน สามารถทำการเพิ่ม BJ ',
	'목록 상단 고정 해제': 'เอาออกจากรายการ',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': 'ไม่พบรายการสดในรายการโปรด',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': 'ไม่มี VOD จาก BJ โปรดของท่าน　',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': 'ที่ท่านชื่นชอบเข้ารายการโปรดได้เพื่อติดตาม Live และ VOD ที่ท่านชื่นชอบ',
	'상단고정 완료되었습니다': 'ปักหมุดไว้ที่รายการแล้ว',
	'상단고정 해제 되었습니다': 'เอาออกจากรายการ',
	'지원하지 않는 메뉴입니다.': 'ท่านยังไม่สามารถใช้งานระบบนี้ได้',
	'수신거부 해제되었습니다.' : 'ปลดบล็อคเรียบร้อย!',
	'필터': 'คัดกรอง',
	'정렬기준': 'เรียงโดย',
	'구분': 'หมวดหมู่',
	'날짜': 'วันที่',
	'취소': 'ยกเลิก',
	'적용': 'ใช้งาน',
	'생방송': 'สด',
	'업로드VOD': 'VOD ที่อัพโหลด',
	'유저클립': 'คลิปของผู้ชม',
	'다시보기': 'VOD รีเพลย์',
	'하이라이트': 'VOD ไฮไลท์',
	'구독전용': 'เฉพาะสมาชิกเท่านั้น',
	'1년': '1 ปี',
	'고화질순': 'คุณภาพสูง',
	'고해상도순': 'ความละเอียดสูง',
	'애청자순': 'จำนวนความชอบ',
	'팬클럽순': 'จำนวนแฟน',
	'전체알림': 'เตือนทั้งหมด',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': 'รับการแจ้งเตือนทั้งหมดจาก BJ',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': 'ไม่รับการแจ้งเตือนทั้งหมดจาก BJ',
	'%s님에게 알림을 받습니다.': 'รับการแจ้งเตือนจาก %s',
	'%s님에게 알림을 받지 않습니다.': 'ไม่รับการแจ้งเตือนจาก %s',

	'방송제목 변경이력': 'ประวัติชื่อรายการ',
	'조회순': 'คนดู',
	'관련된 VOD': 'VOD ที่เกี่ยวข้อง',
	'검색어와 연관된 추천 영상입니다.': 'VOD ที่แนะนำและใกล้เคียงกับคำที่ค้นหา',
	'게시글': 'ตั้งกระทู้',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': 'ไม่พบกระทู้ที่ค้นหาจาก 1 เดือนที่ผ่านมา<br> กรุณาเปลี่ยนช่วงเวลาเป็น 1 ปี<br> เพื่อเพิ่มประสิทธิภาพในการค้นหา',
	'조회순': 'ยอดผู้ชม',

	'최근 검색어': 'ล่าสุด',
	'실시간 인기 검색어': 'นิยม',
	'개인화 추천검색어': 'ปรับแต่ง',
	'자동저장 <span>끄기</span>': '<span>ปิดการ</span>บันทึกอัตโนมัติ',
	'자동저장 <span>켜기</span>': '<span>เปิดการ</span>บันทึกอัตโนมัติ',
	'최근 검색어가 없습니다.': 'ไม่มีคำค้นหาล่าสุด',
	'최근 검색어 자동저장이 꺼져 있습니다.': 'ระบบการบันทึกอัตโนมัติสำหรับคำค้นหาล่าสุดถูกปิดอยู่',
	'자동저장 켜기': 'เปิดการบันทึกอัตโนมัติ',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': 'หากท่านทำการล็อกอิน<strong>ท่านจะสามารถใช้การค้นหาที่ปรับให้เข้ากับผู้ใช้ได้</strong>',
	'최근검색어를\n모두 삭제하시겠습니까?': 'ท่านต้องการจะลบ\nคำค้นหาล่าสุดทั้งหมดหรือไม่',
	'최근검색어 자동저장을 사용합니다.': 'เปิดการบันทึกอัตโนมัติสำหรับคำค้นหาล่าสุด',
	'최근검색어 자동저장 사용을 중지합니다.': 'ปิดการบันทึกอัตโนมัติสำหรับคำค้นหาล่าสุด',
	'개인화 추천 검색어가 없습니다.': 'ไม่มีคำค้นหาที่ปรับให้เข้ากับผู้ใช้',
	'검색한 검색결과입니다.': 'แสดงผลการค้นหาด้วยคำว่า',
	'검색결과 보기': 'แสดงผลการค้นหาด้วย',

	'나중에 보기' : 'รับชมภายหลัง',
	'즐겨찾기한 BJ의 VOD' : 'วิดีโอของ BJ ในรายการโปรด',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : 'ไม่มีวิดีโอของ BJ ในรายการโปรด',
	'즐겨찾기한 BJ의 방송이 없습니다.' : 'ไม่มีรายการสดของ BJ ในรายการโปรด',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : 'ต้องการลบประวัติการรับชมล่าสุดใช่หรือไม่?',
	'재생한 VOD를 모두 삭제하시겠습니까?' : 'ต้องการลบประวัติการรับชมทั้งหมดใช่หรือไม่?',
	'UP 한 VOD가 없습니다.' : 'ไม่มีวิดีโอที่ี่ UP ไว้',
	'즐찾 BJ': 'BJ โปรด',
	'구독 BJ': 'BJ ที่สนัับสนุน',
	'구독중인 BJ' : 'ช่องที่ติดตาม',
	'VOD 홈' : 'หน้าหลักวิดีโอ',
	'인기 VOD' : 'วิดีโอยอดนิยม',
	'인기' : 'วิดีโอยอดนิยม',
	'핫이슈 VOD' : 'วิดีโอฮิต',
	'TV클립' : 'คลิปทีวี',
	'인기 하이라이트' : 'ไฮไลท์ยอดนิยม',
	'드라마 인기클립' : 'คลิปละครยอดนิยม',
	'예능 인기클립' : 'คลิปเกมโชว์ยอดนิยม',
	'스포츠 인기클립' : 'คลิปกีฬายอดนิยม',
	'아프리카TV X TV클립' : 'AfreecaTV X คลิปทีวี',
	'예능' : 'เกมโชว์',
	'교양' : 'วัฒนธรรม',
	'시사' : 'ข่าว',
	'드라마' : 'ละคร',
	'스포츠' : 'กีฬา',
	'패션/뷰티' : 'แฟชั่น/ความงาม',
	'어린이' : 'เด็ก',
	'스페셜' : 'พิเศษ',
	'디지털' : 'ดิจิทัล',
	'뮤직' : 'เพลง',
	'라디오' : 'วิทยุ',
	'키즈' : 'เด็ก',
	'연예' : 'บันเทิง',
	'시사보도' : 'ข่าว',
	'라이프' : 'ไลฟ์สไตล์',
	'홈페이지' : 'หน้าหลัก',
	'주목할만한 클립' : 'คลิปแนะนำ',
	'인기 클립' : 'คลิปยอดนิยม',
	'클립 전체' : 'คลิป ทั้งหมด',
	'프로그램 전체' : 'โปรแกรม ทั้งหมด',
	'TV클립 목록이 없습니다.' : 'ไม่มีรายการทีวีคลิป',
	'인기 프로그램' : 'โปรแกรมยอดนิยม',

	'방송국 가기': 'ไปยังบล็อก',
	'기록' : 'ประวัติรับชม',
	'카테고리' : 'หมวดหมู่',
	'추천받지 않기' : 'ไม่ต้องแนะนำ',
	'이어서 보기' : 'ดูต่อไป',
	'최근 본 VOD' : 'วิดีโอที่ดูล่าสุด',
	'UP 한 VOD' : 'วิดีโอที่ UP',
	'나중에 보기 삭제' : 'ลบจากรับชมภายหลัง',
	'최근 본 VOD 삭제' : 'ลบวิดีโอที่ดูล่าสุด',
	'UP 취소' : 'ยกเลิก UP',
	'나중에 보기 목록에서 삭제 되었습니다.' : 'ลบออกจากรายการรับชมภายหลังแล้ว',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : 'ลบวิดีโอที่ดูล่าสุดไปแล้ว',
	'선택하신 VOD UP이 취소 되었습니다.' : 'ยกเลิกการ UP วิดีโอที่เลือกไว้แล้ว',
	'추천 받지 않기가 반영되었습니다.' : 'เลือกไม่รับการแนะนำแล้ว',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : 'ช่องนี้อยู่ในรายการโปรดของท่านอยู่แล้ว',
	'이미 나중에 보기 등록된 VOD 입니다.' : 'วิดีโอนี้ถูกเพิ่มในรายการรับชมภายหลังแล้ว',
	'전체 삭제' : 'ลบทั้งหมด',
	'나중에 보기 한 VOD가 없습니다.' : 'ไม่มีรายการรับชมภายหลัง',
	'최근 본 VOD가 없습니다.' : 'ไม่มีวิดีโอที่ดูล่าสุด',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : 'หากไปยังหน้าหลักของวิดีโอแล้ว<br>สามารถเลือกรับวิดีโอแนะนำที่คล้ายกันได้',
	'VOD 홈으로 이동' : 'ไปยังหน้าหลักของวิดีโอ',
	'구독' : 'สมัครสมาชิก',
	'카테고리 선택' : 'เลือกหมวดหมู่',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : 'ท่านสามารถดู VOD ทั้งหมดในหมวดประวัติรับชมได้หลังจากทำการล็อกอิน',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : 'เมื่อเข้าสู่ระบบแล้ว<br>สามารถดูรายการ VOD ของ BJ ในรายการโปรดได้',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : 'ต้องการลบรายการรับชมภายหลังทั้งหมดหรือไม่?',
	'UP이 취소되었습니다.' : 'ยกเลิกการ UP วิิดีโอเรียบร้อยแล้ว',
	'추천 받지 않기에 등록 되었습니다.' : 'เลือกไม่รับการแนะนำแล้ว',
	'즐겨찾기에서 삭제됐습니다.': 'ลบออกจากรายการโปรด',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?' : 'ท่านต้องเข้าสู่ระบบก่อนจึงจะสามารถเพิ่มรายการโปรดได้\nต้องการเข้าสู่ระบบหรือไม่?',
	'팬' : 'แฟน',
	'구독한 BJ가 없습니다.' : 'ยังไม่มี BJ ที่สนับสนุน',
	'구독한 BJ의 VOD가 없습니다.' : 'ไม่พบวิดีโอย้อนหลังของ BJ ที่ติดตาม',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : 'ท่านต้องการจะลบ %s ออกจากรายการโปรดหรือไม่',

	'추천' : 'แนะนำ',
	'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : 'ไปยังเมนูวิดีโอแนะนำ<br>เพื่อรับรายการวิดีโอแนะนำจากระบบ',
	'VOD 추천받기' : 'รับวิดีโอแนะนำ',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : 'กรุณาล็อกอิน\nท่านต้องการจะล็อกอินหรือไม่',
	'주소가 복사되었습니다.' : 'คัดลอกที่อยู่นี้แล้ว',
	'본인은 즐겨찾기 할 수 없습니다.' : 'ไม่สามารถเพิ่มช่องตัวเองในรายการโปรดได้',

	'TAG_VOD_LIST_COUNT' : '<span class="count">ทั้ั้งหมด <em class="point" text="@count"></em></span>',
	'TAG_VOD_FAVORITE_TITLE' : '<span class="bj_name_long">วิดีโอที่แนะนำโดย </span><a class="bj_name bj_name_long" text="@nickname"></a>',
	'TAG_VOD_RECENT_TITLE' : '<span class="bj_name_long">BJ ที่ </span><a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">ร ับชมบ่อย</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name">วิดีโอของ <span text="@nickname"></span></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : ' <span text="@count"></span>',

	'알림': 'แจ้งเตือน',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : 'ไม่ได้รับการแจ้งเตือน<br>กรุณาไปที่<a ng-click="setting()"><span>ตั้งค่าแล้วเลือกรับการแจ้งเตือ</span></a>นเพื่อรับข่าวสาร',
	'모두 읽음' : 'ทำเครื่องหมายว่าอ่านหมดแล้ว',
	'모두 삭제' : 'ลบทั้้งหมด',
	'읽은 알림 삭제' : 'ลบที่อ่านแล้วทั้งหมด',
	'알림 설정' : 'ตั้งค่าแจ้งเตือน',
	'설정' : 'ตั้งค่า',
	'%d분 전' : '%d นาทีก่อน',
	'%d시간 전' : '%d ชั่วโมงก่อน',
	'삭제' : 'ลบ',
	'알림이 삭제되었습니다.' : 'ลบแจ้งเตือนแล้ว',
	'숨기기' : 'ซ่อน',
	'알림 콘텐츠 설정' : 'เปิดการแจ้งเตือนคอนเทนต์',
	'초기화' : 'ค่าเริ่มต้น',
	'저장' : 'บันทึก',
	'푸시 설정' : 'ตั้งค่าแจ้งเตือน',
	'기록 설정' : 'ตั้งค่าประวัติต่างๆ',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : 'สามารถตั้งค่าหรือเปลี่ยนแปลงได้ที่ <a ng-click="setup_link()"><span>แจ้งเตือนแอพลิเคชั่น - ตั้งค่า - ตั้งค่าแจ้งเตือน</span></a>',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : 'ตั้งค่าเป็นค่าเริ่มต้น \nการตั้้งค่าจะถูกบันทึกกลับเป็นค่าเริ่มต้นทั้งหมด ระบบจะคืนค่าทั้งหมดเป็นเริ่มต้นเมื่อกดบันทึกการตั้งค่า',
	'설정이 저장되었습니다.' : 'บันทึกการตั้งค่าเรียบร้อยแล้ว',

	'발송취소' : 'ยกเลิกการส่ง',
	'해당 쪽지를 발송 취소하시겠습니까?' : 'ต้องการยกเลิกการส่งใช่หรือไม่?',
	'보관한 보낸 쪽지가 없습니다.' : 'ไม่มีข้อความที่ส่งไปแล้วบันทึกไว้ถาวร',
	'보관한 받은 쪽지가 없습니다.' : 'ไม่มีข้อความที่ได้รับบันทึกไว้ถาวร',
	'읽지 않음' : 'ยังไม่ได้อ่าน',
	'방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : 'ในบล็อก, %s ได้ส่ง Ad Balloons จำนวน %s เป็นของขวัญ.',
	'유효기간 만료순' : 'วันหมดอายุ',

	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : 'ต้องการแจ้งรายงานข้อความนี้ใช่หรือไม่?\n(ข้อความที่ถูกแจ้งรายงานจะถูกย้ายไปกล่องข้อความสแปม)',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : 'ต้องการยกเลิกสแปมของข้อความนี้ใช่หรือไม่?\n(ข้อความที่ถูกยกเลิกสแปมจะถูกย้ายกลับไปยังกล่องข้อความ)',
	'자동스팸분류':'กรองสแปมอัตโนมัติ',
	'스팸해제' : 'ยกเลิกสแปม',
	'스팸신고': 'สแปม',
	'보관함':'บันทึก',
	'스팸함':'สแปม',
	'스팸 쪽지가 없습니다.':'ไม่พบข้อความสแปม',
	'스팸 쪽지':'ข้อความสแปม',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.':'ข้อความสแปมจะถูกลบออกจากระบบอัตโนมัติ 90 วันหลังจากที่เปิดอ่าน',

	'"%s" 검색 결과' : 'ผลการค้นหาของ "%s"',
	'검색된 쪽지가 없습니다.' : 'ไม่พบข้อความที่ค้นหา',
	'검색어를 입력해 주세요.' : 'กรุณาเลือกคำที่ต้องการจะค้นหา',
	'내용' : 'เนื้อหา',
	'검색' : 'ค้นหำ',
	'더 알아보기' : 'อ่านเพิ่มเติม',
	'재생목록':'รายการเล่นซ้ำ',
	'재생목록에 담기':'เพิ่มเข้ารายการเล่น',
	'재생목록 퍼가기':'บันทึกเพลย์ลิสต์',
	'생성한 재생목록':'สร้างรายการเล่น',
	'재생목록 보관함':'คลังเพลย์ลิสต์',
	'재생목록이 없습니다.':'ไม่มีรายการเล่น',
	'새 재생목록 만들기':'ไม่มีรายการเล่น',
	'추가':'เพิ่ม',
	'새 재생목록':'รายการเล่นใหม่',
	'확인' : 'ยืนยัน',
	'VOD 추가순': 'เรียงตามวัน',
	'오래된순': 'เก่าสุด',
	'수정하기': 'แก้ไข',
	'삭제하기': 'ลบ',
	'업데이트' : 'อัพเดท',
	'공개': 'สาธารณะ',
	'비공개' : 'ส่วนตัว',
	'전체보기' : 'ดูทั้งหมด',
	'목록 공개': 'ตั้งรายชื่อให้เป็นสาธารณะ',
	'목록 비공개': 'ตั้งรายชื่อให้เป็นส่วนตัว',
	'목록 퍼가기 허용': 'อนุญาตให้บันทึกได้',
	'목록 퍼가기 비허용': 'ไม่อนุญาตให้บันทึก',
	'제목을 입력해주세요.': 'กรุณาระบุชื่อ.',
	'VOD가 없습니다.' : 'ไม่มีวิดีโอ.',
	'재생목록을 삭제하시겠습니까?' : 'ท่านต้องการจะลบรายการเล่นหรือไม่?',
    '저화질' : 'ปานกลาง',
    '일반화질' : 'สูง',
	'원본화질' : 'ต้นฉบับ',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : 'เลื่อน VOD ในแอปเพื่อทำการรับชมแบบ 360 องศา',
	'유료 광고 포함' : 'เนื้อหาแบบมีสปอนเซอร',
    '참여를 위해<br>인코딩 중입니다.': 'ก ำลังโหลด',
    '잠깐만 기다려주세요!': 'กรุณำรอสักครู่',
    '<span><em>초</em> 남았습니다.</span>': '<span>เหลืออีก <em>초</em></span>',
    '보유 중인 선물 받은 구독권' : 'บัตรของขวัญสมาชิกที่ได้รับของฉัน',
    '보유 중인 구독 선물권' : 'บัตรของขวัญสมาชิกของฉัน',
    '아이템 정보' : 'ข้อมูลไอเทม',
    '광고 Skip, Full방 입장, 고화질 방송참여가 가능한<br>퀵뷰아이템을 만나보세요.' : 'ใช้งาน QuickView เพื่อข้ามโฆษณา<br> เข้าสู่ห้องถ่ายทอดสดหลัก<br> และรับชมด้วยคุณภาพระดับ HD',
    '선물/후원 정보' : 'ข้อมูลของขวัญ/การบริจาค',
	'교환' : 'แลกเปลี่ยน',

	'사용하러 바로가기':'ใช้ทันที',
	'구독 구매하기':'สมัครสมาชิก',
	'구매하러 바로가기':'ซื้อทันที',
    '연속선물' : 'อัตโนมัติ +1',
    '연속선물이란?' : 'อัตโนมัติ +1?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : 'หากเปิดใช้งานฟังก์ชั่นอัตโนมัติ +1 หน้าต่างแสดงไอเท็ม<br>ยังคงแสดงผลอยู่ และจำนวน Star Balloon <br>จะถูกเพิ่มเป็น +1 เมื่อกดส่งออกไป',
	'구매한 스티커' : 'สติ๊กเกอร์ที่ซื้อ',
	'선물받은 스티커' : 'สติ๊กเกอร์ที่ได้รับ',
	'스티커 아이템' : 'ไอเทมสติ๊กเกอร์',
	'스티커 교환하기' : 'แลกสติ๊กเกอร์',
	'아이템 정보' : 'ข้อมูลไอเทม',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : 'ท่านไม่สามารถลงทะเบียนได้ในขณะที่ใช้ QuickView Plus อยู่',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : 'ท่านไม่สามารถลงทะเบียนได้ในขณะที่ใช้ QuickView อยู่',

	'플러스로 전환' : 'เปลี่ยนเป็น Plus',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : 'เฉพาะ QuickView ที่มีการจ่ายเงิน และบัตรของขวัญ QuickView ที่มีการจ่ายเงิน\nเท่านั้นที่สามารถอัพเกรดไปเป็น QuickView Plus ได้',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : 'ใช้งาน QuickView แล้ว\nท่านมีโอกาสได้อัพเกรดตั๋ว QuickView ให้เป็น QuickView Plus \nท่านต้องการจะอัพเกรดหรือไม่',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "ได้ทำการใช้ QuickView แบบฟรีไปแล้ว<br>โดย QuickView แบบฟรีจะไม่สามารถอัพเกรดไปเป็น QuickView Plus ได้",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "ท่านได้ใช้งาน QuickView Plus ฟรีไปแล้ว",
	"퀵뷰가 사용되었습니다.": "ได้ทำการใช้ QuickView ไปแล้ว",
	"퀵뷰 플러스가 사용되었습니다.": "ได้ทำการใช้ QuickView Plus ไปแล้ว",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : 'โอกาสในการอัพเกรด<br><strong>เป็น QuickView Plus!</strong>',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : 'อัพเกรดตั๋ว QuickView ปัจจุบันของท่าน เป็น QuickView Plus.',
	'이용기간' : 'ระยะเวลาใช้งาน',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : 'ช่วงเวลาการใช้งานจะถูกคำนวณจากวันที่ทำการอัพเกรด และเวลาที่สามารถใช้งานได้ทั้งหมดจะถูกขยาย',
	'퀵뷰 플러스의 더 커진 혜택' : 'ได้รับประโยชน์มากขึ้นจาก QuickView Plus',
	'VOD 광고 NO!' : 'วิดีโอโฆษณา NO!',
	'FULL방 입장' : 'เข้าไปยังห้องหลักที่เต็ม',
	'방송 광고 NO!' : 'ไม่มีโฆษณา!',
	'멀티뷰' : 'รับชมหลายจอ',
	'방송 미리보기(모바일)' : 'พรีวิวรายการ (มือถือ)',
	'퀵뷰 사용 기간 별 전환 금액' : 'ชำระเงินค่าอัพเกรดตามจำนวนวันที่ใช้ไป',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : 'เมื่อท่านทำการชำระเงินแล้ว ช่วงเวลาการใช้งานจะถูกคำนวณจากวันที่ทำการอัพเกรด <strong>และเวลาที่สามารถใช้งานได้ทั้งหมดจะถูกขยาย</strong>',
	'사용' : 'ใช้มา',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'LIVE/VOD 광고 NO' : 'ไม่มีโฆษณาใน LIVE/VOD',
	'플러스로 전환하기' : 'เปลี่ยนเป็น Plus',
	'기존 퀵뷰로 사용하기' : 'ใช้งาน QuickView แบบปัจจุบัน',
	'※부가세 미포함' : '※ไม่รวม VAT',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : 'ท่านได้ใช้ไอเทมนี้มาเป็นเวลา %s วัน ดังนั้นท่านจึงสามารถอัพเกรดเป็น <strong>QuickView Plus 30 วัน</strong> ได้ด้วยการชำระเงินเป็นจำนวน <span>%s</span>',
	'더 저렴하고! 기간도 길게!<br>(최초 1회 제공)' : 'คุ้มค่ากว่า! ใช้งานได้นานกว่า! <br>(ครั้งแรกครั้งเดียวเท่านั้น)',
	'선물 ・ 후원 정보' : 'ข้อมูลของขวัญ・การบริจาค',
	'무료체험중' : 'ทดลองใช้ฟรี',
	'(iOS자동결제)' : '(ชำระอัตโนมัติผ่าน iOS)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'เนื่องจากท่านได้ทำการใช้งานการสมัครสมาชิกของ Apple (ชำระเงินอัตโนมัติ) \nท่านจะไม่สามารถเปลี่ยนช่องทางการชำระเงินได้\nบนอุปกรณ์เครื่องนี้ได้\nกรุณาใช้ iPhone(หรือ iPad) App Store > Apple ID > การสมัครสมาชิก',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'เนื่องจากท่านได้ทำการใช้งานการสมัครสมาชิกของ Apple (ชำระเงินอัตโนมัติ) \nท่านจะไม่สามารถยกเลิกการสมัครสมาชิกได้\nบนอุปกรณ์เครื่องนี้ได้\nกรุณาใช้ iPhone(หรือ iPad) App Store > Apple ID > การสมัครสมาชิก',
	'해지 신청중' : 'อยู่ระหว่างส่งเรื่องยกเลิก',
	'2개의 방송을 동시에! 모바일 멀티뷰' : 'ชม 2 รายการพร้อมกัน! รับชมหลายจอมือถือ',

	'내가 보유한 스티커' : 'สติ๊กเกอร์ที่มีอยู่',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : 'หากทำการส่งมากกว่า 100 Signature Balloon ขึ้นไป ท่านจะสามารถส่งคลิปแนบไปด้วยได้',
	'소개글' : 'แนะนำ',
	'공지' : 'แจ้งเตือน',
	'나이' : 'อายุ',
	'경력/수상' : 'ประวัติ / รางวัล',
	'수상 메달' : 'เหรียญ',
	'앗 혹시 찾으시는 분이...' : "ใช่คนที่ท่านตามหาหรือไม่?",
	'지금 이 콘텐츠에 주목!' : 'กรุณาตั้งสมาธิกับเนื้อหานี้',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '<span class="normal">เนื้อหาที่แนะนำสำหรับคนที่ค้นหา</span> \'{{ query }}\'',
	'누적 참여자' : 'ยอดผู้ชมสะสม',
	'방송 시간' : 'เวลาถ่ายทอดสด',

	'쉬는시간 후 방송이 이어집니다.' : 'รายการสดจะเริ่มอีกครั้งหลังจากเวลาพัก',
	'방송에 후원되는 광고입니다.' : 'โฆษณาช่วยสนับสนุนรายการสด',
	
	'URL 복사' : 'คัดลอก URL',
	'구독하러 바로가기':'ซื้อทันที',

	'빠른 답장' : 'ตอบด่วน',
	'기본 메시지로 저장' : 'บันทึกเป็ นข ้อความตั้งต ้น',
	'보내기' : 'ตอบกลับ',
	'후원 쪽지' : 'ข ้อความสนับสนุน',

	'선물 메세지' : 'ข้อความของขวัญ',
	'목소리 선택' : 'เลือกเสียง',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : 'ส่งสติ๊กเกอร์ให้กับ BJ<br>เพื่อให้ใช้แจ้งเตือนด้วยเสียงได้!',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : 'หาก BJ ไม่ได้เปิดใช้งาน E-Assistant หรือ มีการตั้งค่าเพิ่มเติมต่างหาก อาจทำให้ไม่มีการแสดงผลขึ้นมา',

	'영상 광고 없는 VOD 재생!' : 'รับชมวิดีโอโดยไม่มีโฆษณา!',
	'캐치 광고 제외' : 'ไม่รวมโฆษณา Catch',
	'방송 입장 영상광고 NO!' : 'ไม่มีโฆษณาเมื่อรับชมรายการสด!',
	'배너형, 로딩광고, LIVE 중간광고 제외' : 'ยกเว้นแบนเนอร์โฆษณา โฆษณาระหว่างโหลด โฆษณาระหว่างรายการสด',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':'เพื่อเป็นการปกป้องเยาวชน<br>เนื้อหานี้มีการจำกัดอายุ<br>เฉพาะผู้ใช้ที่<strong>มีอายุมากกว่า 19 ปีขึ้นไป</strong> จึงจะสามารถรับชมได้<br>ท่านสามารถรับชมได้เฉพาะทางแอป AfreecaTV เท่านั้น',
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':'เพื่อเป็นการปกป้องเยาวชนเนื้อหานี้มีการจำกัดอายุ\nเฉพาะผู้ใช้ที่มีอายุมากกว่า 19 ปีขึ้นไปจึงจะสามารถรับชมได้\n\nท่านต้องการจะรับชมบนแอป AfreecaTV หรือไม่',	

	'대결미션' : 'ภารกิจต่อสู้', 
	'대결미션이란?': 'อะไรคือภารกิจต่อสู้',
	'대결단계에서만 후원 가능합니다.': 'ท่านสามารถบริจาคได้เฉพาะช่วงเวลาต่อสู้เท่านั้น',
	'보유 별풍선이 즉시 차감됩니다.': 'จะถูกหักออกจากจำนวน Star Balloon',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': 'เป็นระบบที่ BJ เข้าร่วมทีมเพื่อจัดการแข่งขัน และแบ่ง Star Balloon ที่ได้รับตามผลของการแข่ง',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': 'การบริจาคที่เกิดขึ้นจะไม่นับว่าเป็นการบริจาคให้กับ BJ แต่จะถูกส่งเข้าไปยังภารกิจต่อสู้แทน',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': 'เมื่อส่งของขวัญ Star Balloon ให้ BJ แล้ว จะถูกนับว่าสมัครเป็นแฟนคลับ และถูกจัดเข้าลำดับแฟนโดยอัตโนมัติ',
	
	'도전미션' : 'ภารกิจ',
	'보유 별풍선 :' : 'Star Balloons :',
	'도전미션이란?' : 'ภารกิจ คืออะไร?',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : 'สมัครเข้าร่วมภารกิจ BJ<br />แล้วลองโดเนทสนับสนุนเป็นเงินรางวัลดูสิ!',
	'새로운 미션 요청' : 'คำขอภารกิจ',
	'후원할 별풍선' : 'Star Balloon ที่โดเนท',
	'후원하기' : 'โดเนท',
	'미션 내용을 입력해 주세요. (최대 30자)' : 'กรุุณาระบุรายละเอียดของภารกิจ (ไม่เกิน 30 ตัวอักษร)',
	'미션 제한 시간' : 'ระยะเวลาภารกิจ',
	'도전미션 일': 'วัน',
	'도전미션 시간': 'ชั่วโมง',
	'도전미션 분' : 'นาที',
	'미션 상금' : 'เงินรางวัลภารกิจ',
	'미션 요청' : 'คำขอภารกิจ',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : 'บริการที่ช่วยส่งเสริมผู้ใช้งานในเรื่องการโดเนทสนับสนุน BJ ให้สามารถท้าทายภารกิจต่างๆ ให้สำเร็จ',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : 'ผู้ใช้งาน หรือ BJ สามารถทำการสมัครหรือท้าทายภารกิจเองได้',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : 'ผลลัพธ์ของภารกิจจะถูกตัดสินโดย BJ ผู้ใช้งานสามารถตัดสินได้เพียงแค่ ‘สำเร็จ’ หรือไม่เท่านั้น',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : 'การทำภารกิจท้าทายต่างๆ ขึ้นอยู่ความเชื่อใจระหว่าง BJ และผู้ใช้งาน ผลลัพธ์จากภารกิจจะไม่สามารถเปลี่ยนแปลงได้ไม่ว่ากรณีใดๆ ก็ตาม',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : 'หากพ้นกำหนดเวลาแล้วภารกิจจะไม่สำเร็จโดยทันที',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : 'หากภารกิจสำเร็จ เงินสนับสนุนจะถูกโอนให้แก่ BJ ต่อไป',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : 'หากมีการยกเลิก หรือภารกิจไม่สำเร็จ เงินรางวัลจะถูกคืนกลับให้ผู้ใช้งาน',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : 'BJ สามารถทำการต่อเวลาของภารกิจได้ 1 ครั้ง (สูงสุด 10 วัน)',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : 'ภารกิจที่ค้าง หรือกำลังดำเนินการอยู่ สามารถโดเนทสนับสนุนเงินรางวัลเพิ่มเติมได้ตลอด',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : 'ยอดจำนวนแฟนคลับ หรอื VIP จะถูกอัพเดทเมื่อภารกิจสำเร็จ และ BJ ได้รับเงินรางวัล',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : 'หากมีประวัติการยกเลิกสะสม 5 ครั้งขึ้นไปในระยะเวลา 1 วันล่าสุด จะไม่สามารถให้ BJ คนใดทำภารกิจได้เป็นเวลา 24 ชั่วโมง กรุณาพิจารณาอย่างรอบคอบก่อนยื่นขอภารกิจ',
	
	'아이템은 여기서 구매하세요!' : 'ซื้อไอเทมที่นี่!',		
	'1:1 문의 바로가기' : 'ไปยังสอบถาม 1:1',
	'내 애드벌룬 확인하고 선물하기' : 'ตรวจสอบ Ad Balloon และการบริจาคของฉัน',

	'규칙에 동의합니다!' : 'ยอมรับกฎการแชททั้งหมด',
	
	'내 별풍선' : 'สนับสนุน',
	'내 별풍선ㆍ아이템' : 'สนับสนุน ·ไอเทม',
	'드롭스 이벤트 진행 중' :'กำลังดำเนินกิจกรรม Drops อยู่',

	'퀵뷰': 'QuickView',
	'퀵뷰 플러스': 'QuickView Plus',
	'만료': 'หมดอายุ',
	'예약': 'ตั้งเวลาแล้ว',
	'사용 예약': 'ใช้งาน',
	'예약 취소': 'ยกเลิก',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': '%s ตั้งเวลาใช้งานแล้ว \n\nหาก Quick View ที่ใช้งานอยู่หมดอายุ ระบบจะดำเนินการต่ออายุด้วยไอเทมที่ตั้งเวลาไว้โดยอัตโนมัติ',
	'%s 사용을 예약 취소하시겠습니까?': 'ต้องการยกเลิกการเปิดใช้งาน %s ล่วงหน้าหรือไม่?',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': '%s ยกเลิกการตั้งเวลาใช้งานแล้ว\n\nหากต้องการตั้งเวลาใช้งานกรุณาไปที่เมนู \'ตั้งเวลาใช้งาน\'',

	'공식방송국':	'ช่องทางการ',
	'공식 방송국':'ช่องทางการ',
	'관련BJ':	'BJ เกี่ยวข้อง',
	'관련 BJ':	'BJ เกี่ยวข้อง',
	'이벤트':	'กิจกรรม',
	'시리즈게임':	'เกมซีรีส์',
	'시리즈 게임':	'เกมซีรีส์',
	'전체':	'ทั้งหมด',
	'명':	'คน',
	'관련BJ<br>더보기':	'ดูเพิ่มเติม<br>BJ ที่เกี่ยวข้อง',
	'LIVE':	'LIVE',
	'다시보기':	'ย้อนหลัง',
	'경기없음':	'ไม่มีสำรอง',
	'경기취소':	'ยกเลิก',
	'경기예정':	'กำหนดการ',
	'결과<br>보기':	'ดู<br>ผล',
	'참여인원순':	'ยอดผู้เข้าร่วม',
	'시청인원순':	'ยอดผู้ชม',
	'최근방송':	'ถ่ายทอดสดล่าสุด',
	'LIVE 참여하기':	'รับชม LIVE',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'ไม่พบถ่ายทอดสดล่าสุด<br>เริ่มถ่ายทอดสดในหมวดนี้ดูสิ!',
	'예정된 경기가 없습니다.':	'ไม่พบกำหนดการแข่งขัน',
	'경기종료':	'สิ้นสุด',
	'최신방송순':	'ล่าสุด',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '<span class="normal">BJ แนะนำจากผลการค้นหา</span> \'{{ query }}\'',

	// 모웹 메인 footer에서 사용
	'이용약관': 'ข้อตกลงการใช้งาน',
	'개인정보 처리방침': 'นโยบายความเป็นส่วนตัว',
	'대표이사': 'CEO',
	'사업자번호': 'หมายเลขทะเบียนนิติบุคคล',
	'사업자 정보 확인': 'ตรวจสอบข้อมูลธุรกิจ',
	'호스팅 제공자': 'ผู้ให้บริการ',
	'주식회사 숲': 'ⓒ SOOP Corp.',
	'주소': 'ที่ตั้ง',
	'경기도 성남시 분당구 판교로 228번길 15 판교세븐밴처밸리 1단지 2동 주식회사 숲(삼평동)': 'Pangyo 7 Venture Valley 1-2, 15, PangGyo-ro 228beon-gil, Bundang-Gu, SeongNam-Si, GyeongGi-Do, Korea',
	'대표 번호': 'เบอร์ติดต่อ',
	'이메일': 'อีเมล์',
	'FAX': 'แฟกซ์',
	'사업자 정보': 'ข้อมูลธุรกิจ',
	'통신판매번호': 'หมายเลขธุรกิจประเภทสื่อสารโทรคมนาคม',
	'\'{{ query }}\' 검색한 유저를 위한 추천 Catch' : 'Catch แนะนำ สำหรับการค้นหา \'{{ query }}\'',
	'계정연결': 'เชื่อมต่อบัญชี',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'본방 입장': 'เข้าสู่ห้องแชทหลัก',
	'남자': 'ชาย',
	'여자': 'หญิง',
	'캐릭터': 'คาแรคเตอร์',

	'종료된 방송입니다.': 'รายการสดนี้จบลงไปแล้ว',
	'지금 바로 아프리카TV의 다양한 생방송과<br>재미있는 VOD를 만나보세요!': 'เข้าสู่ AfreecaTV เพื่อรับชมคอนเทนต์ถ่ายทอดสดและ VOD ที่น่าสนใจ!',
	'VOD 보러가기': 'รับชม VOD'
};
