ItemFactory.$inject = ['ItemService', 'gettextCatalog'];
function ItemFactory(ItemService) {


        /**
         * 스토리텔링 스티커 찾기
         * @param {int} cnt
         * @returns {String}
         */
        var serchStoryBalloon = function (cnt) {
                if (item.data && item.data.balloon) {
                        var list = item.data.balloon.list
                        for (var i in list) {
                                console.log(list[i].code, cnt);
                                if (list[i].code == cnt) {
                                        return item.data.balloon.url + item.data.balloon.image_prefix + list[i].code + '.png';
                                }
                        }
                }
                return null;
        }
        var itemList;

        return {

                /**
                 * 별풍선
                 * @param {type} cnt
                 * @returns {String}
                 */
                getStarBalloon: function (cnt, file_name) {
                    if(file_name != '') {
                        return STATIC_FILE_AFREECATV + '/starballoon/story_m/' + file_name + '.png';
                    } else {
                        if( cnt <= 10 ) {
                            return STATIC_FILE_AFREECATV + '/starballoon/default_10.png';
                        } else if( cnt > 10 && cnt <= 50) {
                            return STATIC_FILE_AFREECATV + '/starballoon/default_50.png';
                        } else if( cnt > 50 && cnt <= 100) {
                            return STATIC_FILE_AFREECATV + '/starballoon/default_100.png';
                        } else if( cnt > 100 && cnt <= 300) {
                            return STATIC_FILE_AFREECATV + '/starballoon/default_500.png';
                        }  else if( cnt > 300) {
                            return STATIC_FILE_AFREECATV + '/starballoon/default_500.png';
                        }
                    }
                },
                /**
                 * 비디오 풍선 세레모니용 URL
                 * @param {type} cnt
                 * @returns {String}
                 */
                getVideoballoon : function(cnt, file_name) {
                    return RES_AFREECATV + '/images/mobile/afweb/img_videoballoon.png';
                }, 
                /**
                 * 초코릿 URL
                 * @param {type} cnt
                 * @returns {String}
                 */
                getChocolate: function (cnt) {
                        if (cnt <= 5) {
                                return RES_AFREECATV + '/new_player/items/thumb/effect_chocolate_5.png';
                        }
                        else if (cnt <= 10) {
                                return RES_AFREECATV + '/new_player/items/thumb/effect_chocolate_10.png';
                        }
                        else if (cnt <= 50) {
                                return RES_AFREECATV + '/new_player/items/thumb/effect_chocolate_50.png';
                        }
                        else {
                                return RES_AFREECATV + '/new_player/items/thumb/effect_chocolate_100.png';
                        }
                },
                /**
                 * 스티커 URl
                 * @param {type} code
                 * @returns {String}
                 */
                getSticker: function (code) {

                        if (itemList && itemList.sticker) {
                                return itemList.sticker.url + itemList.sticker.image_prefix + code + '.png';
                        } else {
                                return RES_AFREECATV + '/new_player/items/0.png';
                        }
                },

                getItemImages: function () {
                        /**
                         * 아이템정보 불러오기
                         */
                        return new Promise(function (resolve, reject) {
                                ItemService.itemImages().then(function (response) {
                                        itemList = response;
                                        resolve(itemList);
                                })
                        });
                }





        };
}
module.exports = ItemFactory
