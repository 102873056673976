

SearchService.$inject = ['$http'];
function SearchService($http) {
    this.checkWord = function (data) {

        var newData = angular.merge({}, {
            v: '1.0'
            , m: 'stopWord'
            , w: 'webm'
            , t: 'json'
            , c: 'utf-8'
            , ut: 'sv'
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCKETC_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.ret == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };

    this.profile = function (data) {
        var newData = angular.merge({}, {
            v: '3.0'
            , m: 'profileTheme'
            , w: 'webm'
            , isMobile: 1
            , c: 'UTF-8'
            , t: 'json'
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCH_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.RESULT == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };    
    

    this.live = function (data) {
        var newData = angular.merge({}, {
            v: '1.0'
            , m: 'liveSearch'
            , w: 'webm'
            , isMobile: 1
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCH_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.result == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };

    this.video = function (data) {
        var newData = angular.merge({}, {
            v: '5.0'
            , m: 'vodSearch'
            , w: 'webm'
            , isMobile: 1
            , align_type: 'list'
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCH_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.result == 1) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };

    this.bj = function (data) {
        var newData = angular.merge({}, {
            v: '1.0'
            , m: 'bjSearch'
            , w: 'webm'
            , isMobile: 1
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCH_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.result == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };

    this.post = function (data) {
        var newData = angular.merge({}, {
            v: '1.0'
            , m: 'postsSearch'
            , w: 'webm'
            , isMobile: 1
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCH_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.result == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };

    this.actionLog = function (data) {

        var newData = angular.merge({}, {
            v: '1.0'
            , m: 'actionLog'
            , w: 'webm'
            , t: 'json'
            , c: 'utf-8'
        }, data);

        return new Promise(function (resolve, reject) {
            $http.post(SCKETC_AFREECATV + '/api.php', $.param(newData || '')).then(function (response) {
                if (response.data.ret == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    }

}
module.exports = SearchService;