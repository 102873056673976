

CountryService.$inject = ['$http'];
function CountryService($http) {
  this.getIpCountryCode = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/country/a/getIpCountryCode", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };
}


module.exports = CountryService;