NotificationController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', 'Util', 'LoginManager', 'NotificationService', 'gettextCatalog', 'gtext']
function NotificationController($scope, $rootScope, $state, $http, $timeout, Util, LoginManager, NotificationService, gettextCatalog, gtext) {

    //이전페이지 이동
    $scope.back = function () {
        history.back();
    }

    //세부메뉴 
    $scope.more_menu = function () {
        $(".info_layer").toggle();
    }

    //알림설정 페이지이동
    $scope.setting = function () {
        location.replace(M_AFREECATV + "/#/notification/notisetting");
    }

    $("body").removeClass("liveArea");    
}
module.exports = NotificationController;
