module.exports = function activeIcon() {
	return {
		restrict: 'A',
		scope: true,
		link: function ($scope, $element, $attrs) {
			$scope.$watch('oSelectedCategory.category_no', function (newValue, oldValue) {
				if (newValue === $attrs.categoryNo) {
					$element.attr('style', 'display:block ');
				} else {
					$element.attr('style', 'display:none ');
				}
			});

		}
	}
}

