
ThumbnailListFactory.$inject = ['$rootScope', '$location'];
function ThumbnailListFactory($rootScope, $location) {

        var that = this;
        var _videoElem;
        var _aMultiThumbnail = [];
        var _container;
        var _requestId;
        var _videoWidth;
        var _videoHeight;
        var _selectIndex = 0;
        var MainThumbnail = function () {
                var _container;
                var _elem;
                var _img;
                var _url;
                var count = 0;
                this.create = function (container, url) {
                        _container = container;
                        _url = url;
                        _elem = $('<li class="play"><a><span><em></em></span><img ></a></li>');
                        _img = _elem.find('img');
                        _elem.click(this.clickHandler);
                        _container.append(_elem);
                }
                this.clickHandler = function (event) {
                        $(_container).trigger("click_event", 0);
                }
                this.setPosition = function (x, y, width, height) {
                }
                this.draw = function () {
                        if (count % (1000 / 30 * 10) == 0) {
                                _img.attr('src', _url + '?r=' + Math.random())
                        }
                        count++;
                }
                this.remove = function () {
                        $(_elem).remove();
                }
        }
        var MultiThumbnail = function () {
                var _container;
                var _videoElem;
                var _elem;
                var _canvas, _context;
                var _x, _y, _width, _height;
                var that = this;
                this.create = function (index, container, videoElem) {
                        _container = container;
                        _videoElem = videoElem;

                        //기본브라우져에서 Canvas가 작으면 캡쳐가 안됨 ㅜㅜ 왜?ㅜㅜ
                        _elem = $('<li class="play"><a><span><em></em></span><canvas width="500" height="480"/></a></li>');
                        _elem.data('index', index);
                        _elem.click(this.clickHandler);
                        _container.append(_elem);
                        _canvas = _elem.find('canvas');
                        _elem.find('span').hide();
                        _context = _canvas.get(0).getContext('2d');
                }
                this.clickHandler = function (event) {
                        var index = $(event.currentTarget).data('index');
                        $(_container).trigger("click_event", index);
                }
                this.setPosition = function (x, y, width, height) {
                        _x = x;
                        _y = y;
                        _width = width;
                        _height = height;
                }
                this.draw = function () {
                        _context.drawImage(_videoElem, _x, _y, _width, _height, 0, 0, 120, 90);
                }
                this.remove = function () {
                        $(_elem).remove();
                }
        }
        return {
                setInfo: function (info, videoWidth, videoHeight) {
                        _info = info;
                        _videoWidth = videoWidth;
                        _videoHeight = videoHeight;
                },
                create: function (container, video) {
                        remove();
                        _videoElem = video.get(0);
                        _container = container;

                        //메인썸네일
                        var mainThumbnail = new MainThumbnail();
                        mainThumbnail.create(container, $(video).attr('poster'));
                        _aMultiThumbnail.push(mainThumbnail);

                        //멀티뷰썸네일
                        var length = _info.points.length;
                        for (var i = 0; i < length; i++) {
                                var point = _info.points[i];
                                var thumb = new MultiThumbnail();
                                thumb.create((i + 1), container, _videoElem);


                                var videoRate = _videoWidth / _info.width;	//영상크기와 실제크키 비율
                                var srcX = point[0] * videoRate;
                                var srcY = point[1] * videoRate;
                                var srcWidth = point[2] * videoRate;
                                var srcHeight = point[3] * videoRate;

                                thumb.setPosition(srcX, srcY, srcWidth, srcHeight);
                                _aMultiThumbnail.push(thumb);
                        }

                        setChannel(_selectIndex);
                },
                reload: function () {
                        create(_container, $(_videoElem));
                        draw();
                },
		/**
		 * 선택된 썸네일 표시
		 * @param {type} index
		 * @returns {undefined}
		 */
                setChannel: function (index) {
                        if (_info.points.length < index) {
                                index = 0;
                        }
                        $('.inner_scroll').animate({
                                scrollLeft: (index - 1) * 120
                        }, 300);
                        _selectIndex = index;
                        $(_container).find('li span').hide();
                        $(_container).find('li span').slice(index, index + 1).show();
                },

                show: function () {
                        draw();
                },

                hide: function () {
                        //remove();
                        if (_requestId)
                                window.cancelAFrame(_requestId);
                        _requestId = null;
                },

                remove: function () {
                        if (_requestId)
                                window.cancelAFrame(_requestId);
                        _requestId = null;
                        //썸네일 제거
                        while (_aMultiThumbnail.length > 0) {
                                var thumb = _aMultiThumbnail.pop();
                                thumb.remove();
                                thumb = null;
                        }
                },

		/**
		 * 썸네일 그리기
		 * @returns {Boolean}
		 */
                draw: function () {

                        console.log("_videoElem.paused =" + _videoElem.paused + ",_videoElem.ended=" + _videoElem.ended)
                        if (_videoElem.ended)
                                return false;

                        var length = _aMultiThumbnail.length;
                        for (var i = 0; i < length; i++) {
                                var thumb = _aMultiThumbnail[i];
                                thumb.draw();
                        }
                        _requestId = window.requestAFrame(that.draw);
                }
        }
};

module.exports = ThumbnailListFactory;