NoteService.$inject = ["$http", "Util"];

function NoteService($http, Util) {
	this.receviceCount = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/count", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.recevice = function(data) {
		var nTime = 0;
		if(data.search_word != '') {
			nTime = 8000;
		}
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/list", $.param(data || ""), {timeout:nTime})
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.receviceView = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/view", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.receviceDelete = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/delete", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 신고하기
	 * @param {type} data
	 * @returns {noteL#1.noteL#1#L#3.$dao}
	 */
	this.receviceReport = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/report", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 수신거부
	 * @param {type} data
	 * @returns {noteL#1.noteL#1#L#3.$dao}
	 */
	this.receviceReject = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/reject", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.send = function(data) {
		var nTime = 0;
		if(data.search_word != '') {
			nTime = 8000;
		}
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/send/a/list", $.param(data || ""), {timeout : nTime})
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.sendView = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/send/a/view", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.sendDelete = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/send/a/delete", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.write = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/write", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.nickname = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/station/a/nickname", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.setting = function() {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/setting")
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.rejectAdd = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/reject/a/add", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};
	this.rejectDelete = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/reject/a/delete", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};
	this.rejectAble = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/reject/a/able", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};
	this.block = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/block", data)
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 보관하기
	 */
	this.noteStore = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/store", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 수신거부한 아이디가 보낸 쪽지 삭제
	 */
	this.receviceRejectIdNoteDelete = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/rejectIdNoteDelete", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 쪽지 발송 받을 아이디 체크
	 * 쪽지 발송자가 수신거부한 아이디 체크
	 */
	this.receiveIdAndSenderRejectIdCheck = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/receiveIdAndSenderRejectIdCheck", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};


	this.rejectDeleteAble = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/reject/a/deleteAble", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 쪽지보내기시 이미지 파일 업로드
	 */
	this.fileUpload = function (data) {
		return new Promise(function(resolve, reject) {
			var form = new FormData();
			form.append('file', data.file);
			form.append('file_count', data.fileCount);
			$http
				.post(API_M_AFREECATV + "/note/a/upload", form ,{
					headers: {'Content-Type': undefined}
				})
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	/**
	 * 쪽지보내기 취소시, 파일 선택 삭제시  파일 삭제
	 */
	this.fileDelete = function (data) {
		return new Promise(function(resolve, reject){
			$http
				.post(API_M_AFREECATV + "/note/a/deleteFile", $.param(data || ""))
				.then(function(response) {
					if(response.data.result == 1) {
						resolve(response.data);
					} else {
						reject (response.data);
					}
				}).catch(function (error) {
					reject(error);
				});
		});
	}

	this.searchUser = function(data)	{
		var data = {
			m: 'bjList',
			v: '2.0',
			w: 'webm',
			szKeyword: data.keyword,
			nPageNo: data.page_no,
			nListCnt: data.limit,
			isMobile: 1,
		};

		if (afreeca.link.isWebview()) {
			switch ( Util.getOS() ) {
				case 'ios':
					data.w = 'ios1';
					break;
				case 'android':
					data.w = 'adr1';
					break;
				default:
					data.w = 'etc';
			}
		}

		return new Promise(function(resolve, reject)
		{
			$http.post(SCH_AFREECATV + '/api.php', $.param(data || ''))
				.then(function(response)	{
					response.data.result === 1 ? resolve(response.data) : reject(response.data);
				}).catch(function(error)	{
                    var nStatus = error.status < 0 ? 0 : error.status,
                        nMainCode = parseInt(nStatus / 100);

                    var szMsg = '';
                    switch (nMainCode)  {
                        case 4:
                        case 0:
                            szMsg = "잘못된 요청입니다.\n잠시 후 다시 시도해주세요.";
                            break;
                        case 5:
                        default:
                            szMsg = "오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.";
                            break;
                    }
                    var szStatus = nStatus == 0 ? 100: ($.inArray(nMainCode, [4, 5]) < 0 ? nStatus : ('000' + (nStatus - nMainCode * 100)).substr(-3));
                    alert("[ERROR-" + szStatus + "]" + "\n" + szMsg);
					reject(error);
				});
		});
	}

	this.noteCancel = function (data) {
		return new Promise(function(resolve, reject){
			$http
				.post(API_M_AFREECATV + "/note/a/cancel", $.param(data || ""))
				.then(function(response) {
					if(response.data.result == 1) {
						resolve(response.data);
					} else {
						
						reject (response.data);
					}
				}).catch(function (error) {
					reject(error);
				});
		});
	}

	this.spamRaise = function (data) {
		return new Promise(function(resolve, reject){
			$http
				.post(API_M_AFREECATV + "/note/a/raise", $.param(data || ""))
				.then(function(response) {
					if(response.data.result == 1) {
						resolve(response.data);
					} else {
						
						reject (response.data);
					}
				}).catch(function (error) {
					reject(error);
				});
		});
	}

	this.newIcon = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/a/newIcon", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	};

	this.getFastReplyContent = function(data) {
		return new Promise(function(resolve, reject) {
			$http
				.post(API_M_AFREECATV + "/note/receive/a/getFastReply", $.param(data || ""))
				.then(function(response) {
					if (response.data.result == 1) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(function(error) {
					reject(error);
				});
		});
	}

}

module.exports = NoteService;
