var md5 = require("md5");
var Swiper = require("swiper/dist/js/swiper.min.js");

/**
 * [스토리지 저장 데이터]
 * oVodSubmenu			-	선택된 서브메뉴
 * oVodTab				-	선택된 탭
 * oVodCategory			-	선택된 카테고리
 * szVodFavoriteUserId 	-	로그인 후 즐겨찾기 등록할 스트리머의 ID.
 * oVodLaterView		-	로그인 후 나중에보기에 등록할 video 객체.
 *
 * [연관 디렉티브]
 * icon.normal.directive.js		-	카테고리 비활성 이미지로 교체
 * icon.active.directive.js		-	카테고리 활성화 이미지로 교체
 * video.clip.directive.js		-	VOD클립 공통화
 * tag.translate.directive.js	-	번역
 */
VideoNewController.$inject = [
	"$cookies",
	"$scope",
	"$timeout",
	"$rootScope",
	"$state",
	"$stateParams",
	"$sessionStorage",
	"$filter",
	"VideoListService",
	"VideoPlaylistService",
	"FavoriteService",
	"LoginManager",
	"LaterviewFactory",
	"ToastMessage",
	"gtext",
	"Util",
	"CountryService"
];

function VideoNewController(
	$cookies,
	$scope,
	$timeout,
	$rootScope,
	$state,
	$stateParams,
	$sessionStorage,
	$filter,
	VideoListService,
	VideoPlaylistService,
	FavoriteService,
	LoginManager,
	LaterviewFactory,
	ToastMessage,
	gtext,
	Util,
	CountryService
) {

	$("body").removeClass("liveArea fullscreen");

	$rootScope.title = "VOD";
	$scope.location = "KR";

	$rootScope.aSubmenu = [
		{
			id		: "hot",
			name	: "인기",
			class 	: "hot",
			isNewClass : false
		},
		{
			id		: "library",
			name	: "기록",
			class 	: "library",
			isNewClass : false
		},
		{
			id		: "category",
			name	: "카테고리",
			class 	: "category",
			isNewClass : false
		}
	];

	$scope.oSelectedTab = null;
	$scope.aLibraryTab = [
		{
			id: "later",
			index : 0,
			name: "나중에 보기",
		},
		{
			id: "recent",
			index : 1,
			name: "최근 본 VOD",
		},
		{
			id: "up",
			index : 2,
			name: "UP 한 VOD",
		},
		{
			id : "playlist",
			index : 3,
			name : "재생목록"
		}
	];

	$scope.aFavoriteTab = [
		{
			id: "userclip",
			name: "유저클립",
		},
		{
			id: "favoritebj",
			name: "즐찾 스트리머",
		},
		{
			id: "subscribebj",
			name: "구독 스트리머",
		}
	];

	$scope.oSelectedCategory = null;
	$scope.aCategory = [];
	
	$scope.aLaterDeleteAction = [
		{
			id		: "view",
			name	: "재생한 VOD 삭제",
			visible	: true
		},
		{
			id		: "all",
			name	: "전체 삭제",
			visible	: true
		},
	]
	$scope.oSelectedSubscribeUser = null;
	// 페이징 백업 객체
	$scope.oBackupList = {
		oTop 	: null,
		oBottom : null,
	};

	// VOD디폴트 썸네일URL
	$scope.szVodDefaultImageURL = RES_AFREECATV + "/images/mobile/common/img_default2.png";
	// 스트리머 디폴트 썸네일URL
	$scope.szBjDefaultImageURL = RES_AFREECATV + "/images/afmain/img_thumb_profile.gif";

	// 유저클립 자세히보기 URL
	$scope.szClipDetailPostURL = BJ_AFREECATV + "/afvodtv/post/49392702";

	// 로그인뷰 노출 여부
	$scope.bShowLoginView = false;
	// 로그인 후 이동할 카테고리 객체
	$scope.oNextCategory = null;

	// 스페셜 카테고리 번호
	const CATEGORY_NO_SPECIAL = "00000001";
	// 핫이슈 카테고리 번호
	const CATEGORY_NO_HOTISSUE = "00000004";
	// 연령제한 카테고리 번호
	const CATEGORY_NO_ADULT = "00030000";

	// 클립 액션 리스트 조회.
	var getClipAction = function(viewId,fileType = ""){
		var actionList = [
			{
				id		: "ADD_LATER",
				name	: "나중에 보기",
				visible	: !['later', 'playlist'].includes(viewId) && fileType !== "CATCH"
			},
			{
				id		: "ADD_PLAYLIST",
				name	: "재생목록에 담기",
				visible	: (!['playlist'].includes(viewId) && LoginManager.isLogin()) && fileType !== "CATCH"
			},
			{
				id		: "DEL_LATER",
				name	: "나중에 보기 삭제",
				visible	: (viewId === "later")
			},
			{
				id		: "DEL_RECENT",
				name	: "최근 본 VOD 삭제",
				visible	: (viewId === "recent")
			},
			{
				id		: "DEL_RECOMMEND",
				name	: "추천받지 않기",
				visible	: (viewId === "home" && LoginManager.isLogin() == true)
			},
			{
				id		: "DEL_UP",
				name	: "UP 취소",
				visible	: (viewId === "up")
			},
			{
				id		: "SHARE",
				name	: "공유하기",
				visible	: true
			}
		]
		var resultList = [];
		for(var i=0, action; action=actionList[i]; i++){
			if(action.visible == true){
				resultList.push(action);
			}
		}

		return resultList;
	}


	// 정렬 필터
	var getFilterAction = function(viewId){
		if( ["home", "userclip", "favoritebj"].includes(viewId)){
			return null;
		}

		var filterList = [
			{
				id		: "vod_update",
				name    : "VOD 추가순",
				visible : ["playlist"].includes(viewId)
			},
			{
				id 		: "old_date",
				name	: "오래된순",
				visible : ["playlist"].includes(viewId)
			},
			{
				id		: (viewId == "category")? "reg_datetime" : (viewId == "playlist") ? "recent_date" : "reg_date",
				name	: "최신순",
				visible	: (viewId != "popular")
			},
			{
				id		: "view_cnt",
				name	: "조회순",
				visible	: (viewId != "popular" && viewId != "playlist")
			},
			{
				id		: "recomm_cnt",
				name	: "UP순",
				visible	: ["up", "category"].includes(viewId)
			},
			{
				id		: "memo_cnt",
				name	: "댓글순",
				visible	: ["up", "category"].includes(viewId)
			},
			{
				id		: "1day",
				name	: "1일",
				visible	: ["popular"].includes(viewId)
			},
			{
				id		: "1week",
				name	: "1주",
				visible	: ["popular"].includes(viewId)
			},
			{
				id		: "1month",
				name	: "1개월",
				visible	: ["popular"].includes(viewId)
			}
		]
		

		var resultList = [];
		for(var i=0, filter; filter=filterList[i]; i++){
			if(filter.visible == true){
				resultList.push(filter);
			}
		}
		return resultList;
	}

	// 기록 > 재생목록 > 분류 필터
	var getPlaylistFilterAction = function(viewId){
		var filterList = [
			{
				id		: "all",
				name	: "전체",
			},
			{
				id		: "origin",
				name	: "생성한 재생목록",
			},
			{
				id		: "share",
				name	: "재생목록 보관함",
			}
		];

		return filterList;
	}

	// 기간 필터
	var getRangeFilter = function(viewId) {
		if( ["home", "favorite", "playlist"].includes(viewId)){
			return null;
		}
		var filterList = [
			{
				id		: "all",
				name	: "전체",
				visible	: ["popular", "category"].includes(viewId)
			},
			{
				id		: "1day",
				name	: "1일",
				visible	: ["popular", "category"].includes(viewId)
			},
			{
				id		: "1week",
				name	: "1주",
				visible	: ["popular", "category"].includes(viewId)
			},
			{
				id		: "1month",
				name	: "1개월",
				visible	: ["popular", "category"].includes(viewId)
			},
			{
				id		: "1year",
				name	: "1년",
				visible	: ["popular", "category"].includes(viewId)
			},
		]
		var resultList = [];
		for(var i=0, filter; filter=filterList[i]; i++){
			if(filter.visible == true){
				resultList.push(filter);
			}
		}
		return resultList;
	}

	//인기VOD 파일타입 필터
	var getfileTypeFilter = function(viewId) {
		if( viewId != 'popular' ){ return null; }

		var filterList = [
			{
				id		: "ALL",
				name	: "전체"
			},
			{
				id		: "REVIEW",
				name	: "다시보기"
			},
			{
				id		: "NORMAL",
				name	: "업로드VOD"
			},
			{
				id		: "CLIP",
				name	: "유저클립"
			}
		];

		return filterList;
	}

	//인기VOD 정렬타입 필터
	var getOrderTypeFilter = function(viewId) {
		if( !(viewId == 'popular' || viewId == 'userclip') ){ return null; }

		var filterList = [
			{
				id		: "popular",
				name	: "인기순"
			},
			{
				id		: "reg_date",
				name	: "최신순"
			},
			{
				id		: "view_cnt",
				name	: "조회순"
			},
			{
				id		: "recomm_cnt",
				name	: "UP순"
			},
			{
				id		: "memo_cnt",
				name	: "댓글순"
			}
		];
	
		return filterList;
	}

	// 기본 리스트 객체.
	var oDefaultListData =  {
		aList			  : [],
		nPage		  	  : 1,
		nLimit 		  	  : 20,
		szTotalCnt	  	  : "0",
		bCompleted	  	  : true,
		bHasMore	  	  : false,
		bShowEmptyBox 	  : false,
		bHasAdvertisement : false,
	}

	// 기본 뷰 객체.
	var getDefaultPageCtrl = function(viewId){
		var pageCtrl = {
			szViewId	 		: viewId,						  // 페이지Id
			oTop				: angular.copy(oDefaultListData), // 상단 리스트 데이터
			oBottom				: angular.copy(oDefaultListData), // 하단 리스트 데이터
			aFilter  			: getFilterAction(viewId),  	  // order 필터
			rangeFilter 		: getRangeFilter(viewId),		  // range 필터
			fileTypeFilter 		: getfileTypeFilter(viewId),	  // VOD 파일타입 필터
			orderTypeFilter		: getOrderTypeFilter(viewId),	  // 인기 VOD 정렬 필터
			oSelectedFilter 	: null,							  // 선택된 필터
			oRangeFilter 		: null, 						  // 선택된 range 필터
			oFileTypeFilter 	: null, 						  // 선택된 VOD 파일타입 필터
			oOrderTypeFilter	: null,							  // 선택된 인기 VOD 정렬타입 필터
			bShowDetail			: false,						  // 상세페이지 여부
			bShowBrand			: false,						  // 방송사 브랜드관 페이지 여부
			bShowBrandDetail	: false,						  // 방송사 브랜드관 > 상세 페이지 여부
			nActiveTab			: 0,							  // 방송사 브랜드관 활성화 탭
			bNeedViewInit		: true,							  // 뷰갱신필요여부.
			nScrollTop			: 0								  // 스크롤위치.
		}

		if(pageCtrl.aFilter && pageCtrl.aFilter.length > 0){
			pageCtrl.oSelectedFilter = pageCtrl.aFilter[1];
		}

		if(pageCtrl.rangeFilter && pageCtrl.rangeFilter.length > 0){
			pageCtrl.oRangeFilter = pageCtrl.rangeFilter[2];
		}

		if(pageCtrl.fileTypeFilter && pageCtrl.fileTypeFilter.length > 0){
			pageCtrl.oFileTypeFilter = pageCtrl.fileTypeFilter[0];
		}

		if(pageCtrl.orderTypeFilter && pageCtrl.orderTypeFilter.length > 0){
			pageCtrl.oOrderTypeFilter = pageCtrl.orderTypeFilter[0];
		}
		
		if(viewId == "playlist"){
			pageCtrl.playListTypeFilter = getPlaylistFilterAction(viewId);
			pageCtrl.oSelectedPlayListType = pageCtrl.playListTypeFilter[0];
			$sessionStorage.oPlaylistFilter = pageCtrl.playListTypeFilter[0];
		}

		return angular.copy(pageCtrl);
	}

	$scope.init = function(){
		// 화면 제어 객체
		$scope.oViewCtrl = {
			hot			: getDefaultPageCtrl("hot"),
			later		: getDefaultPageCtrl("later"),
			recent		: getDefaultPageCtrl("recent"),
			up			: getDefaultPageCtrl("up"),
			category	: getDefaultPageCtrl("category"),
			playlist    : getDefaultPageCtrl("playlist")
		}

		let oSelectedSubmenu = $scope.aSubmenu[0];

		// 서브메뉴로 이동.
		if($stateParams.oParams == null || $.isEmptyObject($stateParams.oParams)) {
			// 세션 스토리지에 저장된 메뉴 보여줌
			const oVodSubmenu = $sessionStorage.oVodSubmenu;

			if(oVodSubmenu){
				// 메뉴 목록에 존재하는지 찾기
				let oSelected = $scope.aSubmenu.find(oFilter => oFilter.id === oVodSubmenu.id);

				if(oSelected) {
					oSelectedSubmenu = $sessionStorage.oVodSubmenu;
				}
			}
		}

		$scope.goSubmenu(oSelectedSubmenu);

		$rootScope.bVodClass = true; //content_wrap>vod 클래스
	}

	$scope.setView = function(){
		// 스크롤 Top저장
		if($scope.oCurrent){
			$scope.oCurrent.nScrollTop = $(window).scrollTop();
		}
		var viewId = $rootScope.oSelectedSubmenu.id;
		$rootScope.bRegisteClass = false; //기록 페이지 registe 클래스

		if(viewId == "library"){
			// 탭 메뉴 존재 시
			$scope.oSelectedTab = ($sessionStorage.oVodTab)? $sessionStorage.oVodTab : $scope.aLibraryTab[0];
			$sessionStorage.oVodTab = $scope.oSelectedTab;
			if($sessionStorage.oVodTab && vodSwiper.activeIndex != $sessionStorage.oVodTab.index){
				vodSwiper.slideTo($scope.oSelectedTab.index, 0, false);
				return;
			}
			viewId = $scope.oSelectedTab.id;
			$rootScope.bRegisteClass = true; //기록 페이지 registe 클래스
		}
		else if(viewId == "favorite") {
			$scope.oSelectedTab = ($sessionStorage.oFavoriteTab)? $sessionStorage.oFavoriteTab : $scope.aFavoriteTab[0];
			$sessionStorage.oFavoriteTab = $scope.oSelectedTab;
			viewId = $scope.oSelectedTab.id;
		}
		else if(viewId == "hot") {
			$scope.goHotFilter("ALL", false);
		}

		if($scope.oViewCtrl[viewId].bNeedViewInit){
			$scope.bShowLoading = true; //로딩 UI
			$scope.oCurrent = $scope.oViewCtrl[viewId] = getDefaultPageCtrl(viewId);

			if(viewId == "category"){
				// 카테고리 조회.
				VideoListService.getCategory().then(function(oRes) {
					$scope.aCategory = []

					for(var i=0; i<oRes.data.length; i++){
						$scope.aCategory.push(oRes.data[i]);
					}
					
					var oSelectedCategory = ($sessionStorage.oVodCategory)? $sessionStorage.oVodCategory : $scope.aCategory[0];
					$scope.selectCategory(oSelectedCategory);
				});	
				return;
			}

			if(viewId == "subscribebj"){
				listInit("top", false);
			} else if(viewId == "favoritebj" || viewId == "recent"){
				listInit("all", false);
			} else {
				listInit("bottom", false);
			}

		} else {
			$scope.oCurrent = $scope.oViewCtrl[viewId];
			$scope.oCurrent.bShowDetail = false;
		}

                $rootScope.bVidoWbg = false;
                $rootScope.bChannelClass = false; //cnannel-visual channel 클래스

		// 스크롤 위치 이동
		$('body, html').scrollTop($scope.oCurrent.nScrollTop);

		// 핫이슈, 카테고리 > select box 닫기
		$scope.bShowCategory = false; 

		$timeout(function(){
			window.dispatchEvent(new Event("resize"));
		},300)
	}


	var listInit = function(szLocation, bRetainPage){  // szLocation : all / top / bottom
		var aLocation = [];
		if(szLocation == "all"){
			aLocation = ["top", "bottom"];
		} else {
			aLocation.push(szLocation);
		}

		for(var i=0; i<aLocation.length; i++){
			var key = (aLocation[i] == "top")? "oTop" : "oBottom";
			if(bRetainPage == true){
				$scope.oBackupList[key] = angular.copy($scope.oCurrent[key]);
				$scope.oCurrent[key].nLimit = ($scope.oCurrent[key].nPage * $scope.oCurrent[key].nLimit);
				$scope.oCurrent[key].nPage = 1;
				$scope.oCurrent[key].bHasAdvertisement = false;
			} else {
				$scope.oCurrent[key] = angular.copy(oDefaultListData);
				$scope.oCurrent[key].bHasAdvertisement = false;
				$("#" + $scope.oCurrent.szViewId).scrollTop(0);

			}

			if($scope.oCurrent.szViewId == "playlist"){
				$scope.aPlaylists.aPlaylist=[];
				getList(aLocation[i]);
			}
			else{
				getList(aLocation[i]);
			}
		}
	}

	// 로그인 상태 변경시
	$scope.$on("$loginChange", function(event, value){
		if ($rootScope.member && $sessionStorage.szVodFavoriteUserId) {
			$scope.addFavoriteVodHandler($sessionStorage.szVodFavoriteUserId, function(){
				if($scope.oCurrent.szViewId !== "favorite" && $scope.oViewCtrl.favorite !== null){
					$scope.oViewCtrl.favorite.bNeedViewInit = true;
				}
			});
			return;
		}

		if ($rootScope.member && $sessionStorage.oVodLaterView) {
			$scope.addLaterViewVodHandler($sessionStorage.oVodLaterView);
			return;
		}

		if($scope.oNextCategory){
			$scope.selectDepthCategory($scope.oNextCategory);
			$scope.oNextCategory = null;
			return;
		}
		$scope.bShowLoginView = false;
		$scope.init();
	});

	// 서브메뉴 클릭 이벤트
	$scope.$on("clickSubmenuEvent", function(event, oSubmenu){
		$scope.goSubmenu(oSubmenu);
	});

	// 서브메뉴 이동
	$scope.goSubmenu = function(oSubmenu){
		//모바일앱과 로그명 통일시키기 위해...
		var theme_id = '';
		switch (oSubmenu.id){
			case 'category':
				theme_id = 'cate';
				break;
			case 'library':
				theme_id = 'history';
				break;
			default:
				theme_id = oSubmenu.id;
				break;
		}

		window.collector.mtheme({theme_id: 'vod_'+theme_id}); //mtheme 로그

		// 즐겨찾기, 라이브러리 진입시 로그인 체크
		$scope.bShowLoginView = false;
		if (LoginManager.isLogin() === false && $.inArray(oSubmenu.id, ["favorite", "library"]) > -1) {
			$rootScope.oSelectedSubmenu = oSubmenu;
			$sessionStorage.oVodSubmenu = oSubmenu;
			$scope.bShowLoginView = true;
		}

		$rootScope.oSelectedSubmenu = oSubmenu;
		$sessionStorage.oVodSubmenu = oSubmenu;
		$scope.setView();
	}

	// 탭메뉴 클릭 이벤트
	$scope.clickTab = function(tab){
		vodSwiper.slideTo(tab.index);
	}

	// 즐겨찾기 > 탭
	$scope.clickFavTab = function(idx){
		$scope.oSelectedTab = $scope.aFavoriteTab[idx];
		$sessionStorage.oFavoriteTab = $scope.oSelectedTab;
		$scope.setView();
	}

	// 즐겨찾기 > 더보기 클릭
	$scope.goVodListByBJ = function(){
		$scope.oViewCtrl.favoritebj.bShowDetail = true;
		$rootScope.bShowSubmenu = false; //VOD 메뉴 리스트
		$rootScope.bVodClass = false; //content_wrap>vod 클래스
		listInit("bottom", false);
	}

	// 카테고리 > 카테고리 콤보박스 클릭
	// $scope.showCategory = function(){
	// 	if(Util.getOS() === "ios"){
	// 		if($(window).height() > window.innerHeight){
	// 			var nMargin  = $(window).height() -  window.innerHeight;
	// 			$scope.oCategoryMarginBottom = { 'margin-bottom' : nMargin + 'px'};
	// 		} else {
	// 			$scope.oCategoryMarginBottom = { 'margin-bottom' : '0px'};
	// 		}
	// 	}

	// 	$scope.bShowCategory = !$scope.bShowCategory;
	// }
	$scope.showCategory = function(){
		//앵귤러 애니메이션 추가가 힘들어서 jqeury로 부득이하게.. 씀..
		$(".category_wrap").find(".depth_list").slideToggle("slow");
		
		$scope.bShowCategory = !$scope.bShowCategory;
		if($scope.bShowCategory)
			$("body").addClass("action_sheet");
		else
			$("body").removeClass("action_sheet");
	}

	$scope.outCategoryDepth = function(){
		$scope.oViewCtrl.category.bCategoryDepth = false;
	}
	$scope.goDetailCategory = function(category){
		$sessionStorage.szInflowPath1 = category.inflow_code;
		
		if(category.is_real ==0){//전체 케이스일 경우
			$scope.selectCategory(category);
			return;
		}
		

		let aTempCategory = [];
		for(var i=0; i<category.category.length; i++){
			aTempCategory.push(category.category[i]);
		}

		
	//	$scope.oSelectedCategory.category = aTempCategory; 
		$scope.oSelectedParentCategory = category;
		$scope.oViewCtrl.category.bCategoryDepth = true;
	}

	// 카테고리 > 1개 선택
	$scope.selectCategory = function(oCategory){
		if(oCategory.is_adult == 1){
			if(LoginManager.isLogin() == false){
				$scope.oNextCategory = oCategory;
				$rootScope.login();
				return;
			}
			
			if($rootScope.szNation == 'korean' && LoginManager.getAge() < 19){
				alert("이 정보 내용은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의하여 만 19세 미만의 청소년이 이용할 수 없습니다.");
				return;
			}

			if($rootScope.szNation == 'korean' && LoginManager.isAdultCheck() == null){
				window.open(AUTH_M_AFREECATV + '/realname');
				return;
			}
		}

		$scope.oSelectedCategory = oCategory;
		$sessionStorage.oVodCategory = oCategory;

		let findCategoryData = {"find":false,'cate_stack':[]}
		findCategoryData = $scope.searchCategoryDepth($scope.aCategory , oCategory, findCategoryData);
		
		$sessionStorage.category_inflowpath = findCategoryData['cate_stack'];

		$scope.bShowCategory = false;
		$('body').removeClass('action_sheet'); 
		listInit("bottom", false);
	}

	$scope.selectDepthCategory = function(oParentCate,oCategory){
		if(oCategory.is_adult == 1){
			
			if(LoginManager.isLogin() == false){
				$scope.oNextCategory = oCategory;
				$rootScope.login();
				return;
			}
			if($rootScope.szNation == 'korean' && LoginManager.getAge() < 19){
				alert("이 정보 내용은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의하여 만 19세 미만의 청소년이 이용할 수 없습니다.");
				return;
			}

			if( $rootScope.szNation == 'korean' && LoginManager.isAdultCheck() == null){
				window.open(AUTH_M_AFREECATV + '/realname');
				return;
			}
		}

		$scope.oSelectedCategory = oCategory;
		// /$scope.oSelectedCategory.select_parent_category = oParentCate;
		$sessionStorage.oVodCategory = oCategory;

		let findCategoryData = {"find":false,'cate_stack':[]}
		findCategoryData = $scope.searchCategoryDepth($scope.aCategory , oCategory, findCategoryData);
		$sessionStorage.category_inflowpath = findCategoryData['cate_stack'];

		$scope.bShowCategory = false;
		$('body').removeClass('action_sheet'); 
		listInit("bottom", false);
	}

	$scope.closeCategorySheet = function(){
		$scope.bShowCategory = false;

		$('body').removeClass('action_sheet'); //액션시트에서 스크롤할때 body에 있는 스크롤까지 먹히는 것 방지
	}

	// bj_img 추가
	$scope.addBjImage = function(oUser){
		oUser.bj_img = Util.getBjThumbnail(oUser.bj_id);
	}

	// 최근본VOD 즐겨찾기 토글
	$scope.favoriteToggle = function(oData){
		var szBjId = '';
		var szBjNick = '';

		switch($scope.oCurrent.szViewId){
			case "recent":
				szBjId = oData.bj_id;
				szBjNick = oData.bj_nick;

				if(oData.bFavorite == false){
					$scope.addFavoriteVodHandler(szBjId, function(){
						oData.bFavorite = true;
						$scope.$digest();
					});
				} else {
					$scope.delFavoriteVodHandler(szBjId, szBjNick, function(){
						oData.bFavorite = false;
						$scope.$digest();
					});
				}
				break;
		}
	}

	// 새로고침.
	$scope.reloadState = function(){
		delete $sessionStorage.oVodSubmenu;
		delete $sessionStorage.oVodTab;
		delete $sessionStorage.oVodCategory;
		delete $sessionStorage.oFileType;

		$scope.goSubmenu($scope.aSubmenu[0]);
	}

	//======================================
	// ACTION SHEET
	//======================================

	// 액션 발생한 영상 정보
	$scope.oActionVideo = null;
	// 클립 더보기 액션 시트 노출.
	$scope.showActionSheet = function(oVideo){
            
        //file_type에 따라 actionSheet 변경 필요
        $scope.oCurrent.aAction = getClipAction($scope.oCurrent.szViewId, oVideo.file_type);

		$scope.oActionVideo = oVideo;
		$rootScope.showActionSheet($scope.oCurrent.aAction, null, function(oAction){
			switch(oAction.id){
				case "ADD_LATER" :
					$scope.addLaterViewVodHandler($scope.oActionVideo);
					break;
				case "DEL_LATER" :
					if ($scope.oActionVideo.type == 'LIVE') {
						$scope.oActionVideo.scheme = SERVICE_APP_SCHEME + 'player/video?&broad_no='+$scope.oActionVideo.broad_no+'&user_id='+$scope.oActionVideo.user_id+'&type=live'
					}
					$scope.delLaterViewVodHandler($scope.oActionVideo, "one");
					break;
				case "DEL_RECENT" :
					$scope.delRecentVodHandler($scope.oActionVideo, "title");
					break;
				case "DEL_RECOMMEND" :
					$scope.delRecommendVodHandler($scope.oActionVideo, "title");
					break;
				case "DEL_UP" :
					$scope.delUpVodHandler($scope.oActionVideo);
					break;
				case "ADD_PLAYLIST":
					$scope.getMakingPlaylist($scope.oActionVideo.title_no,1,20);//app.controller.js에 있음
					$scope.oViewCtrl.playlist.bNeedViewInit = true;
					break;
				case "SHARE":
					$scope.shareVodHandler($scope.oActionVideo);
					break;
			}
		});
	};
	

	// 정렬 필터시트 노출
	$scope.showFilterSheet = function(){
		$rootScope.showActionSheet($scope.oCurrent.aFilter, $scope.oCurrent.oSelectedFilter, function(oFilter){
			$scope.oCurrent.oSelectedFilter = oFilter;

			listInit("bottom", false);
		});
	}

	// 기록 > 재생목록 > 분류 필터시트 노출
	$scope.showPlaylistFilterSheet = function(){
		$rootScope.showActionSheet($scope.oCurrent.playListTypeFilter, $scope.oCurrent.oSelectedPlayListType, function(oFilter){
			$scope.oCurrent.oSelectedPlayListType = oFilter;
			$sessionStorage.oPlaylistFilter = oFilter;
			listInit("bottom", false);
		});
	}

	// 기간 필터시트 노출
	$scope.showRangeFilterSheet = function() {
		$rootScope.showActionSheet($scope.oCurrent.rangeFilter, $scope.oCurrent.oRangeFilter, function(oFilter){
			$scope.oCurrent.oRangeFilter = oFilter;

			listInit("bottom", false);
		});
	}

	// 인기VOD 전체,다시보기,업로드VOD,유저클립 필터
	$scope.showFileTypeFilterSheet = function() {
		$rootScope.showActionSheet($scope.oCurrent.fileTypeFilter, $scope.oCurrent.oFileTypeFilter, function(oFilter){
			$scope.oCurrent.oFileTypeFilter = oFilter;
			$sessionStorage.oFileType = oFilter;
			listInit("bottom", false);
		});
	}

	// 인기VOD 정렬 필터
	$scope.showOrderTypeFilterSheet = function() {
		$rootScope.showActionSheet($scope.oCurrent.orderTypeFilter, $scope.oCurrent.oOrderTypeFilter, function(oFilter){
			$scope.oCurrent.oOrderTypeFilter = oFilter;

			listInit("bottom", false);
		});
	}

	// 나중에보기 삭제 액션시트 노출.
	$scope.showLaterAction = function(){
		$rootScope.showActionSheet($scope.aLaterDeleteAction, null, function(oAction){
			$scope.delAllLaterViewHandler(oAction.id);
		});
	}

	//======================================
	// API REQUEST
	//======================================

	// 즐겨찾기 추가
	$scope.addFavoriteVodHandler = function(szUserId, successCallback){
		if(!$rootScope.member) {
			if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?"))) {
				// 로그인후에 자동으로 즐겨찾기 추가하기위함.
				$sessionStorage.szVodFavoriteUserId = szUserId;
				$sessionStorage.bBackAfterLogin = true;
				$rootScope.login();
			}
			return;
		}

		delete $sessionStorage.szVodFavoriteUserId;
		FavoriteService.add(szUserId).then(function(data){
			if($.isFunction(successCallback)){
				successCallback();
			}
			ToastMessage(gtext(data.message));
		}).catch(function (response) {
			//이미 즐겨찾기 대상인 경우
			ToastMessage(gtext(response.data.message));
		})
		.finally(function(){
			// 로그인 후 돌아왔을 때 페이지 갱신
			if($sessionStorage.bBackAfterLogin) {
				$scope.oCurrent.bNeedViewInit = true;

				angular.forEach($scope.aSubmenu, function(val, idx){
					if(val.id == $scope.oCurrent.szViewId) {
						$scope.goSubmenu($scope.aSubmenu[idx]);
					}
				})

				delete $sessionStorage.bBackAfterLogin;
			}
		});
	};


	// 즐겨찾기 삭제
	$scope.delFavoriteVodHandler = function(szUserId, szUserNickname, successCallback){
		// MANTIS:0018965
		// if (confirm(gtext('%s님을 즐겨찾기에서 삭제하시겠습니까?', szUserNickname))) {
		// 	FavoriteService.delete(szUserId).then(function(data){
		// 		ToastMessage(gtext(data.message), "blue");
		// 		if($.isFunction(successCallback)){
		// 			successCallback();
		// 		}
		// 	}).catch(function (response) {
		// 		ToastMessage(gtext(response.data.message));
		// 	});
		// }

		FavoriteService.delete(szUserId).then(function(data){
			ToastMessage(gtext(data.message));
			if($.isFunction(successCallback)){
				successCallback();
			}
		}).catch(function (response) {
			ToastMessage(gtext(response.data.message));
		});
	};


	// 나중에보기 추가 Handler
	$scope.addLaterViewVodHandler = function(video) {
		if(!$rootScope.member) {
			if (confirm(gtext('나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?'))) {
				$sessionStorage.oVodLaterView = video;
				$sessionStorage.bBackAfterLogin = true;
				$rootScope.login();
			}
			return;
		}

		delete $sessionStorage.oVodLaterView;

		LaterviewFactory
		.sheme(video.scheme)
		.setShowMessage(false)
		.setShowGuide(false)
		.add()
		.then(function(data) {
			ToastMessage(gtext(data.message));
			if($scope.oCurrent.szViewId !== "later" && $scope.oViewCtrl.later !== null){
				$scope.oViewCtrl.later.bNeedViewInit = true;
			}
		})
		.catch(function(error){
			//이미 나중에보기에 등록된 경우
			ToastMessage(gtext(error.data.message));
		})
		.finally(function(){
			// 로그인 후 돌아왔을 때 페이지 갱신
			if($sessionStorage.bBackAfterLogin) {
				$scope.oCurrent.bNeedViewInit = true;

				angular.forEach($scope.aSubmenu, function(val, idx){
					if(val.id == $scope.oCurrent.szViewId) {
						$scope.goSubmenu($scope.aSubmenu[idx]);
					}
				})

				delete $sessionStorage.bBackAfterLogin;
			}
		});
	};


	// 나중에보기 삭제 Handler
	$scope.delLaterViewVodHandler = function(video){
		LaterviewFactory
		.sheme(video.scheme)
		.setShowMessage(false)
		.delete()
		.then(function(data){
			// MANTIS:0018965
			// ToastMessage(gtext(data.message), "blue");
			ToastMessage(gtext(data.message));

			listInit("bottom", true);
		})
		.catch(function(data){
			ToastMessage(gtext(data.message));
		});
	};


	// 나중에보기 여러개 삭제
	$scope.delAllLaterViewHandler = function(szActionId){
		var oMsg = {
			"view" : gtext("재생한 VOD를 모두 삭제하시겠습니까?"),
			"all"  : gtext("나중에 보기 목록을 전체 삭제하시겠습니까?"),
		}

		if(confirm(gtext(oMsg[szActionId]))){
			LaterviewFactory
			.setShowMessage(false)
			.deleteAll(szActionId)
			.then(function(data){
				ToastMessage(gtext(data.message));

				listInit("bottom", false);
			}).catch(function(error){
				ToastMessage(gtext(error.data.message));
			});
		}
	}


	// 최근본VOD삭제
	$scope.delRecentVodHandler = function(video, szWork) {
		var params = {
			szDataType : "REMOVERECENT",
			szWork	   : szWork,
			nTitleNo   : video.title_no,
		}
		VideoListService.setVodList(params).then(function(data){
			ToastMessage(gtext(data.message));

			listInit("bottom", true);
		}).catch(function(data) {
			ToastMessage(gtext(data.message));
		});
	};

	// 최근본VOD > 전체삭제 버튼 클릭.
	$scope.delAllRecentVodHandler = function(){
		if(confirm(gtext("최근 본 VOD 목록을 모두 삭제하시겠습니까?"))){
			var params = {
				szDataType : "REMOVERECENT",
				szWork	   : "all",
			}

			VideoListService.setVodList(params).then(function(data){
				ToastMessage(gtext(data.message));

				listInit("bottom", false);
			}).catch(function(data) {
				ToastMessage(gtext(data.message));
			});
		}
	}

	// 추천받지 않기
	$scope.delRecommendVodHandler = function(video, szWork) {
		var params = {
			szDataType : "RECOMMENDREMOVE",
			szWork	   : szWork,
			nTitleNo   : video.title_no,
		}
		VideoListService.setVodList(params).then(function(data){
			ToastMessage(gtext(data.message));

			listInit("bottom", true);
		}).catch(function(data) {
			ToastMessage(gtext(data.message));
		});
	};


	// UP취소
	$scope.delUpVodHandler = function(video){
		var params = {
			nTitleNo	: video.title_no,
		}
		VideoListService.deleteUp(params).then(function(data){
			ToastMessage(gtext(data.message));

			listInit("bottom", true);
		}).catch(function(data) {
			ToastMessage(gtext(data.message));
		});
	}

	// 공유하기 레이어
	$scope.shareVodHandler = function(video) {
		$scope.bShowShare = true;

		const domain = VOD_AFREECATV || $rootScope.$const.VOD_AFREECATV;
		
		let szShareUrl = domain + '/player/';
		szShareUrl += (video.share_type == 'playlsit') ? video.rep_title_no + '/playlist/' + video.idx : 
						(video.file_type && video.file_type.toLowerCase() == 'catch') ? video.title_no + '/catch' : video.title_no;

		// 트위터
		$('.tw').off('click').on('click', function() {
			window.collector.sendVodUaLogBtnType('twitter_btn');
			$scope.closeShare();
			const szShareTitle = encodeURIComponent(video.title_name);
			window.open('//twitter.com/intent/tweet?text='+szShareTitle+'&url='+encodeURIComponent(szShareUrl), '_blank');
		})

		// 페이스북
		$('.fb').off('click').on('click', function() {
			window.collector.sendVodUaLogBtnType('facebook_btn');
			$scope.closeShare();
			const szShareTitle = encodeURIComponent(video.title_name);
			window.open('//www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(szShareUrl)+'&t='+szShareTitle, '_blank');
		})

		// url
		$('.url').off('click').on('click', function() {
			window.collector.sendVodUaLogBtnType('copy_btn');
			$scope.closeShare();
			let tempElem = document.createElement('textarea');
			tempElem.value = szShareUrl;
			document.body.appendChild(tempElem);
			tempElem.select();
			document.execCommand("copy");
			document.body.removeChild(tempElem);

			ToastMessage(gtext("주소가 복사 되었습니다"));
		})
	}

	// 공유하기 레이어 닫기
	$scope.closeShare = function() {
		$scope.bShowShare = false;
		$scope.$digest();
	}

	// TOP리스트 조회.
	$scope.getTopList = function(params){
		$scope.oCurrent.oTop.bCompleted = false;

		VideoListService.getVodList(params).then(function(oRes){
			makeList(oRes.data, "top");
		});
	}

	// Bottom리스트 조회.
	$scope.getBottomList = function(params){
		$scope.oCurrent.oBottom.bCompleted = false;
		// 카테고리
		if($scope.oCurrent.szViewId == "category"){
			switch($scope.oSelectedCategory.cate_no){
				case CATEGORY_NO_SPECIAL: // 스페셜
					VideoListService.getVideoSpecial(params).then(function(oRes) {
						oRes.data.list = (oRes.data.groups.length > 0)? oRes.data.groups[0].contents : [];
						makeList(oRes.data, "bottom");
					});
					break;
				case CATEGORY_NO_HOTISSUE: // 핫이슈
					VideoListService.getVideoSection(params).then(function(oRes) {
						oRes.data.list = (oRes.data.groups.length > 0)? oRes.data.groups[0].contents : [];
						makeList(oRes.data, "bottom");
					});
					break;
				default : // 그외 카테고리
					VideoListService.getCategoryList(params).then(function(oRes) {
						oRes.data.list = (oRes.data.groups.length > 0)? oRes.data.groups[0].contents : [];
						makeList(oRes.data, "bottom");
					});
			}
		} else if($scope.oCurrent.szViewId == "popular"){
			VideoListService.getPopularList(params).then(function(oRes) {
				makeList(oRes.data, "bottom");
			});
		} 
		else {

			VideoListService.getVodList(params).then(function(oRes) {
				makeList(oRes.data, "bottom");
			});
		}
	}
	// 인기VOD리스트
	$scope.getPopularList = function(){
		// 유저클립 필터 & 한국어일 때 유저클립이란? 노출
		$scope.isShowClipInfo = ($scope.oCurrent.oFileTypeFilter.id == 'CLIP' && Util.language() == 'ko') ? true : false;

		$scope.getBottomList({
			nCurrentPage	: $scope.oCurrent.oBottom.nPage,
			nRowPerPageNo	: $scope.oCurrent.oBottom.nLimit,
			szLately		: $scope.oCurrent.oSelectedFilter.id,
			szFileType		: $scope.oCurrent.oFileTypeFilter.id,
			szOrderByColumn	: $scope.oCurrent.oOrderTypeFilter.id,
		});

		$sessionStorage.oFileType = $scope.oCurrent.oFileTypeFilter;
	}

	// 기록 > 재생목록 리스트
	$scope.getPlaylists = function(){
		var requestData = {
			szWork : "get_playlist_list",
			type 		: $scope.oCurrent.oSelectedPlayListType.id, 	
			sorttype 	: $scope.oCurrent.oSelectedFilter.id,
			nPage 		: $scope.oCurrent.oBottom.nPage,
			nLimit      : $scope.oCurrent.oBottom.nLimit
		};

		VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
			makePlaylistList(oRes);
			makeList(oRes,"bottom");
		});
	}

	// 즐겨찾기 > 유저클립 리스트 조회
	$scope.getUserclipList = function(){
		$scope.getBottomList({
			szDataType		 : "FAVORITE",
			szDataDetailType : "fav_clip",
			nPage 			 : $scope.oCurrent.oBottom.nPage,
			nLimit      	 : $scope.oCurrent.oBottom.nLimit,
			szOrder			 : $scope.oCurrent.oOrderTypeFilter.id
		});
	}

	// 즐겨찾기 상단 리스트 조회
	$scope.getFavoriteTopList = function(){
		$scope.getTopList({
			szDataType		 : "FAVORITE",
			szDataDetailType : "fav_vod",
			nPage			 : 1,
			nLimit			 : 8,
		});
	}


	// 즐겨찾기 하단 리스트 조회
	$scope.getFavoriteBottomList = function(){
		var params = {
			nPage 		: $scope.oCurrent.oBottom.nPage,
			nLimit      : $scope.oCurrent.oBottom.nLimit,
		};

		if($scope.oCurrent.bShowDetail == false){
			params.szDataType = "FAVORITE";
			params.szTheme = $scope.oCurrent.szViewId;
		} else {
			params.szDataType = "USER";
			params.szBjId = $scope.oCurrent.oTop.user_id;
		}

		$scope.getBottomList(params);
	}


	// 최근본VOD리스트 조회.
	$scope.getRecentTopList = function(){
		$scope.getTopList({
			szDataType		 : "RECENTVIEW",
			szDataDetailType : "fav_user",
			szOrder			 : "reg_date",
			nPage			 : 1,
			nLimit			 : 6,
		});
	}

	// 최근본VOD리스트 조회.
	$scope.getRecentBottomList = function(){
		$scope.getBottomList({
			szDataType		 : "RECENTVIEW",
			szDataDetailType : "vod",
			szOrder			 : $scope.oCurrent.oSelectedFilter.id,
			nPage 			 : $scope.oCurrent.oBottom.nPage,
			nLimit           : $scope.oCurrent.oBottom.nLimit,
		});
	}


	// 카테고리 리스트 조회
	$scope.getCategoryList = function(){
		var params = {}
		switch($scope.oSelectedCategory.cate_no){
			case CATEGORY_NO_SPECIAL:
				params = {
					nCurrentPage	: $scope.oCurrent.oBottom.nPage,
					nRowPerPageNo	: $scope.oCurrent.oBottom.nLimit,
					szOrderByColumn	: $scope.oCurrent.oSelectedFilter.id,
					szLately		: $scope.oCurrent.oRangeFilter.id,
				}
				break;
			case CATEGORY_NO_HOTISSUE:
				params = {
					current_page	: $scope.oCurrent.oBottom.nPage,
					rows_per_page	: $scope.oCurrent.oBottom.nLimit,
					theme			: "hotissue",
					order			: $scope.oCurrent.oSelectedFilter.id,
					period			: $scope.oCurrent.oRangeFilter.id,
				};
				break;
			default :
				params = {
					m			: "vodList",
					nPageNo		: $scope.oCurrent.oBottom.nPage,
					nListCnt	: $scope.oCurrent.oBottom.nLimit,
					szOrder		: $scope.oCurrent.oSelectedFilter.id,
					szTerm		: $scope.oCurrent.oRangeFilter.id,
					szVideoType	: "",
					szPlatform	: "mobile",
					szFileType	: "",
                    v               : "3.0"
				}
				if($scope.oSelectedCategory.is_real == 1){
					if($scope.oSelectedCategory.is_adult == 1){
						params['szCateNo'] = CATEGORY_NO_ADULT;
					}
					params['szVodCateNo'] = $scope.oSelectedCategory.vod_category.join(",");	
				}
				else{
					params['szCateNo'] =$scope.oSelectedCategory.cate_no
				}
				
		}
		$scope.getBottomList(params);
	}

	// 구독 스트리머 리스트
	$scope.getSubscribeTopList = function(){
		$scope.getTopList({
			szDataType 		 : "SUBSCRIBE",
			szDataDetailType : "user",
			nPage			 : 1,
			nLimit			 : 999,
		});
	}

	// 구독 VOD리스트
	$scope.getSubscribeBottomList = function(){
		$scope.getBottomList({
			szDataType : "USER",
			szBjId	   : $scope.oSelectedSubscribeUser.bj_id,
			nPage	   : $scope.oCurrent.oBottom.nPage,
			nLimit	   : $scope.oCurrent.oBottom.nLimit,
			szOrder	   : $scope.oCurrent.oSelectedFilter.id,
		});
	}

	// 구독 스트리머 선택
	$scope.clickUser = function(oUser){
		if(oUser == $scope.oSelectedSubscribeUser){
			return;
		}
		$scope.oSelectedSubscribeUser = oUser;
		listInit("bottom", false);
	}

	// 리스트 다음페이지 조회.
	$scope.getNextList = function(szLocation){
		var szKey = (szLocation == "top")? "oTop" : "oBottom";
		$scope.bShowLoading = true;

		if($scope.oCurrent.szViewId == "popular" && $scope.oViewCtrl.popular.oBottom.aList.length >= 100) {
			$scope.oViewCtrl.popular.oBottom.bHasMore = false;
			$scope.$digest();
		}

		if ($scope.oCurrent[szKey].bCompleted && $scope.oCurrent[szKey].bHasMore){
			$scope.oCurrent[szKey].nPage++;
                            getList(szLocation);
		}
	}

	var getCommonParams = function(){
		var pageDataType = {
			"home" 		: "MOBILE_MAIN",
			"later"     : "LATERVIEW",
			"recent"    : "RECENTVIEW",
			"up"        : "UPVOD",
			"favoritebj" : "FAVORITE",
			"subscribebj": "USER"
		}
		var params = {
			szDataType	: pageDataType[$scope.oCurrent.szViewId],
			nPage		: $scope.oCurrent.oBottom.nPage,
			nLimit		: $scope.oCurrent.oBottom.nLimit,
			szTheme		: $scope.oCurrent.szViewId,
		}
		if($scope.oCurrent.rangeFilter != null){
			params.szOrder = $scope.oCurrent.rangeFilter.id;
		}
		if($scope.oCurrent.oSelectedFilter != null){
			params.szOrder = $scope.oCurrent.oSelectedFilter.id;
		}

		return params;
	}

	// 핫이슈 리스트
	$scope.getHotList = function() {
		$scope.getBottomList({
			szDataType		 : "HOT",
			szDataDetailType : $scope.oSelectedHotFilter.id,
			nPage 			 : $scope.oCurrent.oBottom.nPage,
			nLimit           : 40,
		});
	}

	// 리스트 조회.
	var getList = function(szLocation){
		switch($scope.oCurrent.szViewId){
			case "recent" :
				switch(szLocation){
					case "top" :
						$scope.getRecentTopList();
						break;
					case "bottom" :
						$scope.getRecentBottomList();
						break;
				}
				break;
			case "category" :
				$scope.getCategoryList();
				break;
			case "later" :
			case "up" :
				$scope.getBottomList(getCommonParams());
				break;
			case "playlist":
				$scope.getPlaylists();
				break;
			case "hot":
				$scope.getHotList();
				break;
		}
	};

	// 리스트 바인딩.
	var makeList = function(data, szLocation){
		$scope.bShowLoading = false;

		var oList = (szLocation == "top")? $scope.oCurrent.oTop :  $scope.oCurrent.oBottom;
		oList = angular.extend(oList, data);

		// 카테고리, 티비클립 - 검색api를 통해 얻은 데이터 표준화.
		if($scope.oCurrent.szViewId === "category"){
			angular.forEach(data.list, function(oVideo){
				oVideo.read_cnt = $filter('number')(oVideo.view_cnt, 0);
				oVideo.thumb = oVideo.thumbnail;
				oVideo.duration = Util.secondsToTime(oVideo.duration);
				oVideo.title_name = oVideo.title;

				if( [CATEGORY_NO_SPECIAL, CATEGORY_NO_HOTISSUE].includes($scope.oSelectedCategory.cate_no) === false){
					oVideo.reg_timestamp = oVideo.timestamp;
				}
			});
		}

		// 현재페이지 유지하고 데이터 갱신하는 경우.
		if($scope.oBackupList.oBottom != null){
			oList.nPage = $scope.oBackupList.oBottom.nPage;
			oList.nLimit = $scope.oBackupList.oBottom.nLimit;
			oList.aList = data.list;
			$scope.oBackupList.oBottom = null;
		} else {
			angular.forEach(data.list, function(oVideo) {
				oList.aList.push(oVideo);
			});
		}

		if($scope.oCurrent.szViewId =="playlist") {
			oList.bShowEmptyBox = (data.playlists.length === 0 && oList.nPage == 1)? true : false;
		}
		else {
			oList.bShowEmptyBox = (data.list && data.list.length === 0 && oList.nPage == 1)? true : false;
		}

		oList.szTotalCnt = (data.total_cnt == 0)? data.total_cnt : $filter('number')(oList.total_cnt, 0);

		if($scope.oCurrent.szViewId === "category"){
			oList.bHasMore = data.has_more_list;
		} else if($scope.oCurrent.szViewId === "playlist")
			oList.bHasMore = data.has_more;
		 else {
			oList.bHasMore = (data.more_yn == "Y")? true : false;
		}

		// 조회 완료여부(페이징 중복요청방지)
		$timeout(function(){
			oList.bCompleted = true;
			$scope.oCurrent.bNeedViewInit = false;
		}, 100, true);

		// 구독유저리스트 조회 후 첫번째 USER의 VOD 조회
		if( $scope.oCurrent.szViewId === "subscribebj" && szLocation === "top" ){
			if($scope.oSelectedSubscribeUser == null && oList.aList.length > 0){
				$scope.clickUser(oList.aList[0]);
			}
		}

		// 유저클립, 즐찾bj > 즐찾 bj 갯수
		if(($scope.oCurrent.szViewId === "userclip" || $scope.oCurrent.szViewId === "favoritebj") && szLocation === "bottom") {
			$scope.oCurrent.nBjCnt = (!data.bj_cnt) ? 0 : data.bj_cnt;
		}

		if($scope.oCurrent.szViewId =="hot" && data.catch_list.length>0){//캐치 형식때문에 js에서 초기화
			oList.aList.splice(1,0, data.catch_list[0]);
		}

		$scope.$digest();

		// 필터애니메이션 리셋
		if(szLocation == "bottom"){
			if(vodSwiper){
				vodSwiper.updateAutoHeight(0);
			}
		}
	};

	$scope.aPlaylists.aPlaylist =[];
	var makePlaylistList = function(data){
		$scope.aPlaylists['total_cnt'] = data.total_cnt;
		angular.forEach(data.playlists, function(oPlaylist){
			
		//	oVideo.read_cnt = $filter('number')(oVideo.view_cnt, 0);
 			oPlaylist.thumb = oPlaylist.thumb;
		//	oVideo.duration = Util.secondsToTime(oVideo.duration);
			oPlaylist.title_name = oPlaylist.title;
			oPlaylist.total_cnt = oPlaylist.total_cnt
			
			oPlaylist.reg_date = Util.regDateConvert(oPlaylist.reg_timestamp);
			$scope.aPlaylists.aPlaylist.push(oPlaylist);
			
		});
		$scope.$digest();
	}

	//더보기 버튼
	$scope.loadMore = function(func, idx) {
		if(typeof idx == "undefined"){
			func.limit = parseInt(func.limit) + 6;
		}
		else{
			func[idx].limit = parseInt(func[idx].limit) + 6;
		}
	}

	$scope.searchCategoryDepth = (category, categoryData, findCategoryData)=>{
    
		category.some((val) => {
			
			if(typeof val.category != "undefined" && val.category.length > 0){
				findCategoryData['cate_stack'].push(val.inflow_code); 
				findCategoryData = $scope.searchCategoryDepth(val.category, categoryData, findCategoryData);
				if(findCategoryData['find'] ) return true;
				else findCategoryData['cate_stack'].pop();
			}
			else if(typeof val.detail_category !="undefined" && val.detail_category.length >0 ){
				findCategoryData['cate_stack'].push(val.inflow_code); 
				findCategoryData = $scope.searchCategoryDepth(val.detail_category, categoryData, findCategoryData);
				if(val.my_cate_no == categoryData.my_cate_no){ 
					findCategoryData['find'] =true;
					findCategoryData['cate_stack'].push("all");
				}
				if(findCategoryData['find'] ) return true;
				else findCategoryData['cate_stack'].pop();
			}
			else{
	
				if(val.my_cate_no == categoryData.my_cate_no){ 
					findCategoryData['cate_stack'].push(val.inflow_code);
					findCategoryData['find'] =true;
					return true;
				}
			} 
		});
		return findCategoryData
		
	}

	// 광고 추가
	var addAdvertisement = function(oList){

		var szOS = Util.getOS();
		if( oList.nPage != 1 || oList.aList.length < 3 || oList.bHasAdvertisement || szOS == "etc"){
			$scope.$digest();
			return;
		}

		// 로그인 유저아이디.
		var userTicketName = (typeof USER_COOKIE_NAME != 'undefined') ? USER_COOKIE_NAME : $rootScope.$const.USER_COOKIE_NAME;
		var szLoginUser = "";
		var oUrl = Util.parseURL(M_AFREECATV + "?" + $cookies.get(userTicketName));		

		if(! $.isEmptyObject(oUrl.params)){
			szLoginUser = oUrl.params.uid;
		}

		// 브라우저언어
		var dlang = "";
		try{
			var lang = window.navigator.language || window.navigator.userLanguage;
			lang = lang.replace("-", "_");
			dlang = lang.split("_")[0];
		}catch (err){
			dlang = "";
		}

		// 광고추척설정여부
		var au = "Y";
		if(window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || (window.external != undefined && "msTrackingProtectionEnabled" in window.external) ){
			if(window.doNotTrack == "1" || navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1" || (window.external != undefined && window.external.msTrackingProtectionEnabled())){
				au = "N";
			}
		}
		var params = {
			t 		: 0,					// 고정값 전달
			acno	: 1003,					// 고정값
			dlang 	: dlang,				// 브라우저 언어 설정값.
			au		: au,					// 개인정보보호>광고>광고추적설정여부(추적가능:Y/추적불가:N)
			dresolw : $(window).width(),	// 디바이스 width
			dresolh	: $(window).height(),	// 디바이스 height
			aftvlid : (szLoginUser == "" || szLoginUser == undefined)? "" : md5(szLoginUser),  // 로그인유저아이디 md5
			oax		: ($cookies.get("OAX") == undefined)? "" : $cookies.get("OAX"),	// OAX 쿠키값
			aftvid	: ($cookies.get("_au") == undefined)? "" : $cookies.get("_au"),	// _au 쿠키
			os 		: (szOS == "android")? "aos" : szOS,
		}
		VideoListService.getAdvertisement(params).then(function(oRes) {
			oList.aList.splice(2, 0, oRes.AdType);
			oList.bHasAdvertisement = true;
			$scope.$digest();
		}).catch(function(){
			oList.bHasAdvertisement = false;
			$scope.$digest();
		});
	}

	// 핫이슈 필터
	$scope.aHotFilter = [
		{
			id		: "ALL",
			log_id	: "all",
			name	: "전체",
			img_off	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_All.png",
			img_on	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_All_on.png"
		},
		// {
		// 	id		: "HOTVOD",
		// 	log_id	: "hotissue",
		// 	name	: "핫이슈 VOD",
		// 	img_off	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_hot.png",
		// 	img_on	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_hot_on.png"			
		// },
		{
			id		: "USERCLIP",
			log_id	: "clip",
			name	: "유저클립",
			img_off	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_userclip.png",
			img_on	: RES_AFREECATV + "/images/mobile/afweb/theme_icon/ic_category_userclip_on.png"			
		}
	];

	/**
	 * 핫이슈 > 필터 변경
	 * @param {string} szFilterId 
	 * @param {bool} bListInit 
	 */
	$scope.goHotFilter = function(szFilterId, bListInit) {
		let oSelected = $scope.aHotFilter.find(oFilter => oFilter.id === szFilterId);

		// 선택된게 없으면 전체탭 보여줌
		if(!oSelected) {
			oSelected = $scope.aHotFilter[0];
		}
		
		$scope.oSelectedHotFilter = oSelected;

		if(bListInit) {
			listInit("bottom", false);
			$scope.closeCategorySheet();
		}
	}

	// swiper 객체.
	var vodSwiper = new Swiper(".swiper-container", {
		wrapperClass	: "vod-swiper-wrapper",
		oSwipingClass	: "swiper-no-swiping",
		autoHeight		: true,
		on : {
			slideChange : function(){
				var oSelectedTab = $scope.aLibraryTab[vodSwiper.activeIndex];
				if ($scope.$$phase == "$apply" || $scope.$$phase == "$digest") {
					$sessionStorage.oVodTab = oSelectedTab;
					$scope.oSelectedTab = oSelectedTab;
				} else {
					$scope.$apply(function() {
						$sessionStorage.oVodTab = oSelectedTab;
						$scope.oSelectedTab = oSelectedTab;
					});
				}
				$scope.setView();
			}
		}
	});



	angular.element(document).ready(function(){
		// ios dimmed 뒤 스크롤 방지.
		$('#category_dimmed').on('touchmove', function (e) {
			e.stopPropagation();
			e.preventDefault();

			$scope.bShowCategory = false;
			$("body").removeClass("action_sheet");
			$(".category_wrap").find(".depth_list").slideToggle("slow");
			$scope.$digest();
		}).delegate('div.category','touchmove', function(e){
			e.stopPropagation();
			e.preventDefault();
		});
	})

	// 스크롤 페이징
	Util.lastItem(function() {
		$scope.getNextList("bottom");
	});

	//모바일 동영상 UV
	$timeout(function() {
		window.szLogAction = "MOBILE_VOD_PAGE";
		$.getScript(ANALYSIS_AFREECATV + "/_au.js");
	}, 1000);

	$(window).on('scroll', function(e) {
		$scope.closeShare(); // 공유하기 레이어 닫기
		$('.icon-menu').hide(); // 닉네임 메뉴 닫기
	});

	$scope.init();
}
module.exports = VideoNewController;
