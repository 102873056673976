

VideoPlaylistService.$inject = ['$http', "Util"];
function VideoPlaylistService($http, Util) {
        // 베스트 동영상 리스트
        this.callPlaylistApi = function(data){
            return new Promise(function (resolve, reject){
                    $http.post(STBBS_AFREECATV + '/vodplaylist/api/api.php', $.param(data || '')).then(function (response){
                            if(response.data.result == 1) resolve(response.data)
                            else resolve(response.data);

                    }).catch(function (error){
                            reject(error);
                    })
            })
    }
       
}


module.exports = VideoPlaylistService;