IteminfoConfirmAddrController.$inject = ['$scope', '$state', '$stateParams', 'ItemService'];

function IteminfoConfirmAddrController($scope, $state, $stateParams, ItemService) {

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$state.go("iteminfo.mysticker");
	}

	/**
	 *  내 스티커 정보 페이지로 이동
	 */
	$scope.goMyStickerPage = function () {
		$state.go("iteminfo.mysticker");
	}

	/**
	 * 교환 완료 정보 불러오기
	 */
	ItemService.stickerItemConfirmAddr({
		order_num: $stateParams.order_num
	}).then(function (response) {
		$scope.total = 1;
		$scope.ticket = response.data;
		$scope.item_count = ($stateParams.reload) == 'done' ? '1' : $scope.ticket.item_count;
		$scope.$digest();
	}).catch(function (error) {
		$scope.total = 0;
		$scope.$digest();
	});
}
module.exports = IteminfoConfirmAddrController;