var toastr = require('toastr');
FavoriteController.$inject = ['$scope', '$rootScope', '$state', '$sessionStorage', 'FavoriteService', 'Util', 'LoginManager', 'HistoryState', 'gtext'];

function FavoriteController($scope, $rootScope, $state, $sessionStorage, FavoriteService, Util, LoginManager, HistoryState, gtext) {

	$rootScope.title = '즐겨찾기';
	$scope.oGroups = {};
	$scope.nPosition = 0;
	$scope.szOrderByType = 'DESC';
	$scope.bExistFavorites = true;
	var bCompleted;
	var nCurrentPage = 1;
	// order정보
	$scope.aOrderByColumns = [{
			id: 'view',
			name: '참여인원순'
		},
		{
			id: 'latest',
			name: '최신순'
		}
	];
	$scope.selectOrder = $scope.aOrderByColumns[0];

	//정렬 탭 클릭
	$scope.clickOrder = function (oOrder) {
		if ($scope.selectOrder == oOrder) {
			$scope.szOrderByType = $scope.szOrderByType == 'DESC' ? 'ASC' : 'DESC';
		} else {
			$scope.selectOrder = oOrder;
		}
		nCurrentPage = 1;
		bCompleted = false;
		getList();
	};


	// 더보기
	Util.lastItem(function () {
		if (bCompleted && $scope.bHasMore) {
			bCompleted = false;
			nCurrentPage++;
			getList();
		}
	});

	var getList = function () {
		if (nCurrentPage === 1) {
			$scope.oGroups = {};
		}
		FavoriteService.getList({
			'order_by_type': $scope.szOrderByType,
			'order_by_column': $scope.selectOrder.id,
			'current_page': nCurrentPage,
			'row_per_page': 20
		}).then(function (oRes) {
			// Title이 Key되서 데이터가 있으면 contents에 merge
			angular.forEach(oRes.data.groups, function (aGroup, nIdx) {
				if ($scope.oGroups.hasOwnProperty(aGroup.title)) {
					$.merge($scope.oGroups[aGroup.title].contents, aGroup.contents);
				} else {
					aGroup.order_no = nIdx;
					$scope.oGroups[aGroup.title] = aGroup;
				}
				// 모든그룹에 방송이 하나도없을경우 
				$scope.bShowEmptyBox = ((aGroup.contents.length < 1) && nCurrentPage == 1) ? true : false;
			});

			//더보기
			$scope.bExistFavorites = oRes.data.is_exist_favorites;
			$scope.bHasMore = oRes.data.has_more_list;
			$scope.$digest();

		}).then(function (oRes) {
			bCompleted = true;
		});
	};

	$scope.addFavoriteHandler = function (szUserId, oLogData) {
		if (!$rootScope.member) {
			if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?"))) {
				// 로그인후에 자동으로 즐쳐찾기 추가하기위함.
				$sessionStorage.favorateUserId = szUserId;
                $sessionStorage.logData = ologData || null;
				$rootScope.login();
			}
			return;
		}
		
		oLogData['join_cc'] = $rootScope.member.nationality_cd;
		
		delete $sessionStorage.favorateUserId;
        delete $sessionStorage.logData;
		FavoriteService.add(szUserId, oLogData).then(function () {
			toastr.success(gtext("즐겨찾기에 추가됐습니다."));
		}).catch(function (response) {
			toastr.error(response.data.message);
		});
	};

	//즐겨찾기 삭제 
	$scope.deleteFavoriteHandler = function (szUserId, oLogData) {
		if (loginCheck()){
			oLogData['join_cc'] = $rootScope.member.nationality_cd;
			if (confirm(gtext('해당 스트리머를 즐겨찾기에서\n삭제 하시겠습니까?'))) {
				FavoriteService.delete(szUserId, oLogData).then(function () {
					toastr.success(gtext("즐겨찾기에서 삭제됐습니다."));
					nCurrentPage = 1;
					bCompleted = false;
					getList();
				}).catch(function (response) {
					toastr.error(response.data.message);
				});
			}
		}
	};

	function loginCheck() {
		if (LoginManager.isLogin()) {
			return true;
		} else {
			toastr.error("로그인이 되어 있지 않습니다.");
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}
	}

	/**
	 * 로그인 이벤트
	 */
	$scope.$on('$loginChange', function (event, value) {
		if (value == false) {
			$rootScope.login($state.current.name);
		}
	});

	//History State정의
	HistoryState.init($scope, 'favorite', ['oGroups', 'nCurrentPage', 'bHasMore', 'bExistFavorites'], function () {
		if (loginCheck()) {
			getList();
		}
	});
}

module.exports = FavoriteController;