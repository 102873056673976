

require('jquery');
var angular = require('angular');
angular.module('afreecatv')
        .filter('orderObject', require('./order.filter'))
        .filter('emoticonEncode', require('./emoticon.encode.filter'));
// angular.module('afreecatv').filter('orderObject', function () {
//         return function (item) {
//                 return item;
//         };
// });