function Afterlaterlayer() {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			scope.$watch(attrs.afterlaterlayer, function () {
				element.css('top', $(window).scrollTop() + 150 + 'px');
				element.css('display', scope.bLaterViewAfterLayer ? 'block' : 'none');
			});
		}
	}
}

module.exports = Afterlaterlayer;