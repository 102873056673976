
AfreecaStorageFactory.$inject = ['$rootScope'];
function AfreecaStorageFactory($rootScope) {

        return {
                getItem: function (key) {
                        try {
                                var data = localStorage.getItem(key);
                                return data ? data : $rootScope[key];
                        } catch (e) {
                                return $rootScope[key];
                        }

                },

                //추가
                setItem: function (key, value) {

                        if (window.localStorage) {
                                try {
                                        localStorage.setItem(key, value);
                                }
                                catch (e) {
                                        $rootScope[key] = value;
                                }
                        }
                        else {
                                $rootScope[key] = value;
                        }
                },

                //삭제
                removeItem: function (key) {
                        try {
                                localStorage.removeItem(key);
                        } catch (e) {
                                delete $rootScope[key];
                        }

                }
        }
};

module.exports = AfreecaStorageFactory;