TokenService.$inject = ['$http'];
function TokenService($http) {
        this.sendTokenLog = function (szLoginId, type, aData) {
        		if(!szLoginId) return;
                $http.post(TOKENAPI_AFREECATV + '/logs/' + szLoginId + '/' + type + '/', $.param(aData || ''))
                .then(function (response) {})
                .catch(function (error) {})
        }
}

module.exports = TokenService;