

LaterviewService.$inject = ['$http'];
function LaterviewService($http) {
        this.add = function (data) {
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/later/a/add', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        this.del = function (data) {
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/later/a/delete', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
}


module.exports = LaterviewService;