NoteSettingController.$inject = [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"$window",
	"$http",
	"$timeout",
	"Util",
	"LoginManager",
	"NoteService",
	"gtext",
];

function NoteSettingController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, NoteService, gtext) {
	$scope.rejects = [];
	$scope.isDisabled = false;
	$scope.all_reject = false;
	$scope.rejectSelected = false;

	function _getSelectedItems() {
		var aDeleteId = [];
		for (var i = 0; i < $scope.rejects.length; i++) {
			if ($scope.rejects[i].checked) {
				aDeleteId.push($scope.rejects[i].reject_id);
			}
		}
		return aDeleteId;
	}

	function _removeSelectedItems() {
		var cnt = $scope.rejects.length - 1;
		for (var i = cnt; i >= 0; i--) {
			if ($scope.rejects[i].checked) {
				$scope.rejects.splice(i, 1);
			}
		}
		$scope.$digest();
	}

	function _checkOneSelected() {
		for (var i = 0; i < $scope.rejects.length; i++) {
			if ($scope.rejects[i].checked) {
				return true;
			}
		}
		return false;
	}

	function _checkAllSelected() {
		for (var i = 0; i < $scope.rejects.length; i++) {
			if (!$scope.rejects[i].checked) {
				return false;
			}
		}
		return true;
	}
	$scope.totalMessage = function(cnt) {
		return gtext("총 %s명", cnt);
	};

	$scope.backHandler = function() {
		$window.history.back();
	};
	$scope.checkAll = function() {
		$timeout(function() {
			for (var i = 0; i < $scope.rejects.length; i++) {
				$scope.rejects[i].checked = $scope.all;
			}
			$scope.rejectSelected = _checkOneSelected();
			$scope.$digest();
		});
	};
	$scope.checkOne = function() {
		$timeout(function() {
			$scope.all = _checkAllSelected();
			$scope.rejectSelected = _checkOneSelected();
			$scope.$digest();
		});
	};

	$scope.addHandler = function() {
		if (!$scope.reject_id) {
			alert(gtext("아이디를 입력해 주세요."));
			return false;
		}
		var regType1 = /^[A-Za-z0-9+]+$/;
		if (!regType1.test($scope.reject_id)) {
			alert(gtext("아이디는 영문,숫자만 입력 가능합니다."));
			$scope.reject_id = "";
			$("#reject_id").focus();
			return false;
		}

		$scope.reject_id = $scope.reject_id.trim().toLowerCase();
		NoteService.rejectAdd({
			reject_id: $scope.reject_id,
		})
			.then(function(response) {
				$scope.isDisabled = false;
				$scope.rejects.push({
					reject_id: $scope.reject_id,
					reg_date: new Date().getTime() / 1000,
				});
				$scope.all = false;
				$scope.reject_id = "";
				$scope.$digest();
			})
			.catch(function(response) {
				$scope.isDisabled = false;
				alert(response.data.message);
			});
	};

	$scope.removeHandler = function() {
		var selectedIds = _getSelectedItems();
		if (selectedIds.length == 0) {
			alert(gtext("아이디를 선택해 주세요"));
			return false;
		}

		NoteService.rejectDelete({
			reject_id: selectedIds,
		})
			.then(function(response) {
				$scope.isDisabled = false;
				$scope.all = false;
				$scope.rejectSelected = false;
				alert(gtext("해제되었습니다."));
				_removeSelectedItems();
			})
			.catch(function(response) {
				$scope.isDisabled = false;
				$scope.rejectSelected = false;
				alert(response.data.message);
			});
	};

	$scope.rejectAbleHandler = function() {
		NoteService.rejectAble({
			flag: $scope.all_reject,
		})
			.then(function(response) {
				$scope.isDisabled = false;
				$scope.$digest();
			})
			.catch(function(response) {
				$scope.isDisabled = false;
				alert(response.data.message);
			});
	};

	NoteService.setting()
		.then(function(response) {
			$scope.isDisabled = false;
			$scope.rejects = response.data.list;
			$scope.all_reject = response.data.all_reject;
			$scope.$digest();
		})
		.catch(function(response) {
			$scope.isDisabled = false;
		});
}
module.exports = NoteSettingController;
