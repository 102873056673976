Constant.$inject = ['$rootScope'];
function Constant($rootScope) {
        const ROOT_DOMAIN = 'sooplive.co.kr';
        $rootScope.$const = {
            SERVICE_ROOT_DOMAIN : ROOT_DOMAIN
            , AFREECATV_ROOT_DOMAIN : 'afreecatv.com'
            , SOOPLIVE_ROOT_DOMAIN : 'sooplive.co.kr'
            , AFREECATV_WEBVIEW_AGENT : 'Afreeca Webview'
            , SOOPLIVE_WEBVIEW_AGENT : 'Sooplive Webview'
            , AFREECATV_APP_SCHEME : 'afreeca://'
            , SOOPLIVE_APP_SCHEME : 'sooplive://'
            , SERVICE_APP_SCHEME : 'sooplive://'
            , WWW_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//www.'+ROOT_DOMAIN
            , RES_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//res.'+ROOT_DOMAIN
            , AUTH_M_AFREECATV : 'https://auth.m.'+ROOT_DOMAIN
            , MEMBER_M_AFREECATV : 'https://member.m.'+ROOT_DOMAIN
            , EVENT_IMG_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//event.img.'+ROOT_DOMAIN
            , BJ_AFREECATV : 'https://ch.'+ROOT_DOMAIN            
            , M_AFREECATV : 'https://m.'+ROOT_DOMAIN
            , PLAY_AFREECATV : 'https://play.'+ROOT_DOMAIN
            , VOD_AFREECATV : 'https://vod.'+ROOT_DOMAIN
            , TICKET_COOKIE_NAME : 'AuthTicket'
            , USER_COOKIE_NAME : 'UserTicket'
            , SERVICE_OFFICIAL_MAIL : 'soop@sooplive.co.kr'
        };
};

module.exports = Constant; 