


emoticons.$inject = ['$filter', '$sce', 'EmoticonService'];
function emoticons($filter, $sce, EmoticonService) {
        return function (item, field, reverse) {
                console.log(input);
                if (!input || input.message === undefined || input.message === null) {
                        return;
                }
                var message = input.message;
                var subscription = input.subscription;
                console.log(subscription);

                /**
                 * All the functions are being called here.
                 */
                message = EmoticonService.decodeStr(message);
                if (subscription) {
                        message = EmoticonService.decodeSubscriptionStr(message);
                }

                return $sce.trustAsHtml(message);
        }
}

module.exports = emoticons;