MypointAccumulateController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState', '$sce', 'gtext']

function MypointAccumulateController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState, $sce, gtext) {

    // 이전 페이지로 이동
    $scope.goBackPage = function () {
        history.back();
    }

    // 적립방법 상세 페이지 이동
    $scope.goDetailPage = function () {
        $state.go('mypoint.accumulatedetail');
    }

	// TOP버튼 설정
	var topBtn = $('.btn_top');
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
	topBtn.click(function () {
		$('body,html').animate({
		  scrollTop: 0
		}, 100);
		return false;
	});

	// 호출시 페이지 최상단부터 노출
	$('body,html').scrollTop(0);
	
}

module.exports = MypointAccumulateController;
