var toastr = require('toastr');
var Swiper = require("swiper/dist/js/swiper.min");
HomeController.$inject = ['$scope', '$rootScope', '$stateParams', '$http', '$timeout', '$window', '$sessionStorage', 'gtext', 'Util', 'HistoryState', 'CookieFactory'
        , 'CategoryService', 'LiveService', 'AlarmService', 'CountryService','gettextCatalog'];
function HomeController($scope, $rootScope, $stateParams, $http, $timeout, $window, $sessionStorage, gtext, Util, HistoryState, CookieFactory, CategoryService, LiveService, AlarmService, CountryService,gettextCatalog) {
        
        $('body').removeClass('liveArea fullscreen');
        $rootScope.title = 'SOOP';
        var _alarmIdx = null;
        // 선택된 테마
        $scope.oSelectedTheme = {};
        // 카테고리
        $scope.oCategories = {};
        // 전체 테마리스트 
        $scope.oThemes = {};
        // page 번호
        $scope.current_page_no = 1;
        //선택된 카테고리
        $scope.oSelectedCategory = {};
        //
        $scope.bAlarmPopup = false;
        //
        $scope.bAlarmExist = false;

        $scope.bAddLineClass = false;

        //이미지언어
        $scope.img_lang = gettextCatalog.currentLanguage.substr(3,2).toLowerCase();
        $scope.country_code = gettextCatalog.currentLanguage

        
        // IP에 따른 국가 코드 
        $scope.ip_country_code = 'KR';
        // 레이어 제어 on, off 변수
        $scope.bIpCountryLayerToggle = false;

        // 오늘 하루 레이어 띄울지 말지 체크 박스 값
        $scope.bTodayCloseLayer = true;

        // 텍스트 배너 Swiper
        $scope.textBannerSwiper = function () {
                var swiper = new Swiper(".HotList", {
                        slidesPerView: 1,
                        spaceBetween: 5,
                        direction: "vertical",
                        autoplay: {
                                delay: 3000,
                        },
                        loop: true,
                        allowTouchMove : false,
                });
                
                swiper.on("click", function(e){
                        $scope.oThemes['sports']['인기 스포츠']['text_banner'].forEach(element => {
                                if(element['order_no'] == e.target.offsetParent.classList[2]){
                                        if(element['scheme'].includes("player")){
                                                var arrInflowPath = ['page_hash', 'sports', 'headline'];
                                                $rootScope.sendInflowLog('VOD', '', element['no'], arrInflowPath);
                                        }
                                        $scope.sendClickLog(element['scheme']);
                                        $.get(element['log']);
                                        $scope.action(element['scheme']);
                                }
                        });
                });
        };

        // 텍스트 배너 데이터 있을 시 기존 인기 스포츠 타이틀 숨김처리
        $scope.popularSportsTitle = function () {
                if($scope.oSelectedTheme.theme_id == 'sports'){
                        if($scope.oThemes['sports']['인기 스포츠'].hasOwnProperty('text_banner')){
                                $(".swipe_sub h2").hide();
                        } else {
                                $(".swipe_sub h2").show();
                        }
                }
        };

        /**
         * CLICK 로그 전송
         * @returns {undefined}
         */
        $scope.sendClickLog = function (url) {
                window.collector.click({
                        code_type: 'sports_headline',
                        url: url,
                });
        };

        $scope.bSponserLayerToggle = CookieFactory.get("openSponserLayer") =="ON" ? false : true;
		
        /*****************************
         * 카테고리 + 방송리스트 호출 
         *****************************/
        var getCategoryWithList = function () {
                CategoryService.list().then(function (oRes) {
                        $sessionStorage.oCategory = angular.copy(oRes.data);
                        $scope.oSelectedTheme = oRes.data[0];
                        $scope.oCategories = oRes.data;
                        $scope.oSelectedTheme.bShowCategory = false;

                        // 기본 테마클릭으로 들어왔을때
                        if ($.isEmptyObject($stateParams.oParams)) {
                                getList();

                        }
                        // 테마이동으로 들어왔을때는
                        else {
                                HistoryState.reset();
                                $scope.moveThemeGroupCategory();
                        }

                })

        };

        /********************
         *  방송리스트 호출
         *********************/
        var getList = function (bStaticCallApi) {
                // 현재 선택된 theme_id
                var szThemeId = $scope.oSelectedTheme.theme_id;

                // 푸터 (이용약관, 개인정보방침, 사업자 정보 노출을 위한 변수)
                $rootScope.mainFooterCheck = $scope.oSelectedTheme.theme_order == 1 ? true : false;

                // 테마리스트가 없거나 강제로 API호출할 경우만 리스트를 호출
                if ($scope.oThemes[szThemeId] && !bStaticCallApi) {
                        return false;
                }

                // 리스트 호출 실행
                $scope.oSelectedTheme.bLoad = true;
                LiveService.list({
                        theme_id: $scope.oSelectedTheme.theme_id || 'hot',
                        group_id: $scope.oSelectedTheme.group_id || '',
                        category_no: $scope.oSelectedTheme.category_no,
                        current_page: $scope.current_page_no || 1
                }).then(function (oRes) {
                        // Title이 Key되서 데이터가 있으면 contents에 merge
                        angular.forEach(oRes.data.groups, function (oGroup, nIdx) {

                                // 테마 정보가 없을시
                                if (!$scope.oThemes[szThemeId]) {
                                        $scope.oThemes[szThemeId] = {};
                                }

                                // 그룹별 컨턴츠 담기
                                if ($scope.oThemes[szThemeId].hasOwnProperty(oGroup.title)) {
                                        if (oGroup.contents !== null) {
                                                $.merge($scope.oThemes[szThemeId][oGroup.title].contents, oGroup.contents);
                                        }
                                } else {
                                        $scope.oThemes[szThemeId][oGroup.title] = oGroup;
                                        $scope.oThemes[szThemeId][oGroup.title].order_no = nIdx;
                                }
                                // 모든그룹에 방송이 하나도없을경우 
                                $scope.bShowEmptyBox = ((oGroup.contents === null || oGroup.contents.length < 1) && $scope.current_page_no == 1) ? true : false;
                        });

                        // 더보기, 로드상태
                        $scope.oSelectedTheme.bHasMore = oRes.data.has_more_list;
                        $scope.oSelectedTheme.bLoad = false;
                        $scope.$digest();
                        
                        if($scope.oSelectedTheme.theme_id == 'sports' && $scope.oThemes['sports']['인기 스포츠'].hasOwnProperty('text_banner')){
                                $scope.textBannerSwiper();
                        }

                }).catch(function (oError) {
                        $scope.oSelectedTheme.bLoad = false;
                });
        };

        // 테마 선택 클릭
        $scope.clickTheme = function (oCategory) {
                $scope.oSelectedTheme = oCategory;

                // e스포츠 카테고리는 e스포츠로 이동
                if($scope.oSelectedTheme.theme_id == "esports") {

                        location.href = ESPORTS_AFREECATV;
                        window.onpageshow =  function(event) { // BFCahe
                                if (event.persisted) {
                                        window.location.reload();
                                }else{} //새로운페이지
                        }
                        return false;
                }

                Util.moveScroll(0);
                if($scope.oSelectedCategory[$scope.oSelectedTheme.theme_id] == undefined){
                        $scope.oSelectedTheme.selectedGroupName = oCategory.group_title;
                }else{
                        $scope.oSelectedTheme.selectedGroupName = $scope.oSelectedCategory[$scope.oSelectedTheme.theme_id];
                }
                $scope.oSelectedTheme.bShowCategory = false;
                $scope.oSelectedTheme.category_no = '';
                $scope.oSelectedTheme.group_id = '';
                $scope.bAddLineClass = false;

                // 파라메터로 테마 이동했을시 재 테마클릭시엔 전체로 갱신하기위해 방송리스트 삭제
                if ($stateParams.oParams) {
                        // 방송리스트 삭제
                        $scope.oThemes[$stateParams.oParams.theme_id] = null;
                        $stateParams.oParams = null;
                }

                //active 바 이동
                $scope.moveActiveBar();

                // 리스트 호출
                getList();
        };

        // 카테고리 LAST
        $scope.categoryLast = function (bLast) {
                if (bLast) {
                        $scope.moveActiveBar();
                }
        };

        // 선택 바 이동
        $scope.moveActiveBar = function () {
                setTimeout(function () {
                        var li = $('#category > li.active');
                        var oPosition = $(li).offset();

                        $('#activeBar').css({
                                left: (oPosition) ? oPosition.left : 0,
                                width: $(li).width()
                        });
                }, 100);
        };

        // 디바이스 회전시 Active 바 재설정
        $($window).bind('orientationchange', onOrientationChange);
        $($window).bind('resize', onOrientationChange);
        function onOrientationChange() {
                $scope.moveActiveBar();
        }

        // 카테고리 클릭
        $scope.showCategory = function (theme) {
                $scope.oSelectedTheme.bShowCategory = !$scope.oSelectedTheme.bShowCategory;
        };

        // 그룹선택
        $scope.selectGroup = function (oGroup) {
                // 그룹정보가 없으면 전체그룹 호출
                if (!oGroup) {
                        oGroup = {};
                        oGroup.name = $scope.oSelectedTheme.group_title;
                        oGroup.id = '';
                }

                if (oGroup.group_id == 'adult') {
                        if (confirm(gtext('청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\nSOOP 앱에서만 참여할 수 있습니다.\n\nSOOP 앱에서 참여하시겠습니까?'))) {
                                $scope.oSelectedTheme.bShowCategory = false;
                                afreeca.link.goLiveTab({ theme_id: $scope.oSelectedTheme.theme_id, group_id: 'adult' });
                        }
                        return;
                }

                $scope.oSelectedTheme.selectedGroupName = oGroup.name;
                $scope.oSelectedCategory[$scope.oSelectedTheme.theme_id] = oGroup.name;
                $scope.oSelectedTheme.group_id = oGroup.group_id;
                $scope.oSelectedTheme.category_no = null;
                $scope.oSelectedTheme.bShowCategory = false;

                // 방송리스트 삭제
                $scope.oThemes[$scope.oSelectedTheme.theme_id] = null;

                // 리스트 호출
                getList();
        };

        //카테고리 선택
        $scope.selecCategory = function (oCategory) {
                $scope.oSelectedTheme.category_no = oCategory.category_no;
                $scope.oSelectedTheme.selectedGroupName = oCategory.name;

                //선택된카테고리 저장
                $scope.oSelectedCategory[$scope.oSelectedTheme.theme_id] = oCategory.name;

                $scope.oSelectedTheme.bShowCategory = false;

                // 방송리스트 삭제
                $scope.oThemes[$scope.oSelectedTheme.theme_id] = null;

                //리스트 호출
                getList();
        };

        $scope.selectPosterCategory = function (categoryno,category_name) {
				if(category_name == "연령제한") {
					if(confirm(gtext('청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\nSOOP 앱에서만 참여할 수 있습니다.\n\nSOOP 앱에서 참여하시겠습니까?'))) {
						$scope.oSelectedTheme.bShowCategory = false;
						afreeca.link.goLiveTab({theme_id: $scope.oSelectedTheme.theme_id, group_id: 'adult'});
					}
					return;
				}

                $scope.oSelectedTheme.category_no = categoryno;
                $scope.oSelectedTheme.selectedGroupName = category_name;
                $scope.oSelectedCategory[$scope.oSelectedTheme.theme_id] = category_name;
                $scope.oSelectedTheme.bShowCategory = false;

                // 방송리스트 삭제
                $scope.oThemes[$scope.oSelectedTheme.theme_id] = null;

                //리스트 호출
                getList();
        };

        //게임섹션 서브배너, 스포츠페이지 배너일때는 ti_banner클래스추가
        $scope.addGameSubBannerClass = function(title, themeId = ''){
                if(title =='sub_banner'){
                        if (themeId === 'sports' ) {
                                return "";
                        }
                        return "ti_banner";
                }else{
                        return "";
                }
        };

        //처음 호출된 area에는 line_tx 클래스 추가
        $scope.addLineClass = function(title){
                if(title == $scope.bAddLineClass || !$scope.bAddLineClass) {
                        $scope.bAddLineClass = title;
                        return "line_tx";
                }else{
                        return "";
                }
        };

        // 그룹안에 is_web 이 있는지 체크
        $scope.checkEnableGroup = function (oContents) {
                var bResult = false;
                angular.forEach(oContents, function (oValue, nKey) {
                        if (oValue.is_web == 1) {
                                bResult = true;
                                return true;
                        }
                });
                return bResult;
        };


        // 더보기 이벤트 핸들러 등록 (더보기 기능)
        Util.lastItem(function () {
                if (!$scope.oSelectedTheme.bLoad && $scope.oSelectedTheme.bHasMore) {
                        $scope.current_page_no++;

                        console.log($scope.current_page_no);
                        getList(true);
                }
        });
        
        $scope.$on('$destroy', function() {
                Util.lastItem(null)
        });


        // 카테고리 배너
        $scope.initCtegoryBackground = function (e) {
                $('.theme_background').on('touchstart mousedown click', function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        $scope.$apply(function () {
                                $scope.oSelectedTheme.bShowCategory = false;
                        });
                });
        };

        // 이미지뷰에 사용되는 scheme object형식으로 변경
        $scope.getImageContents = function (szScheme) {
                return Util.action(szScheme);
        };

        // 광고정보 호출 
        $scope.setAdvertisement = function (oItem) {
                var szUrl;
                if (Util.getOS() == 'ios') {
                        szUrl = oItem[0].is_web_ios;
                } else if (Util.getOS() == 'android') {

                        szUrl = oItem[0].is_web_aos;
                }

                // 광고 테그가 없을경우 노출 없음.
                if (!szUrl) {
                        return false;
                }

                $.ajax({
                        url: szUrl, dataType: "jsonp", jsonpCallback: "callback", success: function (oRes) {
                                $scope.oAdvertisement = oRes;
                                $scope.$digest();
                        }
                });
        };

        // 더보기로 (파라메터로 테마이동시)
        $scope.moveThemeGroupCategory = function () {
                var oParmas = $stateParams.oParams;
                angular.forEach($scope.oCategories, function (oTheme, nThemeIdx) {
                        if (oTheme.theme_id == oParmas.theme_id) {

                                //방송리스트 삭제
                                $scope.oThemes[$scope.oSelectedTheme.theme_id] = null;

                                //초기 테마정보 셋팅
                                Util.moveScroll(0);
                                $scope.oSelectedTheme = oTheme;
                                $scope.oSelectedTheme.selectedGroupName = oTheme.group_title;
                                $scope.oSelectedTheme.bShowCategory = false;
                                $scope.moveActiveBar();

                                //그룹 데이터
                                var oGroup = oTheme.group.getDataById('group_id', oParmas.group_id);

                                // 테마이동
                                if (!oParmas.group_id && !oParmas.category_no) {
                                        $scope.clickTheme(oTheme);
                                }
                                //상세 그룹이동일때
                                else if (oParmas.group_id && !oParmas.category_no) {
                                        $scope.selectGroup(oGroup);
                                }

                                // 카테고리 이동일때
                                else if (oParmas.category_no) {
                                        var oCategory = oGroup.category.getDataById('category_no', oParmas.category_no);
                                        $scope.selecCategory(oCategory);
                                }

                        }
                });
        };

        var alarmPopupTimer = null;
        $scope.showAlaramPopup = function ($event, alarm_idx) {
                $event.stopPropagation();
                $event.preventDefault();
                _alarmIdx = alarm_idx;
                AlarmService.status({ alarm_idx: _alarmIdx }).then(function (resposne) {
                        $scope.bAlarmExist = resposne.is_exist;
                        $scope.bAlarmPopup = !$scope.bAlarmPopup;

                        //3초 자동닫힘;
                        if (alarmPopupTimer)
                                $timeout.cancel(alarmPopupTimer);
                        alarmPopupTimer = $timeout(function () {
                                $scope.bAlarmPopup = false;
                        }, 3000);
                }).catch(function (resposne) {
                        $scope.bAlarmPopup = false;
                        if (resposne.data.code == -1000) {
                                if (confirm(gtext("알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?"))) {
                                        $rootScope.login();
                                }
                                return;
                        } else {
                                toastr.error(resposne.data.message);
                        }
                });
        }

        $scope.alarmRegister = function () {
                AlarmService.register({ alarm_idx: _alarmIdx, platform: 'MOBILE_WEB' }).then(function (resposne) {
                        toastr.success(gtext("알림 예약이 완료되었습니다."));
                        $scope.bAlarmPopup = false;
                }).catch(function (resposne) {
                        toastr.error(resposne.data.message);
                        $scope.bAlarmPopup = false;
                });

        }
        $scope.alarmUnRegister = function () {
                AlarmService.unregister({ alarm_idx: _alarmIdx, platform: 'MOBILE_WEB' }).then(function (resposne) {
                        toastr.success(gtext("알림 예약이 취소되었습니다."));
                        $scope.bAlarmPopup = false;
                }).catch(function (resposne) {
                        toastr.error(resposne.data.message);
                        $scope.bAlarmPopup = false;
                });
        }
        $scope.alarmClose = function () {
                $scope.bAlarmPopup = false;

        }

        $scope.sports_score_init = function() {
                $(".sports_live_state li:not(:first-child)").hide();
        }
        $scope.sportsScoreMore = function () {
                if($(".sports_live_more button").hasClass("on")){
                        $(".sports_live_more button").removeClass("on").text("더보기");
                        $(".sports_live_state li:not(:first-child)").hide();
                }else{
                        $(".sports_live_more button").addClass("on").text("접기");
                        $(".sports_live_state li").show();
                }
        }
        $scope.sportsScheduleInflowLog = function (scheme) {
                var schemeArr = scheme.split("&");
                var broad_no = schemeArr[0].split("broad_no=")[1];
                var bj_id = schemeArr[1].split("user_id=")[1];
                var arrInflowPath = ['main', 'sports'];

                // 유입경로 함수로 전달
                $rootScope.sendInflowLog('LIVE', bj_id, broad_no, arrInflowPath);
        }

        /******************************************
         *      API 호출부  with HistoryState
         ******************************************/
        HistoryState.init($scope, 'home', ['oSelectedTheme', 'oThemes', 'oCategories', 'current_page_no'], function () {
                getCategoryWithList(); // 카테고리 +_ 방송리스트 호출
        });


        // 배열에서 원하는 키를 가진 값 가져오기
        Array.prototype.getDataById = function (szKeyName, szKeyValue) {
                if (!this) {
                        return;
                }
                var oData = this;
                var oResult = {};

                angular.forEach(oData, function (oItem) {
                        if (oItem[szKeyName] == szKeyValue) {
                                oResult = oItem;
                        }

                });
                return oResult;

        };

        $scope.getIpCountryCode = function () {
            CountryService.getIpCountryCode().then(function (response) {
                // 국가코드가 중국 간체이면서 오늘 하루 레이어 닫기 옵션이 활성화가 아닌 경우 레이어 띄움
                if (response.data == 'CN') {

                    if (CookieFactory.get('availableChinaLayer') == 1) {
                        $scope.bIpCountryLayerToggle = false;
                    } else {
                        $scope.bIpCountryLayerToggle = true;
                    }
                }
            }, function () {
                // $rootScope.$apply();
            })
        }

        /**
         * 중국 간체 레이어 닫기
         */
        $scope.layerClose = function () {
            $scope.bIpCountryLayerToggle = false;
            if ($scope.bTodayLayerToggle) {
                CookieFactory.set('availableChinaLayer', '1', 1);
            }
        }
        /**
         * 후원 팝업 닫기
         *  
         */
        $scope.closeSponserLayer = function(nCookie){
                $scope.bSponserLayerToggle = false;
                //최초진입시 바로 쿠키 심기
                $timeout(function () {
                        if( CookieFactory.get('openSponserTooltip') !="ON")  CookieFactory.set("openSponserTooltip","ON", 365);
                        
                },3000)
                if(nCookie ===0){
                        window.collector.click({
                                code_type : 'main_popup',
                                button_type: 'popclose',
                                to_url: BJ_AFREECATV + "/afsupport/post/88939917",
                        });
                }
                else if(nCookie === 1){
                        window.collector.click({
                                code_type : 'main_popup',
                                button_type: 'notshow',
                                to_url: BJ_AFREECATV + "/afsupport/post/88939917",
                        });
                        if( CookieFactory.get('openSponserLayer') !="ON") CookieFactory.set('openSponserLayer', "ON", 365);
                }

                $(".btn_item_wrap").addClass("tt");
        }  
        
        /**
         * 후원페이지 이동
         */

        $scope.goSponserPage = function(){
                window.collector.click({
                        code_type : 'main_popup',
			button_type: 'buy',
			to_url: BJ_AFREECATV + "/afsupport/post/88939917",
		});
		location.href = BJ_AFREECATV + "/afsupport/post/88939917";
	}

        $scope.getIpCountryCode();
        
}

module.exports = HomeController; 