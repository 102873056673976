MypointService.$inject = ['$http'];

function MypointService($http) {
  this.history = function(userId,startDate,endDate,callType,pageNo,randNo) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/history/'+userId+'/?'+ randNo +'&start_date='+ startDate +'&end_date='+ endDate +'&s_type='+ callType +'&page='+ pageNo +'')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.amount = function(userId) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/tokens/'+ userId +'/?'+ new Date().getTime() +'')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.mission = function(userId) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/history/'+ userId +'/mission/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.exchange = function(userId,type,data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(TOKENAPI_AFREECATV + '/exchange/'+ userId +'/'+ type +'/', $.param(data || ""))
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.purchaseItems = function() {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/items/token/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.possessionItems = function(userId) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/items/myself/'+ userId +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.expiredItems = function(userId) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/items/expire/'+ userId +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.itemPurchaseDetail = function(itemNo) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/items/token/'+ itemNo +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.itemPurchase = function(itemNo) {
    return new Promise(function(resolve, reject) {
      $http
        .post(TOKENAPI_AFREECATV + '/items/token/'+ itemNo +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.itemUseDetail = function(userId,itemNo) {
    return new Promise(function(resolve, reject) {
      $http
        .get(TOKENAPI_AFREECATV + '/items/myself/'+ userId +'/'+ itemNo +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.itemUse = function(userId,itemNo) {
    return new Promise(function(resolve, reject) {
      $http
        .put(TOKENAPI_AFREECATV + '/items/myself/'+ userId +'/'+ itemNo +'/')
        .then(function(response) {
          if (response.data.RESULT == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.deleteHistory = function(userId,data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(TOKENAPI_AFREECATV + '/history/'+ userId +'/', $.param(data || ""),{
          headers: {"X-HTTP-Method-Override": "DELETE"}
        })
        .then(function(response) {
          if (response.status == 204) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.getNickName = async (userId) => {
    var response = 
      await $http.get(MYPOINT_AFREECATV + "/api/getNickName.php?szUserId=" + userId)

    if(parseInt(response.status) !== 200) {
      return userId;
    }
    
    return response.data;
  }
}

module.exports = MypointService;