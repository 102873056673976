KeywordService.$inject = ['$http'];
function KeywordService($http) {
    this.searchHistory = function (data) {
        var param = angular.merge({}, {
            m: 'searchHistory'
            , isMobile: 1
            , w: 'webm'
            , v: '2.0'
        }, data);

        return new Promise(function (resolve, reject) {
            $http.get(SCKETC_AFREECATV + '/api.php?' + $.param(param || '')).then(function (response) {
                if (response.data.result == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data);
                }
            }).catch(function (error) {
                reject(error);
            })
        });
    };
}
module.exports = KeywordService;