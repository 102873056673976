EmoticonService.$inject = ['$http'];

function EmoticonService($http) {

	var emoticons = {
		"subscription": [{
			"name": "구독 이모티콘",
			"list": [{
					"file": "S101.png",
					"word": "/확인요/"
				},
				{
					"file": "S102.png",
					"word": "/미션/"
				},
				{
					"file": "S103.png",
					"word": "/ㅇㅋ/"
				},
				{
					"file": "S104.png",
					"word": "/티키타카/"
				},
				{
					"file": "S105.png",
					"word": "/ㄱㄴㅇ/"
				},
				{
					"file": "S106.png",
					"word": "/동의/"
				},
				{
					"file": "S107.png",
					"word": "/굿밤/"
				},
				{
					"file": "S108.png",
					"word": "/맴찢/"
				},
				{
					"file": "S109.png",
					"word": "/나이따/"
				},
				{
					"file": "S110.png",
					"word": "/ㄱㄱ/"
				},
				{
					"file": "S111.png",
					"word": "/조오치/"
				},
				{
					"file": "S112.png",
					"word": "/ㄴㅇㅂㅈ/"
				},
				{
					"file": "S113.png",
					"word": "/데헷/"
				},
				{
					"file": "S114.png",
					"word": "/런/"
				},
				{
					"file": "S115.png",
					"word": "/각/"
				},
				{
					"file": "S116.png",
					"word": "/실화/"
				},
				{
					"file": "S117.png",
					"word": "/ㅇㅈ/"
				},
				{
					"file": "S118.png",
					"word": "/ㅇㄱㄹㅇ/"
				},
                {
					"file": "S119.png",
					"word": "/반사/"
				},
				{
					"file": "S120.png",
					"word": "/TMI/"
				},
				{
					"file": "S121.png",
					"word": "/JMT/"
				},
				{
					"file": "S122.png",
					"word": "/할많하않/"
				},
				{
					"file": "S123.png",
					"word": "/현타/"
				},
				{
					"file": "S124.png",
					"word": "/엄근진/"
				},
				{
					"file": "S125.png",
					"word": "/머쓱/"
				},
				{
					"file": "S126.png",
					"word": "/탈룰라/"
				},
				{
					"file": "S127.png",
					"word": "/누나/"
				},
				{
					"file": "S128.png",
					"word": "/탈주/"
				},
				{
					"file": "S129.png",
					"word": "/손절/"
				},
				{
					"file": "S130.png",
					"word": "/하락/"
				}                
			]
		}],
		"emoticons": [{
			"name": "이모티콘",
			"list": [{
					"file": "1001.png",
					"word": "/샤방/"
				},
				{
					"file": "203.png",
					"word": "/윽/"
				},
				{
					"file": "1404.png",
					"word": "/휘파람/"
				},
				{
					"file": "1101.png",
					"word": "/짜증/"
				},
				{
					"file": "601.png",
					"word": "/헉/"
				},
				{
					"file": "1201.png",
					"word": "/하이/"
				},
				{
					"file": "1403.png",
					"word": "/개좋아/"
				},
				{
					"file": "304.png",
					"word": "/개도발/"
				},
				{
					"file": "603.png",
					"word": "/개털림/"
				},
				{
					"file": "702.png",
					"word": "/개감상/"
				},
				{
					"file": "102.png",
					"word": "/개화나/"
				},
				{
					"file": "701.png",
					"word": "/개이득/"
				},
				{
					"file": "1203.png",
					"word": "/개번쩍/"
				},
				{
					"file": "1409.png",
					"word": "/짱좋아/"
				},
				{
					"file": "309.png",
					"word": "/피식/"
				},
				{
					"file": "605.png",
					"word": "/헐/"
				},
				{
					"file": "704.png",
					"word": "/감상중/"
				},
				{
					"file": "106.png",
					"word": "/화나/"
				},
				{
					"file": "703.png",
					"word": "/하하/"
				},
				{
					"file": "508.png",
					"word": "/ㅠㅠ/"
				},
				{
					"file": "1701.png",
					"word": "/주작/"
				},
				{
					"file": "802.png",
					"word": "/꿀잼/"
				},
				{
					"file": "1305.png",
					"word": "/업/"
				},
				{
					"file": "1306.png",
					"word": "/갑/"
				},
				{
					"file": "901.png",
					"word": "/묻/"
				},
				{
					"file": "1004.png",
					"word": "/심쿵/"
				},
				{
					"file": "902.png",
					"word": "/스겜/"
				},
				{
					"file": "803.png",
					"word": "/추천/"
				},
				{
					"file": "804.png",
					"word": "/인정/"
				},
				{
					"file": "1407.png",
					"word": "/사이다/"
				},
				{
					"file": "407.png",
					"word": "/더럽/"
				},
				{
					"file": "103.png",
					"word": "/극혐/"
				},
				{
					"file": "903.png",
					"word": "/매너챗/"
				},
				{
					"file": "105.png",
					"word": "/강퇴/"
				},
				{
					"file": "904.png",
					"word": "/드루와/"
				},
				{
					"file": "404.png",
					"word": "/아잉/"
				},
				{
					"file": "602.png",
					"word": "/기겁/"
				},
				{
					"file": "504.png",
					"word": "/우울/"
				},
				{
					"file": "1103.png",
					"word": "/쳇/"
				},
				{
					"file": "1402.png",
					"word": "/ㅋㅋ/"
				},
				{
					"file": "1502.png",
					"word": "/졸려/"
				},
				{
					"file": "1303.png",
					"word": "/최고/"
				},
				{
					"file": "505.png",
					"word": "/엉엉/"
				},
				{
					"file": "1405.png",
					"word": "/후훗/"
				},
				{
					"file": "1002.png",
					"word": "/부끄/"
				},
				{
					"file": "1003.png",
					"word": "/제발/"
				},
				{
					"file": "303.png",
					"word": "/덜덜/"
				},
				{
					"file": "801.png",
					"word": "/좋아/"
				},
				{
					"file": "402.png",
					"word": "/반함/"
				},
				{
					"file": "204.png",
					"word": "/멘붕/"
				},
				{
					"file": "101.png",
					"word": "/버럭/"
				},
				{
					"file": "1102.png",
					"word": "/우엑/"
				},
				{
					"file": "403.png",
					"word": "/뽀뽀/"
				},
				{
					"file": "302.png",
					"word": "/심각/"
				},
				{
					"file": "503.png",
					"word": "/쥘쥘/"
				},
				{
					"file": "1401.png",
					"word": "/헤헤/"
				},
				{
					"file": "502.png",
					"word": "/훌쩍/"
				},
				{
					"file": "604.png",
					"word": "/코피/"
				},
				{
					"file": "308.png",
					"word": "/철컹철컹/"
				},
				{
					"file": "301.png",
					"word": "/섬뜩/"
				},
				{
					"file": "1301.png",
					"word": "/꺄/"
				},
				{
					"file": "1302.png",
					"word": "/굿/"
				},
				{
					"file": "501.png",
					"word": "/글썽/"
				},
				{
					"file": "201.png",
					"word": "/황당/"
				},
				{
					"file": "202.png",
					"word": "/정색/"
				},
				{
					"file": "1501.png",
					"word": "/피곤/"
				},
				{
					"file": "401.png",
					"word": "/사랑/"
				},
				{
					"file": "506.png",
					"word": "/좌절/"
				},
				{
					"file": "1910.png",
					"word": "/사탕/"
				},
				{
					"file": "507.png",
					"word": "/RIP/"
				},
				{
					"file": "1911.png",
					"word": "/건빵/"
				},
				{
					"file": "1912.png",
					"word": "/사과/"
				},
				{
					"file": "1913.png",
					"word": "/귤/"
				},
				{
					"file": "1408.png",
					"word": "/겁나좋군/"
				},
				{
					"file": "808.png",
					"word": "/근육녀/"
				},
				{
					"file": "806.png",
					"word": "/근육남/"
				},
				{
					"file": "807.png",
					"word": "/박수/"
				},
				{
					"file": "1903.png",
					"word": "/소주/"
				},
				{
					"file": "1304.png",
					"word": "/짱/"
				},
				{
					"file": "1904.png",
					"word": "/꽃/"
				},
				{
					"file": "1601.png",
					"word": "/왕/"
				},
				{
					"file": "205.png",
					"word": "/썰렁/"
				},
				{
					"file": "1902.png",
					"word": "/무지개/"
				},
				{
					"file": "1801.png",
					"word": "/태극기/"
				},
				{
					"file": "104.png",
					"word": "/절교/"
				},
				{
					"file": "405.png",
					"word": "/하트/"
				},
				{
					"file": "1905.png",
					"word": "/불/"
				},
				{
					"file": "1906.png",
					"word": "/별/"
				},
				{
					"file": "306.png",
					"word": "/폭탄/"
				},
				{
					"file": "1602.png",
					"word": "/폭죽/"
				},
				{
					"file": "1605.png",
					"word": "/보석/"
				},
				{
					"file": "1603.png",
					"word": "/금/"
				},
				{
					"file": "1908.png",
					"word": "/돈/"
				},
				{
					"file": "1907.png",
					"word": "/맥주/"
				},
				{
					"file": "406.png",
					"word": "/입술/"
				},
				{
					"file": "805.png",
					"word": "/콜!/"
				},
				{
					"file": "1202.png",
					"word": "/번쩍/"
				},
				{
					"file": "1901.png",
					"word": "/19/"
				},
				{
					"file": "1406.png",
					"word": "/즐거워/"
				},
				{
					"file": "1604.png",
					"word": "/케이크/"
				},
				{
					"file": "1909.png",
					"word": "/약/"
				},
				{
					"file": "1802.png",
					"word": "/SK/"
				},
				{
					"file": "1803.png",
					"word": "/두산/"
				},
				{
					"file": "1804.png",
					"word": "/LG/"
				},
				{
					"file": "1805.png",
					"word": "/롯데/"
				},
				{
					"file": "1806.png",
					"word": "/삼성/"
				},
				{
					"file": "1807.png",
					"word": "/한화/"
				},
				{
					"file": "1808.png",
					"word": "/기아/"
				},
				{
					"file": "1809.png",
					"word": "/키움/"
				},
				{
					"file": "1810.png",
					"word": "/NC/"
				},
				{
					"file": "1811.png",
					"word": "/KT/"
				},
				{
					"file": "1812.png",
					"word": "/SK마/"
				},
				{
					"file": "1813.png",
					"word": "/두산마/"
				},
				{
					"file": "1814.png",
					"word": "/LG마/"
				},
				{
					"file": "1815.png",
					"word": "/롯데마/"
				},
				{
					"file": "1816.png",
					"word": "/삼성마/"
				},
				{
					"file": "1817.png",
					"word": "/한화마/"
				},
				{
					"file": "1818.png",
					"word": "/기아마/"
				},
				{
					"file": "1819.png",
					"word": "/키움마/"
				},
				{
					"file": "1820.png",
					"word": "/NC마/"
				},
				{
					"file": "1821.png",
					"word": "/KT마/"
				},
				{
					"file": "1822.png",
					"word": "/메가폰/"
				},
				{
					"file": "1823.png",
					"word": "/신문/"
				},
				{
					"file": "1824.png",
					"word": "/봉투/"
				},
				{
					"file": "L1.png",
					"word": "/아프리카/"
				},
				{
					"file": "L2.png",
					"word": "/SKT/"
				},
				{
					"file": "L3.png",
					"word": "/그리핀/"
				},
				{
					"file": "L4.png",
					"word": "/킹존/"
				},
				{
					"file": "L5.png",
					"word": "/한화생명/"
				},
				{
					"file": "L6.png",
					"word": "/젠지/"
				},
				{
					"file": "L7.png",
					"word": "/진에어/"
				},
				{
					"file": "L8.png",
					"word": "/케이티/"
				},
				{
					"file": "L9.png",
					"word": "/담원기아/"
				},
				{
					"file": "L11.png",
					"word": "/프레딧/"
				},
				{
					"file": "L13.png",
					"word": "/리브샌박/"
				},
				{
					"file": "L14.png",
					"word": "/농심/"
				}								
			]
		}]
	};

	var words = [];
	var lists = [];
	var files = [];
	var subWords = [];
	var subLists = [];
	var signatureWords = [];
	var signatureLists = [];
	var signatureImagePath = '';
	$.each(emoticons['emoticons'][0]['list'], function (key, value) {
		words.push(value.word.replace(/\//g, '\\/'));
		lists[value.word] = value.file;
		files[value.file] = value.word;
	});
	$.each(emoticons['subscription'][0]['list'], function (key, value) {
		subWords.push(value.word.replace(/\//g, '\\/'));
		subLists[value.word] = value.file;
	});

	//private////////////////////////
	var getSubEmoticonRegex = function () {
		return new RegExp(subWords.join("|"), "g");
	}
    var getSignatureEmoticonRegex = function () {
        return new RegExp(signatureWords.join("|"), "g");
    }
	var getEmoticonWord = function () {
		return words;
	}
	var getEmoticonFiles = function () {
		return files;
	}
	var getMatchImage = function (word) {
		return lists[word];
	}
	var getSubMatchImage = function (word) {
		return subLists[word];
	}
    var getSignatureMatchImage = function (word) {
        return signatureLists[word];
    }

	//public
    this.getSignatureEmoticons = function (data) {
        return new Promise(function (resolve, reject) {
            $http.post(LIVE_AFREECATV + '/api/signature_emoticon_api.php', $.param(data || '')).then(function (response) {
                resolve(response.data);
            }).catch(function (error) {
                reject(error);
            })
        });
    }
	this.setSignatureEmoticons = function (signatureEmoticons) {
		signatureImagePath = signatureEmoticons.img_path;
        $.each(signatureEmoticons.data, function (key, value) {
        	value.title = "/" + value.title + "/";
            signatureWords.push(value.title.replace(/\//g, '\\/'));
            signatureLists[value.title] = (value.mobile_img) ? (value.mobile_img) : (value.pc_img);
        });
	}
	this.getEmoticonRegex = function () {
		return new RegExp(words.join("|"), "g");
	}
	this.getEmoticons = function () {
		return emoticons['emoticons'][0]['list'];
	}
	this.decodeStr = function (str) {
		var that = this;
        if(words.length <= 0) return str;
		return str.replace(this.getEmoticonRegex(), function (match, text) {
			return "<img src=\""+ RES_AFREECATV +"/images/chat/emoticon/54/" + getMatchImage(match) + "\" alt=\"" + match + "\" title=\"" + match + "\">";
		});
	}
	this.decodeSubscriptionStr = function (str) {
		var that = this;
        if(subWords.length <= 0) return str;
		return str.replace(getSubEmoticonRegex(), function (match, text) {
			return "<img src=\""+ RES_AFREECATV +"/images/chat/emoticon/54/" + getSubMatchImage(match) + "\" alt=\"" + match + "\" title=\"" + match + "\">";
		});
	}
    this.decodeSignatureStr = function (str) {
        var that = this;
        if(signatureWords.length <= 0) return str;
        return str.replace(getSignatureEmoticonRegex(), function (match, text) {
            return "<img src=\"" + signatureImagePath + getSignatureMatchImage(match) + "\" alt=\"" + match + "\" title=\"" + match + "\">";
        });
    }
	this.encodeStr = function (str) {
		var emojiRegex = new RegExp("<img[^>]+src\\s*=\\s*['\"]([^'\"]+)['\"][^>]*>", "gi");
		var files = getEmoticonFiles();
		return str.replace(emojiRegex, function (match, text) {
			var filename = text.replace(/^.*[\\\/]/, '')
			return files[filename] ? files[filename] : "";
		});
	}

	this.getEmoticonMessage = function (chat) {

		if (!chat || chat.message === undefined || chat.message === null) {
			return;
		}
		var message = chat.message;
		var subscription = chat.subscription;
		var isBJ = chat.isBj;

		/**
		 * All the functions are being called here.
		 */
		message = this.decodeStr(message);
		if (subscription || isBJ) {
			message = this.decodeSubscriptionStr(message);
			message = this.decodeSignatureStr(message);
		}
		return message;
	}
}


module.exports = EmoticonService;