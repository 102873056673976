FavoriteService.$inject = ['$http'];

function FavoriteService($http) {
	this.getList = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/contents', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}
	this.getItems = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/items', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.favoritePin = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/pin', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}


	this.check = function (szUserId) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/check', $.param({
				id: szUserId
			})).then(function (response) {
				var responseData = response.data;
				if (responseData.result == 1) {
					resolve(responseData.data)
				} else {
					reject(responseData);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}


	this.add = function (szUserId, logData) {
        return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/add', $.param({
				id: szUserId
			})).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};   
                //콜렉터로 로그 전송
                if(logData != null && typeof(logData) != 'undefined') {
                    collector.fav(angular.merge({}, {bj_id:szUserId, action_type:'add', ret:response.data.result}, logData));                
                }
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.delete = function (szUserId, logData) {
        return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/delete', $.param({
				id: szUserId
			})).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
                //콜렉터로 로그 전송
                if(logData != null && typeof(logData) != 'undefined') {
                    collector.fav(angular.merge({}, {bj_id:szUserId, action_type:'remove', ret:response.data.result}, logData));                
                }  
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.updatePush = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/update', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.checkSetPush = function () {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/checkSetPush').then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}

	this.updateAllPush = function (data) {
		return new Promise(function (resolve, reject) {
			$http.post(API_M_AFREECATV + '/station/favorite/a/push', $.param(data || '')).then(function (response) {
				if (response.data.result == 1) {
					resolve(response.data)
				} else {
					reject(response.data);
				};
			}).catch(function (error) {
				reject(error);
			})
		});
	}	
}

module.exports = FavoriteService;