
HistoryState.$inject = ['$location'];
function HistoryState($location) {

        var szHsKey = '';
        var oControllerBox;
        // 마지막 Dom 변화 체크 이벤트
        var domNodeInsertEvent;

        return {
                //History State에서 가져와 scope에 값을 추가해준다.
                init: function (scope, szKey, aKeys, oCallback) {
                        // historyState를 사용하고 싶지 않을경우
                        if (scope.disableHistoryState == false) {
                                if (oCallback) {
                                        oCallback();
                                }
                                return;
                        }
                        szHsKey = szKey;
                        oControllerBox = { scope: scope, keys: aKeys };

                        var oResult = history.state;

                        // history에 저장한 key와 해당 페이지의 URL이 같아야지만 history을 적용한다.
                        if (oResult && ($location.path().indexOf(oResult.szKey) > -1)) {
                                angular.forEach(oResult, function (value, key) {
                                        scope[key] = value;
                                });
                        }


                        // (DOM 생성후 스크롤 이동하기 위함)
                        var nCnt = 0;
                        var timer = setInterval(function () {
                                nCnt++;
                                if (nCnt > 1) {
                                        clearInterval(timer);
                                        $('html, body').stop().scrollTop(scope.nPosition);
                                        scope.nPosition = 0;
                                }
                        }, 100);

                        // DOM 변화 이벤트가 없을때만 이벤트 발생 시킴.
                        if (!domNodeInsertEvent) {
                                domNodeInsertEvent = $(document).bind('DOMNodeInserted', function (e) {
                                        nCnt = 0;
                                });
                        }


                        // 콜백이 있고, HistoryState 내용이없을시 콜백 리턴
                        if (oCallback && !this.checkUseHistory()) {
                                oCallback();
                        }

                        // 로직 처리후 History정보가 있으면 삭제
                        if (this.checkUseHistory()) {
                                this.reset();
                        }


                },


                //History State에 추가
                set: function (nPosition) {
                        if (!oControllerBox) {
                                return;
                        }
                        var scope = oControllerBox['scope'];
                        var aKeys = oControllerBox['keys'];

                        var oData = {};
                        angular.forEach(aKeys, function (value) {
                                oData[value] = scope[value];
                        });

                        oData.szKey = szHsKey;
                        oData.nPosition = nPosition;

                        history.replaceState(oData, null);
                },


                // reset
                reset: function () {
                        if (history.state) {
                                history.replaceState(null, null);
                        }
                },

                // 히스토리를 사용해야되는지 여부 판단
                checkUseHistory: function () {
                        // history에 저장한 key와 해당 페이지의 URL이 같아야지만 history을 적용한다.
                        return (history.state && $location.path().indexOf(history.state.szKey) > -1) ? true : false;
                },
        }
};

module.exports = HistoryState;