tagTranslate.$inject = ['gtext'];
function tagTranslate(gtext) {
    return {
        restrict: "E",
        scope: {
            translateData : "=",
        },
        compile : function(){
            return function(scope, element) {
                scope.$watchCollection('translateData', function() {
                    translate(scope.translateData);
                });

                function translate(data){
                    var szTranslateHtml = gtext(data['@key']);
                    if(szTranslateHtml === data['@key']){
                        return;
                    }
                    var $transElements = $(szTranslateHtml);
                    for(var key in data){
                        $transElements.each(function(){
                            var $oneDepth = $(this);
                            if($oneDepth.attr("text") !== undefined){
                                if(data[key] !== undefined){
                                    $oneDepth.text(data[key]);
                                }
                            }
                            var $twoDepth = $(this).children();
                            $twoDepth.each(function(){
                                if(data[key] !== undefined){
                                    $twoDepth.text(data[key]);
                                }
                            })
                        });
                    }
                    var szResultHtml = $transElements.clone().wrapAll("<div/>").parent().html();
                    element.html(szResultHtml);
                }
            }
        }
    }
}
module.exports = tagTranslate;