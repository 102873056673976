function ngUploadChange(){
    return {
        scope: {
            ngUploadChange:"&"
        },
        link:function($scope, $element, $attrs){
            $element.on("change",function(event){
                $scope.$apply(function(){
                    $scope.ngUploadChange();

                });
                event.preventDefault();
            });
            
        }
    }
}

module.exports = ngUploadChange;