var Swiper = require("swiper/dist/js/swiper.min");
NoteSendViewController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'NoteService', '$sce', 'gtext']
function NoteSendViewController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, NoteService, $sce, gtext) {
    $scope.slideFile = '';
    //초기화
    $scope.note = {
        read_date: 0
    };

    var oDate = new Date();
    $scope.date = oDate.getFullYear() + '-' + (oDate.getMonth()+1).addZero() + '-' + oDate.getDate().addZero() + ' ' + oDate.getHours().addZero() + ":" + oDate.getMinutes().addZero() + ":" + oDate.getSeconds().addZero();

    $scope.bLoginAdmin = false;
    $scope.szStationUrl = "";

    $scope.byteConvert = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        bytes = Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index];
        return bytes;
    };
    var mySwiper = null;
    
    $rootScope.headerClassRemove();

    $scope.layers = function (target, index) {
        if (mySwiper == null) {
            mySwiper = new Swiper('.swiper-container', {
                navigation: {
                    nextEl: '.viewer-next',
                    prevEl: '.viewer-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                }
            });
        }
        $scope.layerOpen = true;
        mySwiper.slideTo(index);
    };
    $scope.layersClose = function (target) {
        $scope.layerOpen = false;
    };

    $scope.prev = function () {
		history.back();
	};

    /**
     * 삭제하기
     */
    $scope.delete = function () {
        if (!confirm(gtext('삭제하시겠습니까?'))) {
            return;
        }

        $scope.isDisabled = true;
        NoteService.sendDelete({
            no: $scope.note.no
        }).then(function (response) {
            $scope.isDisabled = false;
            $scope.$digest();
            history.back();

        }).catch(function (resposne) {
            $scope.isDisabled = false;
        });
    }

    /**
     * 보관하기
     */
    $scope.store = function () {
        NoteService.noteStore({
            no: $scope.note.no,
            note_type: 'send'
        }).then(function (response) {
            alert(response.data.message);
            history.back();
        }).catch(function (response) {
            alert(response.data.message);
        });
    }

    $scope.getPhrases = function () {
        return $sce.trustAsHtml(gtext('(보낸 날짜로부터 <em>90</em>일 보관)'));
    }

    goAosMove = function (url) {
        afreeca.link.browser(url);
    }

	goItemMove = function (url, scheme) {
        // 앱내의 웹뷰 일때
        if(afreeca.link.isWebview()) {
			var schemeUrl = scheme == '' ? (SERVICE_APP_SCHEME + 'browser/station?url=' + encodeURIComponent(url)) : scheme;
			document.location.href = schemeUrl;
            return false;
		} else { // 모웹
			window.open(url, "_blank");
            return false;
        }
    }

    NoteService.sendView({ no: $stateParams.no }).then(function (response) {
        $scope.note = response.data;
        $scope.note.content = Util.eacapeBackslash($scope.note.content);
        $scope.bLoginAdmin = response.data.login_admin;
        $scope.$digest();
    }).catch(function (resposne) {
        alert(resposne.data.message);
        $state.go('note.send', { deleteNo: $stateParams.no });
    });

    $scope.renderContent = function (szContent, szNoteType, nSubType) {
        if (szNoteType == 'DONATION') {
            document.querySelector('.memo_view').innerHTML = Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
        } else {
            return Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
        }
    }

	$scope.nickClick = function ($event, bNickClick) {
		$event.stopPropagation();
		$event.preventDefault();
		$rootScope.bNickClick = !bNickClick;
		return $rootScope.bNickClick;
	}
	
	$scope.showIct = function () {
		return $rootScope.bNickClick ? '' : 'none';
	}

	// 채널 가기
	$scope.goBjStation = function(szUserId){
        $rootScope.bNickClick = false;
        $scope.szStationUrl = BJ_AFREECATV + '/'+szUserId;
		if(Util.getOS() == 'android' && afreeca.link.isWebview() && Util.getOSVersion("6.3.0")['compare']) {
			goAosMove($scope.szStationUrl);
			return;
		}
        if(Util.getOS() == 'android' && afreeca.link.isWebview()) { // android go Station 함수 사용할 경우 본창으로 열리는 이슈가 있어 open 함수 추가
			window.open(SERVICE_APP_SCHEME + "browser/station?url="+$scope.szStationUrl, "_blank");
		} else {
			afreeca.link.goStationLink($scope.szStationUrl);
		}
	}

	$scope.searchUser = function() {
		$state.go('note.send', {deleteNo: null, disableHistoryState: false, searchWord: $scope.note.recv_id, searchType: 2}, {reload: 'note.send'});
	}

	$scope.ictClose = function () {
        $rootScope.bNickClick = false;
    }

    $scope.reply = function () {
		if ($rootScope.bBlock) {
			alert($rootScope.szBlockMsg);
			return false;
		}
		$rootScope.bNickClick = false;
		$state.go('note.write', {
			id: $scope.note.recv_id
		});
	}

}

module.exports = NoteSendViewController;


Number.prototype.addZero = function () {
    return ('0' + this).substr(-2);
}